import { safeURLPath } from './safe-url-path';
import slugify from 'slugify';
import OptionsError from './options-error';
import Logger from '../../logger';

export interface KbArticlePageOptions {
    readonly basePath: string;
    readonly slug: string;
}

export const getKbArticlePagePath = safeURLPath(
    (options: KbArticlePageOptions) => {
        const { basePath, slug } = options;
        if (typeof basePath !== 'string') {
            Logger.info('basePath option is required and must be a string');
            throw new OptionsError(
                'basePath option is required and must be a string'
            );
        }

        if (typeof slug !== 'string') {
            Logger.info('slug option is required and must be a string');
            throw new OptionsError(
                'slug option is required and must be a string'
            );
        }

        return [
            basePath,
            'kb',
            slugify(slug, {
                remove: /[^\w -]/g
            })
        ];
    }
);
