import { Client, Event, SeverityLevel, EventHint, Scope } from '@sentry/types';

declare global {
    interface Window {
        Sentry: Client;
    }
}

interface SentryCaptureExceptionProps {
    exception: any;
    hint?: EventHint;
    scope?: Scope;
}

interface SentryCaptureMessageProps {
    message: string;
    level?: SeverityLevel;
    hint?: EventHint;
    scope?: Scope;
}

interface SentryCaptureEventProps {
    event: Event;
    hint?: EventHint;
    scope?: Scope;
}

let sentryClient: Client;

const getSentryClient = () => {
    if (sentryClient) {
        return sentryClient;
    }

    if (typeof window !== 'undefined' && typeof window.Sentry !== 'undefined') {
        sentryClient = window.Sentry;
    }

    return sentryClient;
};

export const sentryCaptureException = ({
    exception,
    hint,
    scope
}: SentryCaptureExceptionProps) => {
    const sentryClient = getSentryClient();

    if (sentryClient) {
        sentryClient.captureException(exception, hint, scope);
    }
};

export const sentryCaptureMessage = ({
    message,
    level,
    hint,
    scope
}: SentryCaptureMessageProps) => {
    const sentryClient = getSentryClient();

    if (sentryClient) {
        sentryClient.captureMessage(message, level, hint, scope);
    }
};

export const sentryCaptureEvent = ({
    event,
    hint,
    scope
}: SentryCaptureEventProps) => {
    const sentryClient = getSentryClient();

    if (sentryClient) {
        sentryClient.captureEvent(event, hint, scope);
    }
};
