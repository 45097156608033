import React from 'react';
// @ts-ignore
import {
    CardGroup,
    Card,
    CardsWithTitleAdjustment,
    CardsWithTitleAdjustmentFuncParams
    // @ts-ignore
} from '../card';
import useDropdownFocus from '../../hooks/use-dropdown-focus';
import { CollectionReference } from '../../../content/types/content/collection';
import './dropdown.less';

interface DropdownDocumentationProps {
    className: string;
    isOpen: boolean;
    closeDropdown: () => void;
    collections: CollectionReference[] | undefined;
}

const DropdownDocumentation = ({
    className,
    isOpen,
    closeDropdown,
    collections
}: DropdownDocumentationProps) => {
    const setFirstElement = useDropdownFocus({ isOpen, closeDropdown });
    return (
        <div
            className={[
                className,
                isOpen ? '' : 'hide-dropdown',
                'reduced-spacing'
            ].join(' ')}
            data-testid="dropdown_documentation"
        >
            <CardGroup className={'documentation-dropdown'}>
                <CardsWithTitleAdjustment collection={collections}>
                    {(
                        {
                            item,
                            measureRef,
                            titleHeight
                        }: CardsWithTitleAdjustmentFuncParams,
                        idx: number
                    ) => (
                        <Card
                            setRef={idx === 0 ? setFirstElement : undefined}
                            title={item.title}
                            buttonLink={item.url}
                            className={'documentation-card'}
                            description={item.description}
                            key={item.id}
                            type={'third-width'}
                            measureRef={measureRef}
                            titleHeight={titleHeight}
                        />
                    )}
                </CardsWithTitleAdjustment>
            </CardGroup>
        </div>
    );
};

export default DropdownDocumentation;
