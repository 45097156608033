import React, { KeyboardEvent, useCallback, useState } from 'react';
import './platform-tabs.less';
import { Tab } from './tab';

export interface TabsProps {
    tabs: { label: string; content: React.ReactNode }[];
    onSelect?: (selected: { label: string }) => void;
    onKeyDown?: (selected: { label: string }, keyboardEvent: string) => void;
}

export const Tabs = ({ tabs, onSelect, onKeyDown }: TabsProps) => {
    const [tabContentIndex, setTabContentIndex] = useState(0);

    const goToTab = (tabIndex: number) => {
        setTabContentIndex(tabIndex);
        return tabIndex;
    };

    const goToNextTab = useCallback(() => {
        return tabContentIndex + 1 < tabs.length
            ? goToTab(tabContentIndex + 1)
            : goToTab(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabContentIndex]);

    const goToPrevTab = useCallback(() => {
        return tabContentIndex - 1 >= 0
            ? goToTab(tabContentIndex - 1)
            : goToTab(tabs.length - 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabContentIndex]);

    const goToFirstTab = () => {
        return goToTab(0);
    };

    const goToLastTab = () => {
        return goToTab(tabs.length - 1);
    };

    const handleOnKeyDown = (e: KeyboardEvent) => {
        let index = -1;
        if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
            index = goToPrevTab();
        } else if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
            index = goToNextTab();
        } else if (e.key === 'Home') {
            index = goToFirstTab();
        } else if (e.key === 'End') {
            index = goToLastTab();
        } else {
            return index;
        }

        // This prevents scrollbars from moving around
        e.preventDefault();
        return index;
    };

    return (
        <div className="platform-tabs">
            <ul
                data-testid="platform-tabs__tabs"
                className="platform-tabs__tabs"
                role="tablist"
                onKeyDown={(e) => {
                    const newIndex = handleOnKeyDown(e);
                    if (newIndex >= 0 && onKeyDown) {
                        onKeyDown(tabs[newIndex], e.key);
                    }
                }}
            >
                {tabs.map((tab, index) => (
                    <Tab
                        key={index}
                        index={index}
                        label={tab.label}
                        selected={tabContentIndex === index}
                        onSelect={() => {
                            if (index === tabContentIndex) {
                                return;
                            }

                            setTabContentIndex(index);
                            if (onSelect) {
                                onSelect(tab);
                            }
                        }}
                    />
                ))}
            </ul>
            {tabs.map((tab, index) => (
                <div
                    key={index}
                    id={`platform-tabs:panel:${index}`}
                    data-testid={`platform-tabs:panel:${index}`}
                    style={{
                        display: tabContentIndex === index ? 'block' : 'none'
                    }}
                    role="tabpanel"
                    aria-hidden={tabContentIndex !== index}
                    aria-labelledby={`platform-tabs:tab:${index}`}
                >
                    {tab.content}
                </div>
            ))}
        </div>
    );
};
