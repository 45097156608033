import React, { FC } from 'react';
import { IconDimensions } from './card.type';
import './card.less';

interface SvgIconProps {
    icon: string;
    iconDimensions?: IconDimensions;
}

export const SvgIcon: FC<SvgIconProps> = ({
    icon,
    iconDimensions
}: SvgIconProps) => {
    return icon ? (
        <div className="cards__icon">
            <img
                data-testid="card_icon"
                src={icon}
                height={iconDimensions ? iconDimensions.height : '49'}
                width={iconDimensions ? iconDimensions.width : '49'}
                alt=""
            />
        </div>
    ) : null;
};
