import React from 'react';
import { AtlasLink } from '../atlas-link';
import './button-v2.less';

export interface ButtonV2Props {
    children: React.ReactNode;
    variant: 'primary' | 'secondary';
    size: 'small' | 'large';
    href: string;
    ariaLabel?: string;
    onClick?: () => void;
}

export const ButtonV2 = ({
    children,
    variant,
    size,
    href,
    ariaLabel,
    onClick
}: ButtonV2Props) => {
    const styleClasses = `button__${variant} button__${size}`;

    return (
        <AtlasLink
            data-testid="button-v2"
            className={`button ${styleClasses}`}
            onClick={onClick}
            href={href}
            aria-label={ariaLabel}
        >
            {children}
        </AtlasLink>
    );
};
