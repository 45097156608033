import React, { useState, ReactNode } from 'react';
import Measure from 'react-measure';
import { ChildReference } from '../../../content/types/content/collection';
// @ts-ignore
import useViewportWidth from '../../hooks/use-viewport-width';

export interface CardsWithTitleAdjustmentFuncParams {
    item: ChildReference;
    measureRef: (ref: Element | null) => void;
    titleHeight: number | false | undefined;
}

export interface CardsWithTitleAdjustmentProps {
    children: (
        params: CardsWithTitleAdjustmentFuncParams,
        idx: number
    ) => ReactNode;
    collection: ChildReference[];
}

// arbitraty breakpoint being used in cards.less to establish when cards
// break into two columns.
const CARDS_BREAKPOINT = 901;

/**
 * Wrapper component around a collection of <Card />, <CardWithCEM />, or similar derivations. Takes a collection of `ChildReference`s as a prop.
 * Child node is a function that is called against every element in the collection, and exposes the element's object projects, and a `measureRef` and `titleHeight` params which must be provided as props to <Card /> to make the title height adjustment work. Must return a `<Card />`, `<CardWithCEM />`, or similar.
 * You can effectively think of this component similarly to a map function.
 * @param {ChildReference[]} collection - Not to be confused with the Collection contentful type-- a collection of `ChildReference`s to iterate over
 * @param {(params: CardsWithTitleAdjustmentFuncParams, idx: number) => ReactNode} children - Nested child node; Function to be executed upon every iteration through the `collection`, then returning a single `<Card />`, <CardWithCEM />, or similar derivation.
 */
export const CardsWithTitleAdjustment = ({
    children,
    collection
}: CardsWithTitleAdjustmentProps) => {
    // The heights of title elements are being tracked to enable specific alignment
    // between title and body of header items. More details in ticket FRAK-1326
    const [titleHeights, setTitleHeights] = useState<number[]>([]);
    const { viewportWidth } = useViewportWidth();

    if (!collection || !collection.length) {
        return null;
    }

    // Get the largest title height in the applicable row, so that all items
    // in that row can conform to it.
    const getTitleHeightForRow = (titleHeights: number[], idx: number) => {
        if (!viewportWidth) {
            return;
        }
        // Mobile has only one card per row and doesn't need to calculate title height
        if (viewportWidth < CARDS_BREAKPOINT) {
            return;
        }
        const cardsPerRow = 2;

        const rowStartIdx = Math.floor(idx / cardsPerRow) * cardsPerRow;
        const rowEndIdx = Math.ceil((idx + 1) / cardsPerRow) * cardsPerRow;
        const rowHeights = titleHeights.slice(rowStartIdx, rowEndIdx);
        const filteredHeights = rowHeights.filter(
            (height) => height !== undefined
        );

        return filteredHeights.length
            ? Math.max(...filteredHeights)
            : undefined;
    };

    const setTitleHeight = (height: number, idx: number) => {
        // using functional state updates here to ensure that state updates in the
        // same render don't overwrite each other.
        setTitleHeights((prevState) => {
            const newState = [...prevState];
            if (!prevState[idx] || height !== prevState[idx]) {
                newState[idx] = height;
            }
            return newState;
        });
    };

    return (
        <>
            {collection &&
                collection.map((item, idx: number) => (
                    <Measure
                        // @ts-ignore
                        key={item.id || idx}
                        bounds
                        onResize={(contentRect) => {
                            const height = contentRect?.bounds?.height;
                            height && setTitleHeight(height, idx);
                        }}
                    >
                        {
                            (({ measureRef }: { measureRef: any }) => {
                                const rowTitleHeight =
                                    !Number.isNaN(idx) &&
                                    getTitleHeightForRow(titleHeights, idx);

                                return children(
                                    {
                                        item,
                                        measureRef,
                                        titleHeight: rowTitleHeight
                                    },
                                    idx
                                );
                            }) as any
                        }
                    </Measure>
                ))}
        </>
    );
};
