import React from 'react';
import Tooltip from '@atlaskit/tooltip';
import pt from 'prop-types';

function EditorTooltip({
    tooltipContent,
    testId,
    altText,
    logoSrc,
    openEditor,
    ariaLabel = 'Open Editor page'
}) {
    return (
        <Tooltip content={tooltipContent} position="left">
            <div
                className="author-tools__editor-logo"
                role="button"
                tabIndex={0}
                aria-label={ariaLabel}
                onClick={() => openEditor()}
                onKeyDown={(e) => {
                    e.keyCode === 13 && openEditor();
                }}
            >
                <img
                    data-testid={testId}
                    alt={altText}
                    src={logoSrc}
                    height="32"
                    width="32"
                />
            </div>
        </Tooltip>
    );
}

EditorTooltip.propTypes = {
    tooltipContent: pt.string.isRequired,
    testId: pt.string.isRequired,
    altText: pt.string.isRequired,
    logoSrc: pt.string.isRequired,
    openEditor: pt.string.isRequired,
    ariaLabel: pt.string
};

export default EditorTooltip;
