import analytics from '../../utils/analytics';
import camelCase from 'lodash/camelCase';
/*
 adds logic to add required details
 and fires analytics tracking
 @param {string} href - target url
 @param {string} text - optional, link text
 @param {string} name - optional, item name, used for populating actionSubjectId and target
 */
const track = (href: string | null, text?: string, name?: string) => {
    if (name) {
        let target;
        let actionSubjectId;
        if (name.match(/.*button/i)) {
            target = 'button';
            actionSubjectId = camelCase(name.replace(/button/i, ''));
        } else if (name.match(/.*card/i)) {
            target = 'card';
            actionSubjectId = camelCase(name.replace(/card/i, ''));
        } else {
            target = 'link';
            actionSubjectId = camelCase(name);
        }
        return analytics.processEvent(target, '', {
            actionSubjectId: actionSubjectId
        });
    }
    analytics.trackLinkClick(href, text);
};

export default track;
