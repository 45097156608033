import React from 'react';
import NotFound404Page from './404';

export default function render(entry, pageTree, featureFlags) {
    return (
        <NotFound404Page
            entry={entry}
            pageTree={pageTree}
            featureFlags={featureFlags}
        />
    );
}
