import React from 'react';
import ReactPlayer from 'react-player';
import './video.less';
import analytics from '../../utils/analytics/analytics';
import { sentryCaptureException } from '../../utils/sentry/sentry-client';

export interface VideoProps {
    featuredVideo: any;
    options?: any;
}

const trackVideoEvent = (
    name: string,
    event: string,
    action: string,
    label: string
    // eslint-disable-next-line max-params
) => {
    analytics.trackEvent(name, {
        // @ts-ignore
        event: event,
        eventComponent: 'video',
        category: 'video',
        action,
        label
    });
};

const handleOnError = (error: any) => {
    sentryCaptureException({
        exception: error
    });
    trackVideoEvent(
        'videoPlayerError',
        'video_error',
        'error',
        'Video player error'
    );
};

const handleOnStart = () => {
    trackVideoEvent(
        'videoPlayerStart',
        'video_started',
        'start',
        'Video player start'
    );
};

const handleOnEnded = () => {
    trackVideoEvent('videoPlayerEnd', 'video_ended', 'end', 'Video player end');
};

const Video = ({ featuredVideo }: VideoProps) => {
    return (
        <div data-testid="atl-video-player" className="player-wrapper">
            <ReactPlayer
                className="react-player"
                url={featuredVideo.videoUrl}
                width="100%"
                height="100%"
                controls={true}
                onError={handleOnError}
                onStart={handleOnStart}
                onEnded={handleOnEnded}
            />
        </div>
    );
};
export default Video;
