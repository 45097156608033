// TODO: fetch previous status (i.e. previousStatus); uncomment previousStatus related lines when done
import React from 'react';
import './release-note-sidebar.less';
// import ArrowRightIcon from '@atlaskit/icon/glyph/arrow-right';
import Tooltip, { TooltipPrimitive } from '@atlaskit/tooltip';
import styled from '@emotion/styled';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { B400 } from '@atlaskit/theme/colors';
import Lozenge from '@atlaskit/lozenge';

export const StatusPill = ({
    currentStatus,
    statusDescription
}: //previousStatus = 'Planned'
{
    currentStatus: string;
    statusDescription: string;
    //previousStatus?: string;
}) => {
    // const checkStatus = (previousStatus === currentStatus); // uncomment when ready for conditional lozenge

    const CustomTooltip = styled(TooltipPrimitive)`
        background: #0747a6;
        border-radius: 3px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
        box-sizing: content-box; /* do not set this to border-box or it will break the overflow handling */
        color: #ffffff;
        max-height: 300px;
        max-width: 300px;
        padding: 8px 12px;
    `;

    const lozengeAppearances = new Map([
        ['Planned', 'default'], //grey
        ['Coming soon', 'default'], //grey
        ['Rolling out', 'inprogress'], //blue
        ['Generally available', 'success'],
        ['Rolled back', 'success'] //green
    ]);

    return (
        <div
            className="sidebar sidebar__section--release-note"
            data-testid="status-pill-test-id"
        >
            <div className="sidebar__heading-with-icon">
                <span>Status</span>
                <span className="info-icon-wrapper">
                    <Tooltip
                        component={CustomTooltip}
                        content={`Release notes labeled "${currentStatus}" are ${statusDescription}`}
                        position="top"
                    >
                        <InfoIcon label="Info" primaryColor={B400} />
                    </Tooltip>
                </span>
            </div>

            <div className="sidebar__item">
                {/* use checkStatus && {lines 61-70} when previousStatus is ready */}
                <span>
                    <Lozenge
                        // @ts-ignore
                        appearance={
                            lozengeAppearances.get(currentStatus) || 'default'
                        }
                    >
                        {currentStatus}
                    </Lozenge>
                </span>

                {/*!checkStatus && (
                    <span>
                        <Lozenge
                            // @ts-ignore
                            appearance={
                                lozengeAppearances.get(previousStatus) || 'default'
                            }
                        >
                            {previousStatus}
                        </Lozenge>
                        <ArrowRightIcon label="Right Arrow" size="medium" />
                        <Lozenge
                            // @ts-ignore
                            appearance={
                                lozengeAppearances.get(currentStatus) ||
                                'default'
                            }
                        >
                            {currentStatus}
                        </Lozenge>
                    </span>
                )*/}
            </div>
        </div>
    );
};
