import React from 'react';
import { Header, HeaderProps } from '../../components/header';
import Footer from '../../components/footer';
//@ts-ignore
import Button from '@atlaskit/button';
import ArrowLeftIcon from '@atlaskit/icon/glyph/arrow-left';
import SectionMessage from '@atlaskit/section-message';
import { StatusPill } from '../../components/release-note-sidebar/status';
import { ChangeType } from '../../components/release-note-sidebar/change-type';
import { Contexts } from '../../components/release-note-sidebar/contexts';
import { TextSection } from '../../components/release-note-text-section';
import { ReleaseNote } from '../../../content/types/content';
import './release-notes-detailed.less';
import '../../components/release-note-sidebar/release-note-sidebar.less';

export interface ReleaseNotesDetailedEntry extends ReleaseNote {
    header: HeaderProps;
    preview: boolean;
}

interface ReleaseNotesDetailedProps {
    entry: ReleaseNotesDetailedEntry;
}

/* eslint-disable complexity */
const ReleaseNotesDetailedPage = ({
    entry: {
        releaseNoteTitle,
        header,
        releaseNoteId,
        relatedContexts,
        image,
        currentStatus,
        statusDescription,
        changeTypeLabel,
        changeTypeIcon,
        keyChanges,
        description,
        reasonForChange,
        benefitsList,
        resources,
        preview,
        prepareForChange
    }
}: ReleaseNotesDetailedProps) => {
    return (
        <>
            <div className="flex-column-page">
                <div className="content">
                    <Header
                        title={'Atlassian Support'}
                        id={header.id}
                        pageId={releaseNoteId}
                        additionalLinks={header.additionalLinks}
                        resources={resources}
                        showPreviewBanner={preview}
                        showProductDropdownAsWaffle={true}
                        logo={header.logo}
                        cloudProducts={header.cloudProducts}
                        serverProducts={header.serverProducts}
                        atlassianSupportLogo={header.atlassianSupportLogo}
                        atlassianLogo={header.atlassianLogo}
                    />
                    <main className="main-content-container">
                        <nav className="release-note__sub-header">
                            <Button
                                iconBefore={
                                    <ArrowLeftIcon
                                        label="Left Arrow"
                                        size="medium"
                                    />
                                }
                                href="/release-notes"
                                appearance="subtle-link"
                                className="release-note__back-button"
                            >
                                Back to all releases
                            </Button>
                        </nav>
                        <div className="release-note__sub-content">
                            {/* this is for mobile view */}
                            <div className="split-sidebar split-sidebar--only-mobile-view">
                                <Contexts contexts={relatedContexts} />
                                <ChangeType
                                    changeTypeLabel={changeTypeLabel}
                                    changeTypeIcon={changeTypeIcon}
                                />
                                <StatusPill
                                    currentStatus={currentStatus}
                                    statusDescription={statusDescription}
                                />
                            </div>

                            <div className="title margin-bottom-medium">
                                <h1>{releaseNoteTitle}</h1>
                            </div>

                            <div className="release-note__body">
                                {keyChanges && (
                                    <TextSection
                                        text={keyChanges}
                                        testId="key-changes"
                                        title="Key Changes"
                                        atTop
                                    />
                                )}
                                <div
                                    className={
                                        keyChanges
                                            ? 'release-note__body-item'
                                            : ''
                                    }
                                    dangerouslySetInnerHTML={{
                                        __html: description
                                    }}
                                />
                                {image?.url && (
                                    <img
                                        data-testid="featured-image"
                                        className="release-note__body-item featured-image"
                                        src={image.url}
                                        alt={image.altTag}
                                    />
                                )}
                                {benefitsList && (
                                    <TextSection
                                        text={benefitsList}
                                        testId="benefits-list"
                                        title="Benefits"
                                    />
                                )}
                                {prepareForChange && (
                                    <TextSection
                                        text={prepareForChange}
                                        testId="prepare-for-change"
                                        title="Prepare For Change"
                                    />
                                )}
                                {reasonForChange && (
                                    <div
                                        className="release-note__body-item"
                                        data-testid="reason-for-change"
                                    >
                                        <SectionMessage>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: reasonForChange
                                                }}
                                            />
                                        </SectionMessage>
                                    </div>
                                )}
                            </div>

                            {/* this is for desktop view */}
                            <div className="split-sidebar--tablet-only-desktop-view">
                                <ul className="sidebar__section">
                                    <Contexts contexts={relatedContexts} />
                                </ul>

                                <ul className="sidebar__section">
                                    <ChangeType
                                        changeTypeLabel={changeTypeLabel}
                                        changeTypeIcon={changeTypeIcon}
                                    />
                                </ul>

                                <ul className="sidebar__section">
                                    <StatusPill
                                        currentStatus={currentStatus}
                                        statusDescription={statusDescription}
                                    />
                                </ul>
                            </div>
                        </div>
                    </main>
                </div>
                <Footer
                    logo={header.atlassianLogo.url}
                    className={'margin-top-large'}
                />
            </div>
        </>
    );
};

export default ReleaseNotesDetailedPage;
