import React from 'react';
import { ResourceCard } from '../resource-card/resource-card';
import './card-section.less';
import { CardResourceV2 } from '../card/card.type';

interface CardSectionProps {
    cards: CardResourceV2[];
}

export const CardSection = ({ cards }: CardSectionProps) => {
    return (
        <ul className="card-section" data-testid="card-section-container">
            {cards.map((card, index) => (
                <ResourceCard
                    key={index}
                    image={card.icon ? card.icon.url : ''}
                    title={card.title}
                    description={card.sacHomeDescription || ''}
                    link={card.buttonLink}
                    buttonText={card.buttonText}
                    analyticsMetadata={{
                        parentComponent: 'browse-by-resources-section'
                    }}
                />
            ))}
        </ul>
    );
};
