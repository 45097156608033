import { safeURLPath } from './safe-url-path';
import slugify from 'slugify';
import OptionsError from './options-error';
import Logger from '../../logger';

export interface DocumentationPageOptions {
    basePath: string;
    slug: string;
    anchor?: string;
}

export const getDocumentationPagePath = safeURLPath(
    (options: DocumentationPageOptions) => {
        const { basePath, slug } = options;
        if (typeof basePath !== 'string') {
            Logger.info('basePath option is required and must be a string');
            throw new OptionsError(
                'basePath option is required and must be a string'
            );
        }

        if (typeof slug !== 'string') {
            Logger.info('slug option is required and must be a string');
            throw new OptionsError(
                'slug option is required and must be a string'
            );
        }

        return [
            basePath,
            'docs',
            slugify(slug, {
                remove: /[^\w -]/g
            })
        ];
    }
);
