import BrowserLogger, * as browserRequestFunctions from './strategies/browser-strategy';
import ServerLogger, * as serverRequestFunctions from './strategies/server-strategy';

type LoggerUnion = typeof BrowserLogger | typeof ServerLogger;
/**
 * Enforces that logging is invoked using one the following forms:
 * ```
 * // logs { "msg":"Message" }
 * logger.info('Message');
 *
 * // logs { "context": "value", "msg": "Message" }
 * logger.info({ context: 'value' }, 'Message');
 * ```
 *
 * Using alternative invocations with the {@link ServerLogger}, creates log entries that are harder to filter in Splunk:
 * ```
 * // logs { "msg": "Message { context: 'value' }" }
 * logger.info('Message', { context: 'value' });
 * ```
 */
type ILogger = {
    [_ in keyof LoggerUnion]: (contextOrMsg: unknown, msg?: string) => void;
};

declare const __isBrowser__: boolean;

const Logger: ILogger = __isBrowser__ ? BrowserLogger : ServerLogger;
const requestFunctions = __isBrowser__
    ? browserRequestFunctions
    : serverRequestFunctions;

export const requestLogger = requestFunctions.requestLogger;

export const errorRequestLogger = requestFunctions.errorRequestLogger;

export default Logger;
