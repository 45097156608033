import React from 'react';

import PropTypes from 'prop-types';
import { HeroBanner } from '../../components/hero-banner';
import { Search, handleOnSearch } from '../../components/search';
import { Header } from '../../components/header';
import Footer from '../../components/footer';
import FooterV2 from '../../components/footer-v2';
import {
    Card,
    CardGroup,
    CardsWithTitleAdjustment
} from '../../components/card';
import { FeaturedContent } from '../../components/featured-content';
import { ContactCtaBlock } from '../../components/contact';
import EasterEgg from '../../components/easter-egg';
import { HomePageType } from './home.type';
import './home.less';
import { translatableText } from '../../utils/translatable-text';
import { DeploymentSelectorTabs } from '../../components/deployment-selector-tabs';
import { PlatformTabs } from '../../components/platform-tabs';
import { CardSection } from '../../components/card-section';

const SACHomePage = (props) => {
    const {
        entry: {
            cards,
            featuredResources,
            featuredContent,
            header,
            cloudProducts,
            serverProducts,
            id,
            preview
        },
        featureFlags: { isRedesignEnabled }
    } = props;

    const renderCards = (cards) => {
        const filteredCards = cards.filter((item) => !item.hideInHeader);
        return (
            <CardsWithTitleAdjustment collection={filteredCards}>
                {({ item, measureRef, titleHeight }) => (
                    <Card
                        key={item.id}
                        id={item.id}
                        title={item.title}
                        buttonText={item.buttonText}
                        buttonLink={item.buttonLink}
                        analytics={item.analytics}
                        icon={item.icon ? item.icon.url : ''}
                        description={item.sacHomeDescription || ''}
                        measureRef={measureRef}
                        titleHeight={titleHeight}
                    />
                )}
            </CardsWithTitleAdjustment>
        );
    };

    const v2ClassSuffix = isRedesignEnabled ? '-v2' : '';

    const combinedCards = [...featuredResources.cards, ...cards];

    return (
        <div className={`home${v2ClassSuffix}`} data-testid="page-template">
            <div className={`content-wrapper${v2ClassSuffix}`}>
                <Header
                    cloudProducts={header.cloudProducts}
                    serverProducts={header.serverProducts}
                    logo={header.atlassianSupportLogo.url}
                    atlassianSupportLogo={header.atlassianSupportLogo}
                    atlassianSupportLogoV2={header.atlassianSupportLogoV2}
                    id={header.id}
                    additionalLinks={header.additionalLinks}
                    resources={featuredResources}
                    showPreviewBanner={preview}
                    pageId={id}
                    isRedesignEnabled={isRedesignEnabled}
                />
                <main>
                    <HeroBanner
                        searchField={
                            <Search
                                onSearch={handleOnSearch}
                                placeholder="Tell us what you're looking for"
                                analyticsMetadata={{
                                    parentComponent: 'hero-banner'
                                }}
                                isRedesignEnabled={isRedesignEnabled}
                            />
                        }
                        subHeader={translatableText.heroBannerSubheader.text}
                        isRedesignEnabled={isRedesignEnabled}
                    />

                    <div className="main-content-container">
                        {featuredContent?.featuredEntries?.length && (
                            <div
                                className={`home${v2ClassSuffix}__featured-content`}
                                data-testid={`home${v2ClassSuffix}-featured-content`}
                            >
                                <div
                                    className={`home${v2ClassSuffix}__featured-content--inner`}
                                >
                                    <FeaturedContent
                                        content={featuredContent}
                                        analyticsMetadata={{
                                            parentComponent: 'home'
                                        }}
                                        featureFlags={{
                                            isRedesignEnabled
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        <div className={`home${v2ClassSuffix}__browse`}>
                            <div
                                className={`home${v2ClassSuffix}__browse--inner platform-tabs-wrapper`}
                            >
                                <h2 className={`home${v2ClassSuffix}__header`}>
                                    {translatableText.browseByProduct.text}
                                </h2>
                                {isRedesignEnabled ? (
                                    <PlatformTabs
                                        cloudProducts={cloudProducts}
                                        serverProducts={serverProducts}
                                        details="home page"
                                        analyticsMetadata={{
                                            parentComponent: 'browse-by-product'
                                        }}
                                        featureFlags={{
                                            isRedesignEnabled
                                        }}
                                    />
                                ) : (
                                    <DeploymentSelectorTabs
                                        cloudProducts={cloudProducts}
                                        serverProducts={serverProducts}
                                        analyticsMetadata={{
                                            parentComponent: 'browse-by-product'
                                        }}
                                        details={'home page'}
                                    />
                                )}
                            </div>
                        </div>
                        <div className={`home${v2ClassSuffix}__browse`}>
                            <div
                                className={`home${v2ClassSuffix}__browse--inner`}
                            >
                                <h2 className={`home${v2ClassSuffix}__header`}>
                                    {translatableText.browseResources.text}
                                </h2>
                                {isRedesignEnabled ? (
                                    <CardSection cards={combinedCards} />
                                ) : (
                                    <CardGroup>
                                        {renderCards(featuredResources.cards)}
                                        {renderCards(cards)}
                                    </CardGroup>
                                )}
                            </div>
                        </div>
                        <div className={`home${v2ClassSuffix}__browse`}>
                            <div
                                className={`home${v2ClassSuffix}__browse--inner`}
                            >
                                <ContactCtaBlock
                                    isRedesignEnabled={isRedesignEnabled}
                                    analyticsMetadata={{
                                        parentComponent: 'home'
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            {isRedesignEnabled ? (
                <FooterV2
                    analyticsMetadata={{
                        parentComponent: 'home'
                    }}
                    isRedesignEnabled={isRedesignEnabled}
                />
            ) : (
                <Footer
                    logo={header.atlassianLogo.url}
                    className={'margin-top-large'}
                />
            )}
            <EasterEgg />
        </div>
    );
};

SACHomePage.defaultProps = {};

SACHomePage.propTypes = {
    entry: HomePageType,
    featureFlags: PropTypes.shape({
        isRedesignEnabled: PropTypes.bool
    })
};

export default SACHomePage;
