import { Schema } from '@hapi/joi';
import { AppError } from '../../../common/app-error';

const loaderResultValidator = (loaderResult: object, schema: Schema) => {
    const validationResult = schema.validate(loaderResult);

    if (validationResult.error) {
        const { error } = validationResult;
        throw new AppError({
            message: 'Error validating loader result',
            context: { loaderResult },
            previousError: error
        });
    }

    return loaderResult;
};

export default loaderResultValidator;
