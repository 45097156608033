import React from 'react';
import Tabs from '@atlaskit/tabs';
import { ProductCardsGroup } from '../product-card';
import { Context } from '../../../content';
import './deployment-selector-tabs.less';
import analytics from '../../utils/analytics/analytics';
import camelCase from 'lodash/camelCase';
import { AnalyticsMetadata } from '../../utils/analytics/types';

const handleTabSelect = (details: string, tab: string) => {
    const tabName = tab === 'Data Center' ? 'Server' : tab;
    analytics.trackEvent(camelCase(tabName + ' tab'), {
        //@ts-ignore
        event: 'clicked',
        eventComponent: 'tab',
        category: 'products cloud and server tabs',
        actionSubjectId: camelCase(details + ' products ' + tabName + ' tab'),
        action: 'click',
        label: tab
    });
};

export const DeploymentSelectorTabs = ({
    serverProducts,
    cloudProducts,
    details,
    analyticsMetadata
}: {
    serverProducts: Context[];
    cloudProducts: Context[];
    details: string;
    analyticsMetadata: AnalyticsMetadata;
}) => {
    return (
        <Tabs
            tabs={[
                {
                    label: 'Cloud',
                    content: (
                        <ProductCardsGroup
                            productList={cloudProducts}
                            analyticsMetadata={analyticsMetadata}
                        />
                    )
                },
                {
                    label: 'Data Center',
                    content: (
                        <ProductCardsGroup
                            productList={serverProducts}
                            analyticsMetadata={analyticsMetadata}
                        />
                    )
                }
            ]}
            onSelect={(selected) => {
                selected.label && handleTabSelect(details, selected.label);
            }}
        />
    );
};
