// disabling jsx-a11y plugin rules for non client-facing component
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CONTENTFUL_LOGO } from '../../utils/constants';
import { buildContentfulDeepLink } from '../../utils/build-contentful-deep-link';
import './contentful.less';
import Tooltip from '@atlaskit/tooltip';
import { WithAuthDetails } from '../../providers/auth-providers';

const WithContentfulEditMode = (WrappedComponent) => {
    class ContentEdit extends Component {
        constructor(props) {
            super(props);
            this.state = {
                hovering: false
            };
        }

        render() {
            const contentfulSpaceId = window.contentfulSpaceId;
            const {
                authDetails: { canEdit: userCanEdit }
            } = this.props;

            const { id, tooltipContent } = this.props;
            const classNames = this.state.hovering
                ? 'contentful-edit contentful-edit-icon-hover'
                : 'contentful-edit';

            return userCanEdit ? (
                // ensure wrapper WrappedComponent's classname is preserved
                <WrappedComponent
                    {...this.props}
                    className={classNames}
                    userCanEdit={userCanEdit}
                    contentfulEditIcon={
                        <Tooltip content={tooltipContent}>
                            <ContentfulEditIcon
                                id={id}
                                spaceId={contentfulSpaceId}
                                onMouseOver={() =>
                                    this.setState({ hovering: true })
                                }
                                onMouseOut={() =>
                                    this.setState({ hovering: false })
                                }
                            />
                        </Tooltip>
                    }
                />
            ) : (
                <WrappedComponent {...this.props} />
            );
        }
    }

    ContentEdit.propTypes = {
        id: PropTypes.string,
        tooltipContent: PropTypes.string,
        authDetails: PropTypes.shape({
            canEdit: PropTypes.bool
        })
    };

    return WithAuthDetails(ContentEdit);
};

const ContentfulEditIcon = (props) => (
    <img
        onMouseOver={props.onMouseOver}
        onFocus={props.onMouseOver}
        onMouseOut={props.onMouseOut}
        onBlur={props.onMouseOut}
        data-testid="contentful_edit_icon"
        src={CONTENTFUL_LOGO}
        className="contentful-edit-icon"
        alt="Contentful Edit Icon"
        onClick={() =>
            window.open(
                buildContentfulDeepLink(props.spaceId, props.id),
                '_blank'
            )
        }
        height="32"
        width="32"
    />
);

const EditButton = (props) =>
    props.userCanEdit ? (
        <div
            data-testid="contentful_edit_button"
            className="contentful-btn"
            onClick={() =>
                window.open(
                    buildContentfulDeepLink(window.contentfulSpaceId, props.id),
                    '_blank'
                )
            }
        >
            Edit Page in Contentful
        </div>
    ) : null;

ContentfulEditIcon.propTypes = {
    spaceId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func
};

EditButton.propTypes = {
    id: PropTypes.string.isRequired,
    userCanEdit: PropTypes.bool.isRequired
};

const ContentfulPageEditButton = WithContentfulEditMode(EditButton);

export { WithContentfulEditMode, ContentfulPageEditButton };
