import { safeURLPath } from './safe-url-path';
import OptionsError from './options-error';
import Logger from '../../logger';

export interface DocsHomeURLPathOptions {
    basePath: string | undefined;
}

export const getDocsHomePath = safeURLPath(
    (options: DocsHomeURLPathOptions) => {
        const { basePath } = options;
        if (!basePath || typeof basePath !== 'string') {
            Logger.error('basePath option is required and must be a string');
            throw new OptionsError(
                'basePath option is required and must be a string'
            );
        }

        return [options.basePath, 'resources'];
    }
);
