import React from 'react';
import KbHome, { KbHomePageEntry } from './kb-home';

export default function renderKbHome(
    entry: KbHomePageEntry,
    _pageTree: any,
    featureFlags: any
) {
    return <KbHome entry={entry} featureFlags={featureFlags} />;
}
