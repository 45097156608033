export const EDITOR = 'EDITOR';
export const RENDERER = 'RENDERER';
export const DOCUMENTATION = 'Documentation';
export const NOT_APPLICABLE = 'Not applicable';
export const LATEST = 'Latest';
export const CLOUD = 'Cloud';
export const PREVIEW_ERROR = 'preview_error';

// minimum viewport pixel width for desktop
export const DESKTOP_BREAKPOINT = 1095;
// minimum viewport pixel width for tablet
export const TABLET_BREAKPOINT = 768;

export const SAC_DOMAINS = new Set([
    'support.dev.internal.atlassian.com',
    'support.stg.internal.atlassian.com',
    'support.atlassian.com'
]);
