import { shape, bool, string } from 'prop-types';
import { IconType, Icon } from '../asset/asset.type';

export const ResourceType = shape({
    hideInHeader: bool,
    shortTitle: string,
    title: string,
    buttonLink: string,
    icon: IconType,
    shortDescription: string,
    description: string
});

export interface CardResource {
    hideInHeader: boolean;
    shortTitle: string;
    title: string;
    buttonLink: string;
    icon: Icon;
    shortDescription: string;
    description: string;
}

export interface CardResourceV2 {
    icon: Icon;
    title: string;
    sacHomeDescription: string;
    buttonLink: string;
    buttonText: string;
}

export interface IconDimensions {
    height: number;
    width: number;
}
