import React, { ReactElement } from 'react';
import { translatableText } from '../../utils/translatable-text';
import './hero-banner.less';
import { Search } from '../search';

interface Props {
    searchField: ReactElement<typeof Search>;
    subHeader: string;
    isRedesignEnabled?: boolean;
}

export const HeroBanner = (props: Props) => {
    const { searchField, subHeader, isRedesignEnabled } = props;

    const v2Class = isRedesignEnabled ? '-v2' : '';

    return (
        <section className={`hero-banner${v2Class}`} data-testid="hero_banner">
            <div
                data-testid="hero_inner"
                className={`hero-banner${v2Class}__inner`}
            >
                <h1
                    data-testid="hero_headline"
                    className={`hero-banner${v2Class}__headline`}
                >
                    {translatableText.heroBannerHeadline.text}
                </h1>
                <p
                    data-testid="hero_subheadline"
                    className={`hero-banner${v2Class}__headline--secondary`}
                >
                    {subHeader}
                </p>
                {searchField}
            </div>
        </section>
    );
};
