import React from 'react';
import camelCase from 'lodash/camelCase';
import { AtlasLink } from '../../../atlas-link';
import { getURLPath } from '../../../../../url';
import type { GqlKbArticleRichTextHyperlinkEntry } from '../../../../../content/types/content/kb-article';
import type { GqlCalloutRichTextHyperlinkEntry } from '../../../../../content/types/content/component-callout';

export type GqlKbRichTextHyperlinkEntry =
    | GqlKbArticleRichTextHyperlinkEntry
    | GqlCalloutRichTextHyperlinkEntry;

const getHyperlinkURLPath = (entry: GqlKbRichTextHyperlinkEntry): string => {
    const url = entry.url;
    let basePath: string | undefined;

    if ('urlParentEntry' in entry && entry.urlParentEntry) {
        basePath = entry.urlParentEntry.url;
    }

    const entryContentType = camelCase(entry.type);
    if (!basePath) {
        return getURLPath(entryContentType, { basePath: url });
    } else {
        return getURLPath(entryContentType, { basePath, slug: url });
    }
};

const renderHyperlinkedEntry = (
    hyperlinkEntry: GqlKbRichTextHyperlinkEntry,
    children: React.ReactNode
): JSX.Element => {
    const url = getHyperlinkURLPath(hyperlinkEntry);

    return <AtlasLink href={url}>{children}</AtlasLink>;
};

export default renderHyperlinkedEntry;
