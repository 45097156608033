import React from 'react';
import { hydrate } from 'react-dom';
import renderReactApp from '../templating/render-react-app';
import { createBrowserHistory } from 'history';

export const initializeBrowserApp = () => {
    // hashbang routes are being used so that S3 will still serve the
    // myRequests app at 'requests/index.html'. On load, the hash
    // path is converted back to a normal path so that it can be
    // used for dynamic routing inside the myRequests app.
    const history = createBrowserHistory();
    const hashbangPath = (/#!\/(.+)$/.exec(history.location.hash) || [])[1];
    const isRequestsRoute = /^\/requests/.exec(history.location.pathname);

    if (isRequestsRoute && hashbangPath) {
        const newPathname = history.location.pathname + hashbangPath;
        history.replace({
            pathname: newPathname,
            hash: ''
        });
    }

    const reactApp = (
        <React.StrictMode>
            {renderReactApp(
                window.__APP_INITIAL_STATE__.entry,
                window.__APP_INITIAL_STATE__.pageTree,
                window.__APP_INITIAL_STATE__.featureFlags
            )}
        </React.StrictMode>
    );

    hydrate(reactApp, document.querySelector('#root'));
};
