import React from 'react';
import DocumentedProduct from './documented-product';

export default function render(entry, pageTree, featureFlags) {
    return (
        <DocumentedProduct
            entry={entry}
            pageTree={pageTree}
            featureFlags={featureFlags}
        />
    );
}
