const generateHrefWithAnchor = (
    url: string,
    text: string,
    isSanitizing = true
) => {
    //handle case where text is/like "@mentions"
    if (!isSanitizing || text.includes('@')) {
        return `${url}#${text}`;
    }

    //Negative Set. Matches any character that is not in the set
    // of 0-9A-Za-z.? and most emoji
    const regex1 = /[^\d.?A-z\u00A9\u00AE\u2000-\u3300\uD000-\uDFFF]/g;
    const textWithDashes = text.replace(regex1, '-');

    const anchor = `#${textWithDashes}`;

    //Matches # and any character in the set. Then +, Match 1 or more of the preceding token
    const regex = /#[\d.A-Za-z\u00A9\u00AE\u2000-\u3300\uD000-\uDFFF-]+/g;
    const sanitizedAnchor = anchor.match(regex);

    if (sanitizedAnchor) {
        return `${url}${sanitizedAnchor[0]}`;
    }

    return `${url}${anchor}`;
};

export default generateHrefWithAnchor;
