import React, { useState, useContext } from 'react';
import { PageTreeList } from './page-tree-list';
import CurrentPageIdContext from '../../contexts/current-page-id-context';
import { PageTreeIcon } from './page-tree-icon';
import { AtlasLink } from '../atlas-link';

interface Props {
    title: string;
    slug: string;
    id: string;
    childList?: Props[];
}

const findCurrentPageInChildList = (
    currentPageId: string,
    childList: Props['childList']
) => {
    if (!childList) {
        return false;
    }

    return childList.some((item) => {
        if (item.id === currentPageId) {
            return true;
        }
        if (!item.childList) {
            return false;
        }
        // pagetree will only be 3 levels deep at most so we don't have to do
        // another nested 'some' loop here in the predicate

        return item.childList.some((item) => item.id === currentPageId);
    });
};

// eslint-disable-next-line complexity
export const PageTreeItem = ({ title, slug, id, childList }: Props) => {
    const currentPageId = useContext(CurrentPageIdContext);

    // check if PageTreeItem is the currentPage and/or currentPage is inside the childList
    const [isCurrentItem] = useState(currentPageId === id);
    const [isCurrentChildList] = useState(
        findCurrentPageInChildList(currentPageId, childList)
    );

    const [isOpen, setIsOpen] = useState(isCurrentItem || isCurrentChildList);

    return (
        <li
            className={`pagetree__item ${
                isCurrentItem
                    ? 'pagetree__item--current-item'
                    : isCurrentChildList
                    ? 'pagetree__item--current-child-list'
                    : isOpen
                    ? 'pagetree__item--expanded'
                    : ''
            }`}
            key={id}
        >
            <PageTreeIcon
                isCurrent={currentPageId === id}
                isOpen={isOpen}
                hasChildList={childList && !!childList.length}
                onClick={() => setIsOpen(!isOpen)}
                data-event-container="pagetree"
                data-event-component="pagetreeIcon"
                data-track-event="clicked"
            />
            <AtlasLink
                text={title}
                href={slug}
                dataName={`pagetreeItem${id}`}
            />
            {childList && (
                <PageTreeList isOpen={isOpen} isRoot={false}>
                    {childList.map(({ title, slug, id, childList }: Props) => (
                        <PageTreeItem
                            key={id}
                            title={title}
                            id={id}
                            slug={slug}
                            childList={childList}
                        />
                    ))}
                </PageTreeList>
            )}
        </li>
    );
};
