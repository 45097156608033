import axios from 'axios';

export const fetchCohortAttributes = async () => {
    try {
        const url = constructCSMCohortUrl();
        const apiOutput = await axios.get(url, {
            withCredentials: true
        });
        return apiOutput?.data?.attributes || {};
    } catch {
        return {};
    }
};

const constructCSMCohortUrl = () =>
    window.SAC_BASE_URL +
    '/gateway/api/customer-context/customer/cohort/csm-experiment';
