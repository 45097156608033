import React from 'react';
// @ts-ignore
import { Header, HeaderProps } from '../../components/header';
import Footer from '../../components/footer';
import RemoteReactApp from '../../components/remote-react-app';
import { CollectionTreeType } from '../../components/sidebar/sidebar.types';
import { MyRequests } from '../../../content';

interface MyRequestsEntry extends MyRequests {
    header: HeaderProps;
    preview: boolean;
}

interface WindowWithRequestURLs extends Window {
    CSP_REQUESTS_APP_MANIFEST_URL: string;
    CSP_REQUESTS_APP_MANIFEST_URL_BIFROST: string;
}

declare let window: WindowWithRequestURLs;

interface MyRequestsPageProps {
    entry: MyRequestsEntry;
    pageTree: CollectionTreeType[];
}

const getManifestUrl: any = () =>
    `${window.CSP_REQUESTS_APP_MANIFEST_URL_BIFROST}?v=${new Date().getTime()}`;

const getFilterKey: any = (fileKey: string) =>
    fileKey.endsWith('js') || fileKey.endsWith('css');

const getContentFiles: any = (content: any) => content.files;

const CspRequestsAppPage = ({
    entry: { resourceCards, header, id, preview }
}: MyRequestsPageProps) => {
    return (
        <div className="flex-column-page">
            <div className="content-wrapper">
                <Header
                    resources={resourceCards}
                    logo={header.atlassianSupportLogo.url}
                    atlassianSupportLogo={header.atlassianSupportLogo}
                    atlassianLogo={header.atlassianLogo}
                    cloudProducts={header.cloudProducts}
                    serverProducts={header.serverProducts}
                    id={header.id}
                    showPreviewBanner={preview}
                    additionalLinks={header.additionalLinks}
                    pageId={id}
                />
                <div className="main-content-container margin-top-large">
                    <RemoteReactApp
                        appTargetId="customer-support-portal"
                        manifestUrlProvider={getManifestUrl}
                        parseFilesFromManifestFile={getContentFiles}
                        filesFilter={getFilterKey}
                        includeLoadingSpinner={true}
                    />
                </div>
            </div>
            <Footer
                logo={header.atlassianLogo.url}
                className={'margin-top-large'}
            />
        </div>
    );
};

export default CspRequestsAppPage;
