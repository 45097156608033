import { safeURLPath } from './safe-url-path';
import OptionsError from './options-error';
import Logger from '../../logger';

export interface KbHomePageOptions {
    readonly basePath: string;
}

export const getKbHomePagePath = safeURLPath((options: KbHomePageOptions) => {
    const { basePath } = options;
    if (typeof basePath !== 'string') {
        Logger.info('basePath option is required and must be a string');
        throw new OptionsError(
            'basePath option is required and must be a string'
        );
    }

    return [basePath, 'kb'];
});
