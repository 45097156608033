import React from 'react';
import './platform-tabs.less';

interface TabProps {
    index: number;
    label: string;
    selected: boolean;
    onSelect: () => void;
}

export const Tab = ({ index, label, selected, onSelect }: TabProps) => {
    const classes = ['platform-tabs__tab'];
    if (selected) {
        classes.push('platform-tabs__tab--selected');
    }

    return (
        <li
            id={`platform-tabs:tab:${index}`}
            className={classes.join(' ')}
            role="tab"
            aria-selected={selected}
            aria-controls={`platform-tabs:panel:${index}`}
            tabIndex={selected ? 0 : undefined}
            onClick={onSelect}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onSelect();
                }
            }}
        >
            {label}
        </li>
    );
};
