import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import React from 'react';
import './dropdown-button.less';

export interface DropDownButtonProps {
    isActive?: boolean;
}

const DropDownButton = ({ isActive, ...rest }: DropDownButtonProps) => {
    return (
        <button
            data-testid="dropdown-button"
            className={`dropdown-button ${isActive ? 'active' : ''}`}
            {...rest}
        >
            <div className="dropdown-button__title">Filters</div>
            <div className="dropdown-button__icon">
                <ChevronDownIcon label="Filters Dropdown" />
            </div>
        </button>
    );
};

export default DropDownButton;
