import { hardcodedHubReferences } from './hardcoded-refs';

interface AppAndThemeCards {
    title: string;
    url: string;
    description: string;
    id: string;
}

// Reference Map that maps the contextId to their corresponding helpful links
const referenceMap = new Map([
    [
        // Jira Software Cloud
        '3yydXRuQ88gGCKOyYsAGaU',
        [
            hardcodedHubReferences.atlassianAccounts,
            hardcodedHubReferences.jiraCloudAdmin,
            hardcodedHubReferences.jiraCloudAppIOS,
            hardcodedHubReferences.jiraCloudAppMacOS,
            hardcodedHubReferences.jiraCloudAppAndroid,
            hardcodedHubReferences.migration
        ]
    ],
    [
        // Jira Service Management Cloud
        '1gXBh54v1sayA6w8yowM8u',
        [
            hardcodedHubReferences.atlassianAccounts,
            hardcodedHubReferences.jiraCloudAdmin,
            hardcodedHubReferences.jiraCloudAppIOS,
            hardcodedHubReferences.jiraCloudAppMacOS,
            hardcodedHubReferences.jiraCloudAppAndroid,
            hardcodedHubReferences.migration
        ]
    ],
    [
        // Jira Core Cloud
        '2QnaIiOyGAeI4aWeg4y8ms',
        [
            hardcodedHubReferences.atlassianAccounts,
            hardcodedHubReferences.jiraCloudAdmin,
            hardcodedHubReferences.jiraCloudAppIOS,
            hardcodedHubReferences.jiraCloudAppMacOS,
            hardcodedHubReferences.jiraCloudAppAndroid,
            hardcodedHubReferences.migration
        ]
    ],
    [
        // Confluence Cloud
        '4z1mIelYHYwE6ugCuqEg0y',
        [
            hardcodedHubReferences.atlassianAccounts,
            hardcodedHubReferences.migration
        ]
    ],
    [
        // Bitbucket Cloud
        'UhZk1Nq8UMkcuUsy6sI4C',
        [
            hardcodedHubReferences.atlassianAccounts,
            hardcodedHubReferences.migration
        ]
    ],
    [
        // Statuspage
        '70RA5tkL23bUJmCn3bDjGy',
        [
            hardcodedHubReferences.atlassianAccounts,
            hardcodedHubReferences.subscriptionsAndBilling
        ]
    ],
    [
        // Jira Cloud admin
        'pz65qHGV45exLdUYmW7Cc',
        [
            hardcodedHubReferences.atlassianAccounts,
            hardcodedHubReferences.jiraSoftwareCloud,
            hardcodedHubReferences.jiraServiceManagementCloud,
            hardcodedHubReferences.jiraWorkManagement,
            hardcodedHubReferences.jiraCloudAppIOS,
            hardcodedHubReferences.jiraCloudAppMacOS,
            hardcodedHubReferences.jiraCloudAppAndroid,
            hardcodedHubReferences.organizationAdmin,
            hardcodedHubReferences.userManagement,
            hardcodedHubReferences.migration
        ]
    ],
    [
        // Pre-Migration
        '42EVzqtkgj9erY2pseB05n',
        [
            hardcodedHubReferences.organizationAdmin,
            hardcodedHubReferences.migration
        ]
    ],
    [
        // Migration
        '7j5LyDcLbyrWuTeNGCtgW',
        [
            hardcodedHubReferences.atlassianAccounts,
            hardcodedHubReferences.organizationAdmin
        ]
    ],
    [
        // Post Migration
        'pshYAHCF2z2tW2TC9dNRk',
        [
            hardcodedHubReferences.organizationAdmin,
            hardcodedHubReferences.userManagement,
            hardcodedHubReferences.securityAndAccessPolicies,
            hardcodedHubReferences.provisioningUsers,
            hardcodedHubReferences.migration
        ]
    ],
    [
        // Organization Administration
        '48OgKzP3IKiOKPLp8nVEqG',
        [
            hardcodedHubReferences.atlassianAccounts,
            hardcodedHubReferences.userManagement,
            hardcodedHubReferences.provisioningUsers,
            hardcodedHubReferences.securityAndAccessPolicies,
            hardcodedHubReferences.subscriptionsAndBilling,
            hardcodedHubReferences.migration
        ]
    ],
    [
        // Security and access policies
        '2DyhnUE2lGsIrZgMyIxcrG',
        [
            hardcodedHubReferences.atlassianAccounts,
            hardcodedHubReferences.organizationAdmin,
            hardcodedHubReferences.userManagement,
            hardcodedHubReferences.provisioningUsers,
            hardcodedHubReferences.subscriptionsAndBilling,
            hardcodedHubReferences.migration
        ]
    ],
    [
        // Provisioning Users
        'coO8c7LypRMze4rajkC2I',
        [
            hardcodedHubReferences.atlassianAccounts,
            hardcodedHubReferences.organizationAdmin,
            hardcodedHubReferences.userManagement,
            hardcodedHubReferences.securityAndAccessPolicies,
            hardcodedHubReferences.subscriptionsAndBilling,
            hardcodedHubReferences.migration
        ]
    ],
    [
        // User Management
        '1evq30NXmWxA5D5s5OCFHY',
        [
            hardcodedHubReferences.atlassianAccounts,
            hardcodedHubReferences.organizationAdmin,
            hardcodedHubReferences.provisioningUsers,
            hardcodedHubReferences.securityAndAccessPolicies,
            hardcodedHubReferences.subscriptionsAndBilling,
            hardcodedHubReferences.migration
        ]
    ],
    [
        // Subscriptions and billing
        '2Zzp6xA8A7EPWzEcx9ieQy',
        [
            hardcodedHubReferences.atlassianAccounts,
            hardcodedHubReferences.organizationAdmin,
            hardcodedHubReferences.userManagement,
            hardcodedHubReferences.securityAndAccessPolicies,
            hardcodedHubReferences.provisioningUsers
        ]
    ],
    [
        // Jira Cloud mobile for iOS
        '3981JAWMVEsIEWWivaEiTq',
        [
            hardcodedHubReferences.jiraSoftwareCloud,
            hardcodedHubReferences.jiraServiceManagementCloud,
            hardcodedHubReferences.jiraWorkManagement,
            hardcodedHubReferences.jiraCloudAppMacOS,
            hardcodedHubReferences.jiraCloudAppAndroid
        ]
    ],
    [
        // Jira Cloud mobile for macOS
        '1Vq0XfobLij8lzVbb5j5DV',
        [
            hardcodedHubReferences.jiraSoftwareCloud,
            hardcodedHubReferences.jiraServiceManagementCloud,
            hardcodedHubReferences.jiraWorkManagement,
            hardcodedHubReferences.jiraCloudAppIOS,
            hardcodedHubReferences.jiraCloudAppAndroid
        ]
    ],
    [
        // Jira Cloud mobile for Android
        '1gpdDE2G09yKbUHkuVStz3',
        [
            hardcodedHubReferences.jiraSoftwareCloud,
            hardcodedHubReferences.jiraServiceManagementCloud,
            hardcodedHubReferences.jiraWorkManagement,
            hardcodedHubReferences.jiraCloudAppIOS,
            hardcodedHubReferences.jiraCloudAppMacOS
        ]
    ],
    [
        // Opsgenie app for iOS
        '5RgUQE2oTj1bi0pDCw7nRk',
        [
            hardcodedHubReferences.opsgenieProduct,
            hardcodedHubReferences.opsegenieAppAndroid,
            hardcodedHubReferences.opsgenieAppBlackberry
        ]
    ],
    [
        // Opsgenie app for Android
        '3IFWp9bsh5YF3ofnD8JU7y',
        [
            hardcodedHubReferences.opsgenieProduct,
            hardcodedHubReferences.opsgenieAppIOS,
            hardcodedHubReferences.opsgenieAppBlackberry
        ]
    ],
    [
        // Opsgenie app for BlackBerry
        '1EgUesitO2lkmvtXGdwnVA',
        [
            hardcodedHubReferences.opsgenieProduct,
            hardcodedHubReferences.opsgenieAppIOS,
            hardcodedHubReferences.opsegenieAppAndroid
        ]
    ],
    [
        'ILiRNXwGC5pzsmOl2uWLu',
        [
            hardcodedHubReferences.jiraSoftwareCloud,
            hardcodedHubReferences.jiraServiceManagementCloud,
            hardcodedHubReferences.jiraWorkManagement
        ]
    ]
]);

// Helper function that enables us to add "Helpful links" to the linksArray
const shouldAddHelpfulLinks = (
    context: string,
    list: Set<string>,
    featureFlagValue: boolean | undefined
) => {
    return list.has(context) && featureFlagValue;
};

const contextsWithOpsgenieLinks = new Set([
    '5RgUQE2oTj1bi0pDCw7nRk',
    '3IFWp9bsh5YF3ofnD8JU7y',
    '1EgUesitO2lkmvtXGdwnVA'
]);
const contextsWithAtlassianCloudLinks = new Set([
    '3yydXRuQ88gGCKOyYsAGaU',
    '1gXBh54v1sayA6w8yowM8u',
    '2QnaIiOyGAeI4aWeg4y8ms',
    '4z1mIelYHYwE6ugCuqEg0y',
    'UhZk1Nq8UMkcuUsy6sI4C',
    '70RA5tkL23bUJmCn3bDjGy',
    'pz65qHGV45exLdUYmW7Cc',
    '42EVzqtkgj9erY2pseB05n',
    '7j5LyDcLbyrWuTeNGCtgW',
    'pshYAHCF2z2tW2TC9dNRk',
    '48OgKzP3IKiOKPLp8nVEqG',
    '2DyhnUE2lGsIrZgMyIxcrG',
    'coO8c7LypRMze4rajkC2I',
    '1evq30NXmWxA5D5s5OCFHY',
    '2Zzp6xA8A7EPWzEcx9ieQy',
    '3981JAWMVEsIEWWivaEiTq',
    '1Vq0XfobLij8lzVbb5j5DV',
    '1gpdDE2G09yKbUHkuVStz3',
    'ILiRNXwGC5pzsmOl2uWLu'
]);
const contextsWithCloudAutomationLinks = new Set([
    '3yydXRuQ88gGCKOyYsAGaU',
    '1gXBh54v1sayA6w8yowM8u',
    '2QnaIiOyGAeI4aWeg4y8ms'
]);
// Several context types have their "other helpful links" content
// temporarily hardcoded as per FRAK-1944
// eslint-disable-next-line complexity
const getHardcodedReferences = (
    contextId: string
    // eslint-disable-next-line max-params
): AppAndThemeCards[] => {
    let linksArray: AppAndThemeCards[] = [];

    // "if" is used instead of "else if" since we want getHardcodedReferences to execute each of the below conditions
    if (shouldAddHelpfulLinks(contextId, contextsWithOpsgenieLinks, true)) {
        linksArray = linksArray.concat(referenceMap.get(contextId) || []);
    }

    if (
        shouldAddHelpfulLinks(contextId, contextsWithAtlassianCloudLinks, true)
    ) {
        linksArray = linksArray.concat(referenceMap.get(contextId) || []);
    }

    if (
        shouldAddHelpfulLinks(contextId, contextsWithCloudAutomationLinks, true)
    ) {
        linksArray = linksArray.concat(
            linksArray.splice(5, 0, hardcodedHubReferences.cloudAutomation)
        );
    }

    // Opsgenie
    if (contextId === '5t76PH9Mw7zagigkXzUa8H') {
        linksArray.push(
            hardcodedHubReferences.atlassianAccounts,
            hardcodedHubReferences.opsgenieAppIOS,
            hardcodedHubReferences.opsegenieAppAndroid,
            hardcodedHubReferences.opsgenieAppBlackberry
        );

        return linksArray;
    }

    // Atlassian accounts
    if (contextId === '6ZuqXmRiE2XTrsWbbtpyt9') {
        linksArray.push(
            hardcodedHubReferences.jiraSoftwareCloud,
            hardcodedHubReferences.jiraServiceManagementCloud,
            hardcodedHubReferences.jiraWorkManagement,
            hardcodedHubReferences.confluenceCloud,
            hardcodedHubReferences.bitbucketCloud,
            hardcodedHubReferences.statuspage,
            hardcodedHubReferences.opsgenieProduct
        );

        return linksArray;
    }

    return linksArray;
};

export { getHardcodedReferences };
