import React from 'react';
import CollectionPage from './collection';

export default function render(entry, pageTree, featureFlags) {
    return (
        <CollectionPage
            entry={entry}
            pageTree={pageTree}
            featureFlags={featureFlags}
        />
    );
}
