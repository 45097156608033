import React from 'react';
import './no-search-results.less';
import { translatableText } from '../../utils/translatable-text';

interface NoSearchResultsProps {
    searchTerm: string;
}

export const NoSearchResults = ({ searchTerm }: NoSearchResultsProps) => {
    const noResultsText = searchTerm
        ? `${translatableText.noSearchResults.text} "${searchTerm}".`
        : translatableText.noSearchResults.text.replace(' for', '.');

    return (
        <span data-testid="no-search-results" className="no-results-text">
            {noResultsText}
        </span>
    );
};
