import React, { FC } from 'react';
import { AtlasLink } from '../atlas-link';
import generateHrefWithAnchor from '../../utils/generate-href-with-anchor';
import { translatableText } from '../../utils/translatable-text';
import { getHeadersText } from '../../../content/utils/table-of-contents';
import { TopicReferenceTOCProp } from '../../../content/types/content/topic-reference-toc-prop';

interface TableOfContentsProps {
    links?: TopicReferenceTOCProp[];
    topicReferences?: TopicReferenceTOCProp[];
    url: string;
    hideOnMobile?: boolean;
}

const TableOfContents: FC<TableOfContentsProps> = (
    props: TableOfContentsProps
) => {
    // "links" are headers pulled from the sectionOverview/body content of the page being loaded.
    // "topicReferences" are pulled from child topics on flattened topic-set pages
    const {
        links = [],
        topicReferences = [],
        url,
        hideOnMobile = true
    } = props;

    let childTopicHeaders: TopicReferenceTOCProp[] = [];
    // map through child topics and pull the headers out from their body content
    // that will be rendered on flattened topic-set pages.
    topicReferences.forEach(({ body }) => {
        const bodyHeaders = body ? getHeadersText(body) : [];
        childTopicHeaders = childTopicHeaders.concat(bodyHeaders);
    });

    if (links.length + childTopicHeaders.length < 2) {
        return null;
    }

    const tableOfContentClasses = [
        'sidebar__section',
        'sidebar__table-of-contents'
    ];

    if (hideOnMobile) {
        tableOfContentClasses.push('sidebar__section--hidden-mobile');
    }
    return (
        <div className={tableOfContentClasses.join(' ')}>
            <span
                className="sidebar__heading"
                data-testid="cy_sidebar__heading"
            >
                {translatableText.onThisPage.text}
            </span>

            {links.map(({ id, title }) => (
                <AtlasLink
                    dataName={`sidebar link ${title}`}
                    key={title}
                    className="sidebar__link"
                    text={title}
                    href={generateHrefWithAnchor(url, id)}
                />
            ))}
            {childTopicHeaders.map(({ id, title }) => (
                <AtlasLink
                    dataName={`sidebar link ${title}`}
                    key={title}
                    className="sidebar__link"
                    text={title}
                    href={generateHrefWithAnchor(url, id)}
                />
            ))}
        </div>
    );
};

export default TableOfContents;
