import React from 'react';
import track from './track';

interface Props {
    href: string;
    text?: string;
    className?: string;
    setRef?: React.Ref<any>;
    children?: React.ReactNode;
    // when the link is clicked the link text will be sent as the anaylics payload, dataName is sent instead if passed in
    dataName?: string;
    target?: string;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    onAuxClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    onContextMenu?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

const ATLASSIAN_DOMAINS = ['atlassian.com'];

/**
 * Determines the appropriate rel attribute for a given URL.
 * We only want 'noreferrer noopener' to be used for external links.
 */
const determineRelAttribute = (href: string): string | null => {
    // The constructor will only update the hostname to that of the `base` parameter if the hostname doesn't already exist.
    const url = new URL(href, 'https://relative');

    const firstParty =
        url.hostname === 'relative' ||
        ATLASSIAN_DOMAINS.some(
            (d) => url.hostname === d || url.hostname.endsWith(`.${d}`)
        );
    return firstParty ? null : 'noreferrer noopener';
};

/**
 * Creates a link to a URL.
 * @example
 * <AtlasLink href="https://www.atlassian.com">Click me!</AtlasLink>
 */
const AtlasLink = ({
    href,
    text,
    className,
    children,
    dataName,
    setRef,
    ...rest
}: Props) => {
    const rel = determineRelAttribute(href) ?? undefined;

    return (
        <a
            ref={setRef}
            href={href}
            data-testid="atlas_link"
            className={className}
            onClick={() => track(href, text, dataName)}
            rel={rel}
            {...rest}
        >
            {children ? children : text}
        </a>
    );
};

export default AtlasLink;
