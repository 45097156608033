import React, { useEffect } from 'react';
// @ts-ignore
import Button from '@atlaskit/button';
import ButtonV2 from '../button-v2';
import { AtlasLink, track } from '../atlas-link';
import './contact-list-item.less';
import { translatableText } from '../../utils/translatable-text';
import { useSupportFormUrls } from '../../hooks/use-support-form-urls';
import { useAuth } from '../../providers/auth-providers';
import analytics from '../../utils/analytics';
import { AnalyticsMetadata } from '../../utils/analytics/types';

interface ContactProps {
    supportFormUrls?: SupportFormUrls;
    isRedesignEnabled?: boolean;
    analyticsMetadata?: AnalyticsMetadata;
}

export interface SupportFormUrls {
    authenticated: string | null;
    unauthenticated: string | null;
}

export const ContactCtaBlock = ({
    supportFormUrls,
    isRedesignEnabled,
    analyticsMetadata
}: ContactProps) => {
    const supportFormLinks = useSupportFormUrls(supportFormUrls);
    const { isAuthenticated } = useAuth();

    const v2ClassSuffix = isRedesignEnabled ? '-v2' : '';

    const { href: supportFormHref, text: supportFormText } =
        supportFormLinks[
            isAuthenticated ? 'authenticated' : 'needsAuthentication'
        ];
    return (
        <div className={`contact-block${v2ClassSuffix}`}>
            <div className={`contact-block${v2ClassSuffix}--inner`}>
                <h2 className={`contact-block${v2ClassSuffix}__header`}>
                    {translatableText.notFindingTheHelpYouNeed.text}
                </h2>
                {isRedesignEnabled ? (
                    <ButtonV2
                        size="small"
                        variant="secondary"
                        href={supportFormHref}
                        ariaLabel="contact support"
                        onClick={() => {
                            analytics.trackEvent('contact support', {
                                // @ts-ignore
                                event: 'clicked',
                                eventComponent: 'contact-support',
                                actionSubjectId: 'link',
                                category: 'contact',
                                action: 'clicked',
                                label: 'contact support',
                                isRedesignEnabled,
                                ...analyticsMetadata
                            });
                        }}
                    >
                        {supportFormText}
                    </ButtonV2>
                ) : (
                    <Button
                        ariaLabel="contact support"
                        className="contact-block--btn margin-top-large"
                        onClick={() =>
                            track(
                                supportFormHref,
                                'Contact Technical Support',
                                'contactTechnicalSupportButton'
                            )
                        }
                        href={supportFormHref}
                    >
                        {supportFormText}
                    </Button>
                )}
                {supportFormUrls && supportFormUrls.unauthenticated && (
                    <div className="margin-top-medium">
                        <Button
                            className='margin-top-medium"'
                            appearance="link"
                            href={supportFormUrls.unauthenticated}
                            onClick={() =>
                                track(
                                    supportFormUrls?.unauthenticated,
                                    'Contact Support Without Logging In',
                                    'contactSupportWithoutLoggingIn'
                                )
                            }
                        >
                            {
                                translatableText.supportRequestWithoutLoggingIn
                                    .text
                            }
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export const ContactListItem = ({
    supportFormUrls,
    isRedesignEnabled
}: ContactProps) => {
    const supportFormLinks = useSupportFormUrls(supportFormUrls);
    const { isAuthenticated } = useAuth();

    const { href: supportFormHref } =
        supportFormLinks[
            isAuthenticated ? 'authenticated' : 'needsAuthentication'
        ];

    useEffect(() => {
        if (isAuthenticated) {
            analytics.trackEvent('goToContactFormButtonLoaded', {
                // @ts-ignore
                event: 'loaded',
                eventComponent: 'goToContactFormButtonLoaded',
                action: 'loaded'
            });
        }
    }, [isAuthenticated]);
    return (
        <AtlasLink
            dataName="contactButton"
            href={supportFormHref}
            data-testid="contact-button"
            text={
                isRedesignEnabled
                    ? translatableText.contactV2.text
                    : translatableText.contact.text
            }
        />
    );
};
