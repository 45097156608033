import React, { useEffect } from 'react';
import debounce from 'lodash/debounce';
import Pagination from '@atlaskit/pagination';
import Spinner from '@atlaskit/spinner';
import { HeroBanner } from '../../components/hero-banner';
import { Search } from '../../components/search';
import Footer from '../../components/footer';
import { Header, HeaderProps, Resources } from '../../components/header';
import './search-results.less';
import { translatableText } from '../../utils/translatable-text';
import {
    SearchResultsDisplay,
    SearchResultCard,
    NoSearchResults
} from '../../components/search-results';
import FilterSelector from './components/filter-selector';
import ResetButton from './components/reset-button';
import {
    ContentTypeTab,
    ContentTypeTabs,
    DEFAULT_CONTENT_TYPE_TAB
} from './components/content-type-tabs';
import {
    Deployment,
    deploymentsArray as deployments,
    contentArray,
    Content,
    FiltersApiResponse,
    SearchOptionsApiResponse,
    SortBy
} from './types';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from './state/store';
import { updateSearchTerm } from './state/search/search-reducer';
import {
    MAX_NUM_OF_RESULTS,
    NUM_OF_RESULTS_PER_PAGE,
    SearchQuery,
    fetchSearchResults
} from './state/search/utils/fetch-search-results';
import Logger from '../../../logger';
import {
    resetAllValues,
    setContentFilterQueryValue,
    setDeploymentFilterQueryValue,
    setProductsFilterQueryValue,
    setVersionsFilterQueryValue,
    setProductTitleReset,
    setVersionsTitleReset,
    setShouldPulseProductFilter,
    setShouldPulseVersionFilter,
    saveEntry,
    setSortByQueryValue,
    resetAllValuesV2,
    setProductFilterValue,
    setContentTypeFilterValue,
    setCurrentResultIndex
} from './state/filter-reducer';
import { ListFilter } from '../../components/list-filter';
import { Divider } from '../../components/divider';
import analytics from '../../utils/analytics';
import { ResultsText } from '../../components/search-results/results-text';
import { FilterTags } from '../../components/filter-tags';

const searchHandler = async (
    dispatch: AppDispatch,
    searchTerm: string,
    query: SearchQuery,
    featureFlags: {
        isSearchTermHighlightingEnabled?: boolean;
        isSacSearch2Enabled?: boolean;
    },
    searchStartTime: number
    // eslint-disable-next-line max-params
) => {
    try {
        await dispatch(
            fetchSearchResults({
                searchTerm,
                query,
                featureFlags: {
                    isSearchTermHighlightingEnabled:
                        featureFlags.isSearchTermHighlightingEnabled,
                    isSacSearch2Enabled: featureFlags.isSacSearch2Enabled
                }
            })
        ).unwrap();

        const searchResponseTime = Date.now() - searchStartTime;
        analytics.sendGASV3OperationalEvent(
            {
                action: 'rendered',
                actionSubject: 'results',
                actionSubjectId: 'searchResults'
            },
            { searchTerm, responseTime: searchResponseTime }
        );
    } catch (error) {
        Logger.error({ error }, 'Error dispatching the search handler');
    }
};

const debouncedQueryHelpAggregator = debounce(searchHandler, 500);

export interface SearchResultsPageEntry {
    id: string;
    type: string;
    url: string;
    resources: Resources;
    header: HeaderProps;
    preview: boolean;
    filters: FiltersApiResponse;
    searchOptions: SearchOptionsApiResponse;
}

interface SearchResultsPageProps {
    entry: SearchResultsPageEntry;
    featureFlags: {
        isSearchTermHighlightingEnabled?: boolean;
        isSacSearch2Enabled?: boolean;
        isRedesignEnabled?: boolean;
    };
}

// eslint-disable-next-line complexity
export const SearchResultsPage = ({
    entry,
    featureFlags
}: SearchResultsPageProps) => {
    const {
        isSearchTermHighlightingEnabled,
        isSacSearch2Enabled,
        isRedesignEnabled
    } = featureFlags;

    const { isLoading, searchTerm, searchResponse, showPagination } =
        useSelector((state: RootState) => state.search);

    const {
        contentTypeQueryValue,
        contentTypeFilterValue,
        deploymentQueryValue,
        deploymentQueryValueV2,
        productsQueryValue,
        productFilterValue,
        productsFilterValues,
        productsFilterValuesV2,
        productsTitleReset,
        versionsQueryValue,
        versionsFilterValues,
        versionsFilterValuesV2,
        versionsTitleReset,
        shouldPulseProductFilter,
        shouldPulseVersionFilter,
        showVersionsFilter,
        sortByQueryValue,
        sortByFilterValues,
        contentTypeValues,
        currentResultIndex
    } = useSelector((state: RootState) => state.filter);

    const dispatch: AppDispatch = useDispatch();
    const handleSearchChange = (searchValue: string) => {
        const action = updateSearchTerm(searchValue);
        dispatch(action);
    };

    // eslint-disable-next-line complexity
    const handleSubmit = (searchValue: string) => {
        if (!searchValue) {
            return;
        }

        const searchStartTime = Date.now();

        // keep the search value and url in sync
        const url = new URL(window.location.href);
        url.searchParams.set('searchTerm', searchValue);

        const deployment = isSacSearch2Enabled
            ? deploymentQueryValueV2
            : deploymentQueryValue || '';
        const product = isSacSearch2Enabled
            ? productFilterValue.key
            : productsQueryValue[0] || '';
        const version = versionsQueryValue[0] || '';

        deployment
            ? url.searchParams.set('deployment', deployment)
            : url.searchParams.delete('deployment');
        product
            ? url.searchParams.set('product', product)
            : url.searchParams.delete('product');
        version
            ? url.searchParams.set('version', version)
            : url.searchParams.delete('version');

        window.history.replaceState({}, '', url);

        const productQueryValueV2 = [];
        if (productFilterValue.key) {
            productQueryValueV2.push(productFilterValue.key);
        }

        const contentTypeQueryValueV2 = [];
        if (
            contentTypeFilterValue?.key &&
            contentTypeFilterValue.key !== DEFAULT_CONTENT_TYPE_TAB.key
        ) {
            contentTypeQueryValueV2.push(contentTypeFilterValue.key);
        }

        debouncedQueryHelpAggregator(
            dispatch,
            searchTerm,
            {
                deployment: isSacSearch2Enabled
                    ? deploymentQueryValueV2
                    : deploymentQueryValue,
                productsQueryValue: isSacSearch2Enabled
                    ? productQueryValueV2
                    : productsQueryValue,
                contentTypes: isSacSearch2Enabled
                    ? contentTypeQueryValueV2
                    : contentTypeQueryValue,
                version: versionsQueryValue,
                sortByQueryValue,
                pageIndex: currentResultIndex
            },
            {
                isSearchTermHighlightingEnabled:
                    isSearchTermHighlightingEnabled,
                isSacSearch2Enabled: isSacSearch2Enabled
            },
            searchStartTime
        );
    };

    const handleSortByFilterChange = (value: SortBy) => {
        const oldSortByQueryValue = sortByQueryValue;
        dispatch(setSortByQueryValue(value));
        analytics.trackEvent('Sort by filter', {
            // @ts-ignore
            event: 'clicked',
            eventComponent: 'filter',
            actionSubjectId: 'sort-by-filter',
            category: 'search results filter',
            action: 'clicked',
            // label: 'featured article',
            currentSelection: oldSortByQueryValue,
            newSelection: value
        });
    };

    useEffect(() => {
        handleSubmit(searchTerm);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        deploymentQueryValue,
        deploymentQueryValueV2,
        productsQueryValue,
        productFilterValue,
        contentTypeQueryValue,
        contentTypeFilterValue,
        versionsQueryValue,
        sortByQueryValue,
        currentResultIndex
    ]);

    // initialize the query from the url for when users first visit
    useEffect(() => {
        const parsedUrl = new URL(window.location.href);
        const searchTermFromUrl = parsedUrl.searchParams.get('searchTerm');
        const deploymentFromUrl = parsedUrl.searchParams.get('deployment');
        const productFromUrl = parsedUrl.searchParams.get('product');
        const versionFromUrl = parsedUrl.searchParams.get('version');
        const product = isSacSearch2Enabled
            ? entry?.searchOptions?.filters?.platforms
                  .filter((platform) => platform.key === deploymentFromUrl)?.[0]
                  ?.products.filter(
                      (product) => product.key === productFromUrl
                  )?.[0]
            : entry?.filters?.products.filter(
                  (product) =>
                      product.key === productFromUrl &&
                      product.platform === deploymentFromUrl
              )?.[0];

        dispatch(updateSearchTerm(searchTermFromUrl || ''));
        // Remove when isSacSearch2Enabled is fully implemented
        dispatch(
            setDeploymentFilterQueryValue(
                (deploymentFromUrl as Deployment) || Deployment.CLOUD
            )
        );
        // Remove when isSacSearch2Enabled is fully implemented
        dispatch(setProductsFilterQueryValue(product ? [product.key] : []));
        // Search v2
        dispatch(
            setProductFilterValue({
                deployment: (deploymentFromUrl as Deployment) || undefined,
                product: {
                    ...product,
                    label: ''
                }
            })
        );
        dispatch(
            setVersionsFilterQueryValue(versionFromUrl ? [versionFromUrl] : [])
        );

        dispatch(saveEntry(entry));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Remove when isSacSearch2Enabled is fully implemented
    const renderVersionFilter = () => {
        if (showVersionsFilter) {
            return (
                <>
                    <FilterSelector
                        filterTitle="Version"
                        filterValues={versionsFilterValues}
                        setQueryValues={(value: string[]) =>
                            dispatch(setVersionsFilterQueryValue(value))
                        }
                        allowFilterAll={false}
                        titleReset={versionsTitleReset}
                        setTitleReset={() => dispatch(setVersionsTitleReset())}
                        shouldPulse={shouldPulseVersionFilter}
                        setShouldPulse={() =>
                            dispatch(setShouldPulseVersionFilter())
                        }
                        defaultSelected={versionsQueryValue[0]}
                    />
                </>
            );
        }
    };

    const renderNewVersionFilter = () => {
        if (
            deploymentQueryValueV2 === Deployment.DATA_CENTER &&
            versionsFilterValuesV2?.length > 0
        ) {
            return (
                <>
                    <Divider />
                    <ListFilter
                        title={'Version'}
                        selected={{
                            key: versionsQueryValue[0] || ''
                        }}
                        optionsLists={[{ options: versionsFilterValuesV2 }]}
                        isScrollable={true}
                        onChange={(option) => {
                            dispatch(setVersionsFilterQueryValue([option.key]));
                        }}
                        analyticsMetadata={{
                            selectedProduct: productFilterValue.key
                        }}
                    />
                </>
            );
        }
    };

    const renderSearchResults = () => {
        if (isSacSearch2Enabled) {
            return searchResponse.results.map((result: any, index: number) => (
                <SearchResultCard
                    key={result.content.contentAri}
                    id={result.content.contentAri}
                    title={result.content.title}
                    description={
                        isSearchTermHighlightingEnabled
                            ? result?.highlights?.description ||
                              result.content.description
                            : result.content.description
                    }
                    href={result.content.url}
                    dateModified={result.content.metadata.lastPublishedAt}
                    markedUseful={
                        result.content.metadata?.trustFactors?.helpfulCount
                    }
                    views={result.content.metadata?.trustFactors?.numViews}
                    searchTerm={searchTerm}
                    position={index + 1}
                    queryId={searchResponse.queryId}
                    products={result.content.products}
                    productIcons={entry.header.productIcons}
                    isSearchTermHighlightingEnabled={
                        isSearchTermHighlightingEnabled
                    }
                    isSacSearch2Enabled={isSacSearch2Enabled}
                />
            ));
        } else {
            return searchResponse.results.map((result: any, index: number) => (
                <SearchResultCard
                    key={result.id}
                    id={result.id}
                    title={result.title}
                    description={
                        isSearchTermHighlightingEnabled
                            ? result?.highlights?.description ||
                              result.description
                            : result.description
                    }
                    href={result.url}
                    dateModified={result.updatedDate}
                    markedUseful={result.likes}
                    views={result.views}
                    searchTerm={searchTerm}
                    position={index + 1}
                    queryId={searchResponse.queryId}
                    products={result.products}
                    deployment={deploymentQueryValue}
                    isSearchTermHighlightingEnabled={
                        isSearchTermHighlightingEnabled
                    }
                />
            ));
        }
    };

    // Render product filter v2
    const renderProductFilter = () => {
        return (
            <ListFilter
                onChange={(option, headerKey) => {
                    dispatch(
                        setProductFilterValue({
                            deployment: headerKey as Deployment,
                            product: option
                        })
                    );
                }}
                title="Product"
                selected={{
                    key: productFilterValue.key,
                    headerKey: deploymentQueryValueV2
                }}
                optionsLists={productsFilterValuesV2.map((platform) => ({
                    header: {
                        key: platform.key,
                        name: platform.name
                    },
                    options: platform.products.map((product) => ({
                        ...product,
                        label: `${product.name} (${
                            searchResponse?.facets?.platform?.[platform.key]
                                ?.products?.[product.key]?.count || 0
                        })`
                    }))
                }))}
            />
        );
    };

    const renderTotalResults = () => {
        const startCount = currentResultIndex + 1;
        return (
            <ResultsText
                searchTerm={searchResponse?.metadata?.query?.originalQuery}
                startCount={startCount}
                endCount={startCount + searchResponse.pagination?.size - 1}
                totalCount={searchResponse.pagination?.total}
            />
        );
    };

    const renderFilterTags = () => {
        const tags = [];
        if (productFilterValue.key) {
            tags.push({
                type: 'product',
                key: productFilterValue.key,
                name: productFilterValue.name,
                analyticsMetadata: {
                    searchTerm,
                    clearedFilterTags: [
                        productFilterValue.key,
                        ...versionsQueryValue
                    ]
                },
                onRemove: () => dispatch(resetAllValuesV2())
            });

            versionsQueryValue.map((version) => {
                tags.push({
                    type: 'version',
                    key: version,
                    name: version,
                    analyticsMetadata: {
                        searchTerm,
                        clearedFilterTags: [version]
                    },
                    onRemove: () =>
                        dispatch(
                            setVersionsFilterQueryValue(
                                versionsQueryValue.filter((v) => v !== version)
                            )
                        )
                });
            });
        }

        return <FilterTags tags={tags} />;
    };

    const renderPagination = () => {
        return (
            <div className="pagination" data-testid="search-results-pagination">
                <Pagination
                    pages={
                        searchResponse.pagination
                            ? Array.from(
                                  {
                                      length: Math.min(
                                          Math.ceil(
                                              searchResponse.pagination?.total /
                                                  NUM_OF_RESULTS_PER_PAGE
                                          ),
                                          MAX_NUM_OF_RESULTS /
                                              NUM_OF_RESULTS_PER_PAGE
                                      )
                                  },
                                  (_, i) => i + 1
                              )
                            : []
                    }
                    selectedIndex={Math.floor(
                        currentResultIndex / NUM_OF_RESULTS_PER_PAGE
                    )}
                    onChange={(
                        _: React.SyntheticEvent<Element, Event>,
                        page: number
                    ) => {
                        const oldPage =
                            Math.floor(
                                currentResultIndex / NUM_OF_RESULTS_PER_PAGE
                            ) + 1;

                        if (oldPage !== page) {
                            const newResultIndex =
                                (page - 1) * NUM_OF_RESULTS_PER_PAGE;
                            dispatch(setCurrentResultIndex(newResultIndex));
                            analytics.trackEvent('pagination', {
                                // @ts-ignore
                                event: 'clicked',
                                eventComponent: 'pagination',
                                actionSubjectId: 'pagination',
                                category: 'pagination',
                                action: 'clicked',
                                currentSelection: oldPage,
                                newSelection: page
                            });
                        }
                    }}
                />
            </div>
        );
    };

    const renderContentTypeTabs = () => {
        const tabs: ContentTypeTab[] = [];
        let totalDocCount = 0;

        contentTypeValues.map((contentType) => {
            const docCount =
                searchResponse?.facets?.contentType?.[contentType.key]?.count ||
                0;
            totalDocCount += docCount;
            tabs.push({
                key: contentType.key,
                name: contentType.name,
                label: `${contentType.name} (${docCount})`,
                testId: `${contentType.key}-tab`,
                docCount
            });
        });

        tabs.unshift({
            ...DEFAULT_CONTENT_TYPE_TAB,
            label: `${DEFAULT_CONTENT_TYPE_TAB.name} (${totalDocCount})`,
            docCount: totalDocCount
        });

        const handleTabSelect = (selectedContentType: ContentTypeTab) => {
            const previousValue = contentTypeFilterValue.key;
            dispatch(setContentTypeFilterValue(selectedContentType));
            return previousValue;
        };

        return <ContentTypeTabs tabs={tabs} onSelect={handleTabSelect} />;
    };

    const subHeaderText = translatableText.heroBannerSubheader.text;

    const searchV2LayoutClass = isSacSearch2Enabled
        ? 'search-results-page'
        : '';

    const placeholder = isRedesignEnabled
        ? translatableText.searchPlaceholderV2.text
        : translatableText.searchPlaceholder.text;

    return (
        <div className="search-results" data-testid="page-template">
            <div className="content-wrapper">
                <Header
                    id={entry.header.id}
                    pageId={entry.id}
                    resources={entry.resources}
                    additionalLinks={entry.header.additionalLinks}
                    atlassianLogo={entry.header.atlassianLogo}
                    logo={entry.header.atlassianSupportLogo.url}
                    showPreviewBanner={entry.preview}
                    atlassianSupportLogo={entry.header.atlassianSupportLogo}
                    serverProducts={entry.header.serverProducts}
                    cloudProducts={entry.header.cloudProducts}
                />
                <main>
                    <HeroBanner
                        searchField={
                            <Search
                                onSearch={handleSubmit}
                                placeholder={placeholder}
                                initialValue={searchTerm}
                                analyticsMetadata={{
                                    parentComponent: 'search-results'
                                }}
                                onChange={handleSearchChange}
                                isRedesignEnabled={isRedesignEnabled}
                            />
                        }
                        subHeader={subHeaderText}
                        isRedesignEnabled={isRedesignEnabled}
                    />
                    <div
                        data-testid="search-results-page"
                        className={searchV2LayoutClass}
                    >
                        {isSacSearch2Enabled ? (
                            <div className="above-search-cards-container">
                                <div className="content-type-tabs">
                                    {renderContentTypeTabs()}
                                </div>
                                <div className="search-results-summary">
                                    {!isLoading && renderTotalResults()}
                                </div>
                                <div className="sort-by-filter-section">
                                    <div className="sort-by-filter-text">
                                        {translatableText.sortBy.text}
                                    </div>
                                    <FilterSelector
                                        filterTitle={
                                            translatableText.sortBy.text
                                        }
                                        filterValues={sortByFilterValues}
                                        allowFilterAll={false}
                                        setQueryValues={
                                            handleSortByFilterChange
                                        }
                                        queryByString
                                        defaultSelected={
                                            sortByFilterValues[0].name
                                        }
                                        showBorder
                                        isSacSearch2Enabled={
                                            isSacSearch2Enabled
                                        }
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="filters-container">
                                <FilterSelector
                                    filterTitle="Deployment"
                                    filterValues={deployments}
                                    setQueryValues={(value: Deployment) =>
                                        dispatch(
                                            setDeploymentFilterQueryValue(value)
                                        )
                                    }
                                    allowFilterAll={false}
                                    queryByString={true}
                                    defaultSelected={
                                        deployments?.filter(
                                            (deployment) =>
                                                deployment.key ===
                                                deploymentQueryValue
                                        )[0]?.name
                                    }
                                />
                                <FilterSelector
                                    filterTitle="Product"
                                    filterValues={productsFilterValues}
                                    setQueryValues={(value: string[]) =>
                                        dispatch(
                                            setProductsFilterQueryValue(value)
                                        )
                                    }
                                    titleReset={productsTitleReset}
                                    setTitleReset={() =>
                                        dispatch(setProductTitleReset())
                                    }
                                    shouldPulse={shouldPulseProductFilter}
                                    setShouldPulse={() =>
                                        dispatch(setShouldPulseProductFilter())
                                    }
                                    defaultSelected={
                                        entry?.filters?.products.filter(
                                            (product) =>
                                                product.key ===
                                                    productsQueryValue[0] &&
                                                product.platform ===
                                                    deploymentQueryValue
                                        )?.[0]?.name
                                    }
                                />
                                {renderVersionFilter()}
                                <FilterSelector
                                    filterTitle="Resource Type"
                                    filterValues={contentArray}
                                    setQueryValues={(value: Content[]) =>
                                        dispatch(
                                            setContentFilterQueryValue(value)
                                        )
                                    }
                                    allowFilterAll={true}
                                />
                                <ResetButton
                                    appearance="subtle"
                                    onReset={() => {
                                        dispatch(resetAllValues());
                                        return false; // Don't send analytics event for old "Clear filters" button
                                    }}
                                />
                            </div>
                        )}

                        {isSacSearch2Enabled ? (
                            <div className="search-results-grid">
                                <div className="search-filters">
                                    <ResetButton
                                        appearance="default"
                                        analyticsMetadata={{
                                            searchTerm,
                                            clearedDeployment:
                                                deploymentQueryValueV2,
                                            clearedProducts: [
                                                productFilterValue.key
                                            ],
                                            clearedVersions: versionsQueryValue
                                        }}
                                        onReset={() => {
                                            const shouldReset =
                                                !!productFilterValue.key;
                                            if (shouldReset) {
                                                dispatch(resetAllValuesV2());
                                            }
                                            return shouldReset;
                                        }}
                                    />
                                    <Divider />
                                    {renderFilterTags()}
                                    <Divider />
                                    {renderProductFilter()}
                                    {renderNewVersionFilter()}
                                </div>
                                <SearchResultsDisplay
                                    isSacSearch2Enabled={isSacSearch2Enabled}
                                >
                                    {isLoading ? (
                                        <div className="search-results-spinner-container">
                                            <Spinner size={'xlarge'} />
                                        </div>
                                    ) : searchResponse?.queryId &&
                                      searchResponse.results?.length ? (
                                        renderSearchResults()
                                    ) : (
                                        <NoSearchResults
                                            searchTerm={searchTerm}
                                        />
                                    )}
                                </SearchResultsDisplay>
                            </div>
                        ) : (
                            <SearchResultsDisplay
                                isSacSearch2Enabled={isSacSearch2Enabled}
                            >
                                {isLoading ? (
                                    <div className="search-results-spinner-container">
                                        <Spinner size={'xlarge'} />
                                    </div>
                                ) : searchResponse?.queryId &&
                                  searchResponse.results?.length ? (
                                    renderSearchResults()
                                ) : (
                                    <NoSearchResults searchTerm={searchTerm} />
                                )}
                            </SearchResultsDisplay>
                        )}

                        {isSacSearch2Enabled &&
                            showPagination &&
                            renderPagination()}
                    </div>
                </main>
            </div>
            <Footer
                logo={entry.header.atlassianLogo.url}
                className={'margin-top-large'}
            />
        </div>
    );
};
