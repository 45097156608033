import React from 'react';

interface BreadcrumbProps {
    breadcrumbs?: string[];
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({
    breadcrumbs
}: BreadcrumbProps) => {
    return breadcrumbs?.length ? (
        <div className="breadcrumb font-weight-200 margin-bottom-md ">
            {breadcrumbs?.map((crumb) => (
                <span className=" breadcrumb-element " key={crumb}>
                    {crumb}
                </span>
            ))}
        </div>
    ) : (
        <></>
    );
};
