/* eslint-disable react/prop-types */
import React from 'react';
import { AkCodeBlock } from '@atlaskit/code';
import {
    getCodeLanguage,
    type GqlComponentCodeBlock
} from '../../../../content/types/content/component-code-block';

interface ComponentCodeBlockProps {
    entry: GqlComponentCodeBlock;
}

const ComponentCodeBlock: React.FC<ComponentCodeBlockProps> = ({ entry }) => {
    return (
        <div className="kb-article-code-block">
            <AkCodeBlock
                text={entry.code}
                language={getCodeLanguage(entry.language)}
            />
        </div>
    );
};

export default ComponentCodeBlock;
