import { string, arrayOf, shape, oneOf } from 'prop-types';
import { TopicSetType } from '../topic-set/topic-set.type';
import { VersionsType } from '../../components/deployment-selector/versions.type';
import { withPageType } from '../../utils/with-page-type';
import { ProductType } from '../../components/product/product.type';
import { TopicType } from '../topic/topic.type';

export const CollectionType = {
    title: string,
    id: string.isRequired,
    lastPublished: string,
    subhead: string,
    description: string,
    buttonText: string,
    childrenReferences: arrayOf(shape(TopicSetType || TopicType)),
    url: string,
    metaDescription: string,
    pageType: string,
    deployment: string,
    context: ProductType,
    versions: arrayOf(VersionsType)
};

export const GuideType = shape({
    title: string,
    id: string.isRequired,
    lastPublished: string,
    subhead: string,
    description: string,
    buttonText: string,
    childrenReferences: arrayOf(shape(TopicSetType || TopicType)),
    url: string,
    metaDescription: string,
    pageType: oneOf(['Guide']),
    deployment: string,
    context: ProductType,
    versions: arrayOf(VersionsType)
});

export const CollectionPageType = withPageType(CollectionType);
