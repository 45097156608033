import React from 'react';
import AtlassianIcon from '@atlaskit/icon/glyph/emoji/atlassian';
import './version-listing.less';
import type {
    IAppVersionFields,
    IProductVersionFields
} from '@contentful/types';
import PlatformIcon from '@atlaskit/icon/glyph/download';

type Icon = 'platform' | 'atlassian';

type AppVersionModifier = IAppVersionFields['versionModifier'];
type ProductVersionModifier = IProductVersionFields['versionModifier'];

type AppVersionNumber = IAppVersionFields['versionNumber'];
type ProductVersionNumber = IProductVersionFields['versionNumber'];

export interface Version {
    id: string;
    name: string;
    versionModifier?: AppVersionModifier | ProductVersionModifier;
    versionNumber?: AppVersionNumber | ProductVersionNumber;
}

const renderIcon = (icon?: Icon) => {
    switch (icon) {
        case 'platform':
            return <PlatformIcon label="platform" size="medium" />;
        case 'atlassian':
        default:
            return <AtlassianIcon label="atlassian" size="medium" />;
    }
};

const renderVersion = (version: Version, icon?: Icon) => {
    const hasModifier =
        version.versionModifier && version.versionModifier !== 'None';
    const versionModifier = !hasModifier ? '' : ' ' + version.versionModifier;
    return (
        <div key={version.id} className="version-listing__wrapper">
            <div className="version-listing__background">
                {renderIcon(icon)}
                <span className="version-listing__text">
                    {version.name}
                    {version.versionNumber && (
                        <>
                            <>&nbsp;</>
                            <>{`(${version.versionNumber}${versionModifier})`}</>
                        </>
                    )}
                </span>
            </div>
        </div>
    );
};

const VersionListing = ({
    title,
    versions,
    icon
}: {
    title: string;
    versions: Version[];
    icon?: Icon;
}) => {
    if (versions.length === 0) {
        return <span data-testid={`${title}-no-versions`}></span>;
    }

    return (
        <>
            <div className="version-listing__title">{title}</div>
            {versions.map((version) => renderVersion(version, icon))}
        </>
    );
};

export default VersionListing;
