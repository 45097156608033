import { string, shape, arrayOf } from 'prop-types';

export const PageTreeType = arrayOf(
    shape({
        title: string,
        url: string,
        id: string,
        childList: arrayOf(
            shape({
                title: string,
                url: string,
                id: string,
                childList: arrayOf(
                    shape({
                        title: string,
                        url: string,
                        id: string
                    })
                )
            })
        )
    })
);
