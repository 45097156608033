import React from 'react';
import { KbArticlePlatform } from '../../../../../content/types/content/kb-article';
import '../version-listing/version-listing.less';
import VersionListing, {
    type Version
} from '../version-listing/version-listing';

function platformToVersion(platform: KbArticlePlatform): Version {
    return {
        id: platform,
        name: platform
    };
}

export interface PlatformSectionProps {
    readonly platform: KbArticlePlatform;
}

function PlatformSection({ platform }: PlatformSectionProps): JSX.Element {
    return (
        <VersionListing
            title="Platform"
            icon="platform"
            versions={[platformToVersion(platform)]}
        />
    );
}

export default PlatformSection;
