import Context, {
    AppContext,
    isAppContext,
    isProductContext,
    isThemeContext,
    UrlParentEntry,
    ContentfulContext
} from '../types/content/context';

import { translatableText } from '../../renderer/utils/translatable-text';

export const getContextName = (context: ContentfulContext) => {
    if (isProductContext(context)) {
        return context.productNameReference.productName;
    }
    if (isAppContext(context)) {
        return context.appNameReference.appName;
    }

    return context.hubName;
};

export const getRelevantProductNames = (context: ContentfulContext) => {
    if (isProductContext(context)) {
        return [context.productNameReference.productName];
    }
    if (isAppContext(context)) {
        return (context as AppContext).parentProductContext.items.map(
            ({ internalTitle }) => internalTitle
        );
    }

    return [];
};

export const getSupportTitle = (urlParentEntry: UrlParentEntry) => {
    if (isProductContext(urlParentEntry)) {
        return urlParentEntry.supportTitle;
    }
    if (isAppContext(urlParentEntry)) {
        return urlParentEntry.appNameReference.appName + ' Support';
    }

    return 'Atlassian Support';
};

export const getDeployment = (context: ContentfulContext) => {
    if (isProductContext(context)) {
        return context.deployment;
    }
    if (isAppContext(context)) {
        return context.deployment;
    }

    return null;
};

export const getBreadcrumbTitle = (context: ContentfulContext) => {
    if (isThemeContext(context)) {
        return `${context.hubName} ${translatableText.resources.text}`;
    }
    return translatableText.resources.text;
};

export const getContextDeploymentForSelectedButton = (context: Context) => {
    if (context.deployment === 'Cloud') {
        return context.deployment;
    } else {
        return 'Data Center';
    }
};

export const getProductNameId = (context: ContentfulContext) => {
    if (isProductContext(context)) {
        return context.productNameReference.productNameId;
    }

    return '';
};

export const getProductVersion = (context: ContentfulContext) => {
    if (isProductContext(context) && context.version !== 'Not Applicable') {
        return context.version;
    }

    return '';
};
