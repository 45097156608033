import React from 'react';
import { GqlAppVersion } from '../../../../../content/types/content/app-version';
import VersionListing from '../version-listing';
import { Version } from '../version-listing/version-listing';

const appVersionToVersion = (appVersion: GqlAppVersion): Version => {
    return {
        ...appVersion,
        id: appVersion.sys.id,
        name: appVersion.app.appName
    };
};

const AppVersions = ({ appVersions }: { appVersions: GqlAppVersion[] }) => {
    return (
        <VersionListing
            title={'App' + (appVersions.length > 1 ? 's' : '')}
            versions={appVersions.map(appVersionToVersion)}
        />
    );
};

export default AppVersions;
