import React from 'react';
import { translatableText } from '../../utils/translatable-text';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up';
import './show-more.less';

interface ShowMoreProps {
    onClick: (event: React.MouseEvent) => void;
    label?: string;
    testId?: string;
    type?: 'down' | 'up';
    className?: string;
}

export const ShowMore = ({
    onClick,
    className = '',
    label = 'Show more icon',
    testId = 'show-more-btn',
    type = 'down'
}: ShowMoreProps) => (
    <li className={className}>
        <button
            className={`chevron-${type}`}
            data-testid={testId}
            onClick={onClick}
        >
            <span className="chevron-down__text">
                {translatableText.showMore.text}
            </span>
            {type === 'down' ? (
                <ChevronDownIcon size="medium" label={label} />
            ) : (
                <ChevronUpIcon size="medium" label={label} />
            )}
        </button>
    </li>
);
