import { Target, Meta } from './types';

declare global {
    interface Window {
        analytics: any;
    }
}

const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
};

// Generates a UUID for analytics
export const generateUUID = () => {
    return (
        s4() +
        s4() +
        '-' +
        s4() +
        '-' +
        s4() +
        '-' +
        s4() +
        '-' +
        s4() +
        s4() +
        s4()
    );
};

// Helper to determine of target is a string
export const isTargetAString = (target: Target) => {
    return typeof target === 'string' || target instanceof String;
};

// Takes a target and returns an event name
export const formatEventName = (target: Target, action: string): string => {
    if (isTargetAString(target) && isTargetAString(action)) {
        return (target as string).trim() + ' ' + action.trim();
    }

    let rawText;
    if ((target as HTMLFormElement).nodeName === 'FORM') {
        rawText = (target as HTMLFormElement).getAttribute('name');
    } else {
        rawText = (target as HTMLElement).textContent;

        if (rawText === '') {
            const imgLink = (target as HTMLElement).querySelectorAll('img');
            if (imgLink.length > 0) {
                rawText = imgLink[0].getAttribute('alt');
            }
        }
    }

    return rawText + ' Clicked';
};

// Uses meta data to format event props
export const formatEventProperties = (target: Target, metaData: Meta): any => {
    const transformedMetaData: any =
        metaData !== null && metaData !== 'undefined' ? metaData : {};

    // try to get target link if the element is an anchor tag
    if (typeof (target as any).getAttribute === 'function') {
        const targetLink: string | null = (target as HTMLElement).getAttribute(
            'href'
        );

        if (targetLink) {
            transformedMetaData.target_link = targetLink;
        }
    }

    return Object.assign({}, transformedMetaData);
};
