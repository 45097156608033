import Joi from '@hapi/joi';
import { schema as ReleaseNotesSchema } from '../release-note-detailed/release-note-detailed.schema';

export const generatePaginatedSchema = (type: string) => {
    return Joi.object().keys({
        [type]: Joi.array().items(ReleaseNotesSchema),
        pageInfo: Joi.object().keys({
            endCursor: Joi.string(),
            startCursor: Joi.string(),
            hasNextPage: Joi.boolean(),
            hasPreviousPage: Joi.boolean()
        })
    });
};
