import axios from 'axios';
import axiosRetry, { isRetryableError } from 'axios-retry';
import Logger from '../../logger';
import { AppError } from '../../common/app-error';

interface Arguments {
    preview?: boolean;
    name?: string;
    id?: string;
    url?: string;
    first?: number;
}

const CPAPIClient = axios.create({
    timeout: 20000,
    baseURL: 'https://jdog.jira-dev.com'
});

//TO-DO : make it work for client side calls
// addTracingInterceptors(CPAPIClient);

axiosRetry(CPAPIClient, {
    retries: 3,
    retryCondition: (error) => {
        return isRetryableError(error);
    },
    shouldResetTimeout: true,
    retryDelay: (retryCount) => {
        Logger.warn(
            'Failure to execute CPAPI GraphQL request. Retrying request.'
        );

        const tenSeconds = 10 * 1000;

        // Wait 10, 20, then 30 seconds
        return retryCount * tenSeconds;
    }
});

export async function executeClientCPAPIQuery(
    query?: string,
    args?: Arguments
) {
    try {
        const data = await CPAPIClient.post(
            `/gateway/api/graphql`,
            {
                query,
                variables: args
            },
            {
                headers: {
                    'X-Slauth-Egress': 'true',
                    'Content-Type': 'application/json'
                }
            }
        );

        return data;
    } catch (error) {
        throw new AppError({
            message: 'Error executing CPAPI query',
            previousError: error as Error
        });
    }
}
