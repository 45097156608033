import React, { Component } from 'react';
import { arrayOf, oneOfType, element, bool, string } from 'prop-types';
import Script from 'react-load-script';
import './issue-collector.less';
import Logger from '../../../logger';

export default class IssueCollector extends Component {
    constructor() {
        super();
        this.state = {
            jqueryLoaded: false,
            issueCollectorPropsAdded: false,
            issueCollectorLoaded: false
        };

        this.handleJqueryLoad = this.handleJqueryLoad.bind(this);
        this.handleIssueCollectorLoad =
            this.handleIssueCollectorLoad.bind(this);
        this.addIssueCollectorProps = this.addIssueCollectorProps.bind(this);
        this.logError = this.logError.bind(this);
    }

    handleJqueryLoad() {
        this.setState({ jqueryLoaded: true }, this.addIssueCollectorProps);
    }

    handleIssueCollectorLoad() {
        this.setState({ issueCollectorLoaded: true });
    }

    addIssueCollectorProps() {
        const $ = window.jQuery;
        const $reportAProblemButton = $(this.reportAProblemButton);
        const { productName, pageId } = this.props;

        window.ATL_JQ_PAGE_PROPS = $.extend(window.ATL_JQ_PAGE_PROPS, {
            triggerFunction: function (show_collector_dialog) {
                $reportAProblemButton.click(function (e) {
                    e.preventDefault();
                    show_collector_dialog();
                });
            },
            // TODO: replace fieldValues with real values once available.
            fieldValues: {
                customfield_18230: window.location.href, // Page URL
                customfield_18231: productName,
                customfield_18232: pageId
            }
        });

        this.setState({ issueCollectorPropsAdded: true });
    }

    logError(error) {
        Logger.error({ error }, 'error loading external script');
    }

    render() {
        return (
            <React.Fragment>
                <button
                    className={[
                        this.props.className,
                        'issue-collector__button'
                    ].join(' ')}
                    aria-label="open issue collector"
                    ref={(el) => (this.reportAProblemButton = el)}
                    data-plugin-loaded={this.state.issueCollectorLoaded}
                >
                    {this.props.children}
                </button>
                {/* dynamically load jQuery dependency */}
                <Script
                    url="https://ajax.googleapis.com/ajax/libs/jquery/1.11.2/jquery.min.js"
                    attributes={{ async: true }}
                    onLoad={this.handleJqueryLoad}
                    onError={this.logError}
                />
                {/* wait for jQuery to load, then initialize issue collector plugin */}
                {this.state.issueCollectorPropsAdded && (
                    <Script
                        url="https://jira.atlassian.com/plugins/servlet/issueCollectorBootstrap.js?locale=en-UK&collectorId=2253b6d2"
                        attributes={{ async: true }}
                        onLoad={this.handleIssueCollectorLoad}
                        onError={this.logError}
                    />
                )}
            </React.Fragment>
        );
    }
}

IssueCollector.propTypes = {
    children: oneOfType([
        arrayOf(oneOfType([element, bool, string])),
        element,
        string
    ]),
    className: string,
    productName: string,
    pageId: string
};
