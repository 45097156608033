import React from 'react';
import { Header, HeaderType } from '../../components/header';
import Footer from '../../components/footer';
import { arrayOf, bool, shape, string } from 'prop-types';
import { PageTreeType } from '../../utils/page-tree-type';
import { ResourceType } from '../../components/card';
import './contact.less';
import Breadcrumbs from '../../components/breadcrumbs';
import RemoteReactApp from '../../components/remote-react-app';

const ContactPage = (props) => {
    const {
        entry: { resourceCards, header, id, preview, context },
        pageTree
    } = props;

    return (
        <div className="contact flex-column-page" data-testid="page-template">
            <div className="content-wrapper">
                <Header
                    resources={resourceCards}
                    logo={header.atlassianSupportLogo}
                    atlassianSupportLogo={header.atlassianSupportLogo}
                    cloudProducts={header.cloudProducts}
                    serverProducts={header.serverProducts}
                    id={header.id}
                    showPreviewBanner={preview}
                    additionalLinks={header.additionalLinks}
                    pageId={id}
                />
                <main className="main-content-container margin-top-large">
                    <div className="contact__sub-header">
                        <Breadcrumbs
                            pageTree={pageTree}
                            entryId={id}
                            context={context}
                        />
                    </div>
                    <div className="contact__forms" data-testid="contact-forms">
                        {renderSACForms()}
                    </div>
                </main>
            </div>
            <Footer
                logo={header.atlassianLogo.url}
                className={'margin-top-large'}
            />
        </div>
    );
};

ContactPage.propTypes = {
    entry: shape({
        resourceCards: shape({
            cards: arrayOf(ResourceType)
        }),
        header: HeaderType,
        id: string.isRequired,
        preview: bool
    }),
    pageTree: PageTreeType
};

const renderSACForms = () => {
    const manifestUrl = () =>
        `${window.CONTACT_FORMS_MANIFEST}?v=${new Date().getTime()}`;

    return (
        <RemoteReactApp
            appTargetId="support-form-app"
            manifestUrlProvider={manifestUrl}
            filesFilter={['index.js', 'index.css']}
            includeLoadingSpinner={true}
            executeAfterMount={() => {
                window.SUPPORT_FORM_APP = {
                    IS_PARTNER_SUPPORT_TYPE: false
                };
            }}
        />
    );
};

export default ContactPage;
