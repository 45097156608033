import React from 'react';
import PropTypes from 'prop-types';

const SectionHeading = ({ title }) => {
    return <h3 className="menu-header">{title}</h3>;
};

SectionHeading.propTypes = {
    title: PropTypes.string.isRequired
};

export default SectionHeading;
