import getLanguageFromMetaTag from '../../common/localization-utils';

const languagePathMap: { [key: string]: string } = {
    'ja-JP': '/ja'
};

export const getLocalizedUrl = (baseUrl: string, path: string) => {
    const language = getLanguageFromMetaTag();
    const localizedPathPrefix = (language && languagePathMap[language]) || '';

    return `${baseUrl}${localizedPathPrefix}${path}`;
};
