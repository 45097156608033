import React from 'react';
import { AtlasLink } from '../atlas-link';
import './product-card.less';
import analytics from '../../utils/analytics';

interface ProductCardProps {
    title: string;
    icon: string;
    url: string;
    id?: string;
    analyticsMetadata: {
        parentComponent: string;
        [key: string]: any;
    };
    featureFlags: { isRedesignEnabled?: boolean };
}

export const ProductCard = ({
    title,
    icon,
    url,
    id,
    analyticsMetadata,
    featureFlags: { isRedesignEnabled }
}: ProductCardProps) => {
    const v2ComponentId = isRedesignEnabled ? '-v2' : '';

    return (
        <li>
            <AtlasLink
                href={url}
                className={`products__link product-card${v2ComponentId}`}
                data-testid={`product-card${v2ComponentId}-${id}`}
                onClick={() => {
                    analytics.trackEvent('product card', {
                        // @ts-ignore
                        event: 'clicked',
                        eventComponent: 'product-card',
                        actionSubjectId: 'link',
                        category: 'product',
                        action: 'clicked',
                        label: 'product card',
                        product: title,
                        productUrl: url,
                        isRedesignEnabled,
                        ...analyticsMetadata
                    });
                }}
            >
                <img
                    src={icon}
                    alt=""
                    height={isRedesignEnabled ? '80' : '95'}
                    width={isRedesignEnabled ? '80' : '95'}
                    className="icon-image"
                    data-testid={`product-img${v2ComponentId}-${id}`}
                />
                <p className="product-title-text">{title}</p>
            </AtlasLink>
        </li>
    );
};
