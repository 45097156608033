/* eslint-disable react/prop-types */
import React from 'react';
import SectionMessage from '@atlaskit/section-message';
import EditorInfoIcon from '@atlaskit/icon/glyph/editor/info';
import EditorNoteIcon from '@atlaskit/icon/glyph/editor/note';
import EditorSuccessIcon from '@atlaskit/icon/glyph/editor/success';
import EditorWarningIcon from '@atlaskit/icon/glyph/editor/warning';
import { INLINES, type Block, type Inline } from '@contentful/rich-text-types';
import type {
    GqlComponentCallout,
    GqlComponentCalloutRichTextData,
    PanelMapping
} from '../../../../content/types/content/component-callout';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { retrieveAndRenderHyperlinkedEntry } from '../utils/retrieve-and-render-entry';

const PANEL_MAPPING: PanelMapping = {
    'Discovery/New (Purple)': { appearance: 'change', icon: EditorNoteIcon },
    'Information (Blue)': { appearance: 'info', icon: EditorInfoIcon },
    'Success/Tip (Green)': {
        appearance: 'confirmation',
        icon: EditorSuccessIcon
    },
    'Warning/Caution (Yellow)': {
        appearance: 'warning',
        icon: EditorWarningIcon
    }
} as const;

const renderComponentCalloutRtf = (
    richTextData: GqlComponentCalloutRichTextData
): React.ReactNode => {
    const {
        json,
        links: {
            entries: { hyperlink = [] }
        }
    } = richTextData;

    const hyperlinkEntryMap = new Map(
        hyperlink.map((entry) => [entry.sys.id, entry])
    );

    const options = {
        renderNode: {
            [INLINES.ENTRY_HYPERLINK]: (
                node: Block | Inline,
                children: React.ReactNode
            ) =>
                retrieveAndRenderHyperlinkedEntry(
                    hyperlinkEntryMap,
                    node,
                    children
                )
        }
    };

    return documentToReactComponents(json, options);
};

interface ComponentCalloutProps {
    entry: GqlComponentCallout;
}

const ComponentCallout: React.FC<ComponentCalloutProps> = ({
    entry: { heading, appearance, body }
}) => {
    const renderedBody = renderComponentCalloutRtf(body);

    const { appearance: appearanceVal, icon } = PANEL_MAPPING[appearance];

    return (
        <div className="kb-article-callout">
            <SectionMessage icon={icon} appearance={appearanceVal}>
                {heading && (
                    <p className="kb-article-callout__heading">
                        <strong>{heading}</strong>
                    </p>
                )}
                <div className={heading ? '' : 'kb-article-callout__body'}>
                    {renderedBody}
                </div>
            </SectionMessage>
        </div>
    );
};

export default ComponentCallout;
