import React, {
    KeyboardEventHandler,
    MouseEventHandler,
    ReactElement,
    useEffect,
    useLayoutEffect,
    useRef
} from 'react';

import './subnav-with-sidebar-tabs.less';

export interface TabData {
    id: string;
    title: string;
    content: ReactElement;
}

const useSafeLayoutEffect =
    typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export interface TabProps {
    isActive: boolean;
    isFocused: boolean;
    tab: TabData;
    onClick: MouseEventHandler<HTMLDivElement>;
    onKeyDown: KeyboardEventHandler<HTMLDivElement>;
}

export const Tab = ({
    tab,
    onClick,
    onKeyDown,
    isActive,
    isFocused
}: TabProps) => {
    const { id, title } = tab;
    const tabRef = useRef<HTMLDivElement>(null);

    useSafeLayoutEffect(() => {
        if (isFocused && tabRef.current) {
            tabRef.current.focus();
        }
    }, [isFocused]);

    return (
        <div
            id={`subnav-with-sidebar-tabs-tab-${id}`}
            ref={tabRef}
            role="tab"
            onClick={onClick}
            onKeyDown={onKeyDown}
            aria-selected={isActive}
            aria-controls={`subnav-with-sidebar-tabs:panel:${id}`}
            tabIndex={isActive ? 0 : -1}
            style={{
                backgroundColor: isActive ? '#fff' : 'inherit',
                color: isActive ? '#1868DB' : '#101214'
            }}
            className="subnav-with-sidebar-tabs__tab"
        >
            <span>{title}</span>
        </div>
    );
};
