import { parse } from 'himalaya';
import { recursivelySearchDom } from './recursively-search-dom';
import { sanitizeCharacterEncodings } from './sanitize-character-encodings';

export const getHeadersText = (bodyHTML) => {
    if (!bodyHTML) {
        return [];
    }

    // deconstruct the rendered body to pull out the headings
    const parsed = parse(bodyHTML);
    const headings = recursivelySearchDom(parsed, (node) =>
        ['h2', 'h3'].includes(node.tagName)
    ).filter((heading) => heading.children.length);

    return headings
        .map((heading) => {
            const textNodes = recursivelySearchDom(
                [heading],
                (node) => node.type === 'text'
            );

            const title = textNodes.reduce(
                (value, node) => value + node.content,
                ''
            );

            const id = heading?.attributes?.[0]?.value;

            return { title, id };
        })
        .map((headingObject) => {
            return {
                ...headingObject,
                title: sanitizeCharacterEncodings(headingObject.title)
            };
        });
};
