import React, { FunctionComponent } from 'react';
import RemoteReactApp from '../remote-react-app';

/**
 * Stub container used to load the Bannerman code into the page. This code will decide where to place the banner and therefore
 * no special logic needs to be placed here.
 */

function manifestUrlProvider() {
    return window.BANNERMAN_MANIFEST;
}

const Bannerman: FunctionComponent<{}> = () => {
    return (
        <div>
            <script
                data-app="bannerman"
                data-site-key="support-atlassian-com"
            />
            <RemoteReactApp
                appTargetId="bannerman"
                manifestUrlProvider={manifestUrlProvider}
                filesFilter={['index.js', 'index.css']}
            />
        </div>
    );
};

export default Bannerman;
