import React from 'react';
import TableOfContents from './table-of-contents';
import RightNav from './right-nav';
import Community from './community';
import './sidebar.less';
import KbArticleSidebar from '../kb-article-sidebar';

const Sidebar = React.forwardRef(({ children }, ref) => {
    return (
        // todo: sidebar should use HTML5 semantic element.
        // https://www.w3schools.com/html/html5_semantic_elements.asp
        <div className="sidebar" ref={ref} data-testid="sidebar">
            {children}
        </div>
    );
});

Sidebar.displayName = 'Sidebar';

Sidebar.propTypes = {
    // verify that children are only of intended Component types
    children: function (props, propName, componentName) {
        const prop = props[propName];
        let error = null;

        React.Children.forEach(prop, function (child) {
            // Add any new Sidebar children component types here
            if (
                child && // protects against null children
                child.type !== TableOfContents &&
                child.type !== Community &&
                child.type !== RightNav &&
                child.type !== KbArticleSidebar
            ) {
                error = new Error(
                    `<${componentName}> child <${child.type.name}> is an unauthorized type.`
                );
            }
        });

        return error;
    }
};

export default Sidebar;
