import React from 'react';
import { AtlasLink } from '../atlas-link';

interface HeaderListItemProps {
    href: string;
    label: string;
}

const HeaderListItem = ({ href, label }: HeaderListItemProps) => (
    <li>
        <AtlasLink
            className="header__nav-item"
            href={href}
            text={label}
            dataName={`${label} header link`}
        />
    </li>
);

export default HeaderListItem;
