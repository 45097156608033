import React from 'react';
import TopicPage from './topic';

export default function render(entry, pageTree, featureFlags) {
    return (
        <TopicPage
            entry={entry}
            pageTree={pageTree}
            featureFlags={featureFlags}
        />
    );
}
