/* eslint-disable react/prop-types */
import React from 'react';
import AtlasImage from '../../atlas-image';
import type { GqlComponentKbImage } from '../../../../content/types/content/component-kb-image';

interface ComponentKbImageProps {
    entry: GqlComponentKbImage;
}

const ComponentKbImage: React.FC<ComponentKbImageProps> = ({ entry }) => {
    const {
        altText,
        image: { url }
    } = entry;

    return <AtlasImage imgSrc={url} altTag={altText} />;
};

export default ComponentKbImage;
