import React, { Component } from 'react';
import Button, { ButtonGroup } from '@atlaskit/button';
import { DeploymentType } from './deployments.type';
import { VersionsType } from './versions.type';
import {
    DropdownMenuStateless,
    DropdownItemGroup,
    DropdownItem
} from '@atlaskit/dropdown-menu';
import PropTypes from 'prop-types';
import VersionSelectorModal from '../version-selector-modal';
import './deployment-selector.less';
import { ModalTransition } from '@atlaskit/modal-dialog';
import { translatableText } from '../../utils/translatable-text';

export default class DeploymentSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropDownOpen: false,
            versionSelectorModal: {
                isOpen: false,
                version: null,
                spaceId: ''
            }
        };
    }

    toggleVersionSelectorModal = (options) => {
        this.setState({
            versionSelectorModal: options
                ? options
                : {
                      isOpen: false,
                      version: null,
                      spaceId: ''
                  }
        });
    };

    render() {
        const { versions = [], deployments, selected } = this.props;
        const { dropDownOpen, versionSelectorModal } = this.state;
        const deploymentButtons = [];

        if (deployments.cloud && (deployments.server || versions.length)) {
            deploymentButtons.push(
                <Button
                    className="cloud-button"
                    key="cloud-button"
                    href={deployments.cloud}
                    isSelected={selected === 'Cloud'}
                >
                    {translatableText.cloud.text}
                </Button>
            );
            deploymentButtons.push(
                <React.Fragment key="server-dropdown">
                    {versions.length ? (
                        <DropdownMenuStateless
                            isOpen={dropDownOpen}
                            isSelected={selected === 'Data Center'}
                            position="bottom left"
                            appearance="tall"
                            onOpenChange={(attrs) => {
                                this.setState({
                                    dropDownOpen: attrs.isOpen
                                });
                            }}
                            trigger="Data Center"
                            triggerType="button"
                            shouldFitContainer={true}
                            shouldFlip={false}
                            triggerButtonProps={{
                                isSelected: selected === 'Data Center'
                            }}
                        >
                            <DropdownItemGroup
                                title={translatableText.versions.text}
                            >
                                <React.Fragment>
                                    {versions.map((version) => (
                                        <DropdownItem
                                            key={version.number}
                                            onClick={() =>
                                                this.toggleVersionSelectorModal(
                                                    {
                                                        isOpen: true,
                                                        version: version.number,
                                                        spaceId: version.spaceId
                                                    }
                                                )
                                            }
                                        >
                                            {version.number}
                                        </DropdownItem>
                                    ))}
                                </React.Fragment>
                                <DropdownItem
                                    target={'_self'}
                                    href={
                                        'https://confluence.atlassian.com/alldoc/applications-and-tools-documentation-directory-209586313.html'
                                    }
                                >
                                    {translatableText.seeAll.text}
                                </DropdownItem>
                            </DropdownItemGroup>
                        </DropdownMenuStateless>
                    ) : (
                        <Button
                            className="server-button"
                            key="server-button"
                            href={deployments.server}
                            isSelected={selected === 'Data Center'}
                        >
                            {translatableText.server.text}
                        </Button>
                    )}
                </React.Fragment>
            );
        }

        return (
            <div data-testid="deploy-select" className="deploy-select">
                <ButtonGroup>{deploymentButtons}</ButtonGroup>
                <ModalTransition>
                    {versionSelectorModal.isOpen && (
                        <VersionSelectorModal
                            version={versionSelectorModal.version}
                            spaceId={versionSelectorModal.spaceId}
                            close={this.toggleVersionSelectorModal}
                        />
                    )}
                </ModalTransition>
            </div>
        );
    }
}

DeploymentSelector.propTypes = {
    versions: PropTypes.arrayOf(VersionsType),
    deployments: DeploymentType,
    selected: PropTypes.oneOf(['Cloud', 'Server', 'Data Center'])
};
