import React, { useState } from 'react';
//@ts-ignore
import Tooltip from '@atlaskit/tooltip';
import { AtlasLink } from '../../components/atlas-link';
import { translatableText } from '../../utils/translatable-text';
import { ShowMore } from '../../components/show-more';
import './documentation-collection.less';
import analytics from '../../utils/analytics/analytics';
import camelCase from 'lodash/camelCase';

interface ListProps {
    description: string;
    children: React.ReactNode;
    title: string;
}

interface ChildEntry {
    title: string;
    description: string;
    url: string;
    id: string;
}

const DEFAULT_NUM_OF_ENTRIES = 5;

export const ChildListItem = ({
    entry: { title, description, url },
    isHidden
}: {
    entry: ChildEntry;
    isHidden: boolean;
}) => (
    <li className={[isHidden ? 'hidden' : ''].join(' ')}>
        <Tooltip content={description}>
            <AtlasLink
                dataName={camelCase('child list item ' + title)}
                text={title}
                href={url}
            />
        </Tooltip>
    </li>
);

export const ChildList = ({ entries = [] }: { entries: ChildEntry[] }) => {
    const [showAll, setShowAll] = useState(entries.length <= 5);
    const handleShowMoreClick = (val: boolean) => {
        analytics.trackEvent('showMore', {
            // @ts-ignore
            event: 'clicked',
            eventComponent: 'link',
            actionSubjectId: 'showMore',
            category: 'showMore',
            action: 'click',
            label: 'Show more'
        });
        setShowAll(val);
    };

    return (
        <ul className="documentation-collection__child-list">
            {entries.map((entry, index) => (
                <ChildListItem
                    entry={entry}
                    key={entry.id}
                    isHidden={!showAll && index >= DEFAULT_NUM_OF_ENTRIES}
                />
            ))}
            {!showAll && (
                <ShowMore
                    onClick={() => handleShowMoreClick(true)}
                    className="documentation-collection__show-more"
                />
            )}
        </ul>
    );
};

export const List = ({ title, description, children }: ListProps) => (
    <div className="documentation-collection__list">
        <h3 className="ternary-heading">{title}</h3>
        <p className="documentation-collection__teaser">{description}</p>
        {children}
    </div>
);

export const Group = ({ children }: { children: React.ReactNode }) => (
    <div className="documentation-collection">
        <h2 className="secondary-heading">
            {translatableText.documentation.text}
        </h2>
        {children}
    </div>
);
