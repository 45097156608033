import React from 'react';
import { Header, HeaderListItem } from '../../components/header';
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs';
import Footer from '../../components/footer';
import SkipLink from '../../components/skip-link/skip-link';
import AuthorTools from '../../components/author-tools';
import KbArticleSidebar from '../../components/kb-article-sidebar';
import Sidebar from '../../components/sidebar';
import FeedbackButtons from '../../components/feedback-buttons';
import { translatableText } from '../../utils/translatable-text';
import PlatformNotice from '../../components/platform-notice';
import { getSupportTitle } from '../../../content/context-utils';
import { PAGE_KB_ARTICLE } from '../../../contentful/content-types';
import type {
    KbArticlePage,
    KbPageReferences
} from '../../../content/types/content/kb-article';
import './kb-article.less';
import AdditionalHelp from '../../components/additional-help';
import KbArticleRtfContent from '../../components/kb-article-rtf-content';

export type KbArticlePageEntry = KbArticlePage & {
    header: any; // TODO: See https://hello.jira.atlassian.cloud/browse/CSTE-2182 for more on header and resources typing
    preview: boolean;
    resources: any;
    metadata: any;
};

interface KbArticlePageProps {
    entry: KbArticlePageEntry;
}

const getReferencesFromArticle = (page: KbArticlePage): KbPageReferences => ({
    kbHomePageReference: {
        url: page.homePageReference.url,
        title: page.homePageReference.title
    },
    kbArticlePageReference: {
        url: page.url,
        title: page.title
    }
});

const convertTimestamp = (timestamp: string) => {
    const date = new Date(timestamp);

    if (Number.isNaN(date.getTime())) {
        return;
    }

    return `${date.toLocaleString('default', {
        month: 'long',
        timeZone: 'UTC'
    })} ${date.getUTCDate()}, ${date.getUTCFullYear()}`;
};

const KbArticle = ({
    entry,
    entry: {
        title,
        body,
        header,
        id,
        preview,
        resources,
        homePageReference,
        appVersions,
        productVersions,
        platform
    }
}: KbArticlePageProps) => {
    const headerListItem = (
        <HeaderListItem
            label={translatableText.knowledgeBase.text}
            href={homePageReference.url}
        />
    );

    const lastPublished = entry.lastPublished
        ? convertTimestamp(entry.lastPublished)
        : undefined;

    const apps = appVersions.map((v) => v.app.appName);
    const products = productVersions.map((v) => v.product.productName);
    const platforms = [
        ...appVersions.map((v) => v.platform),
        ...productVersions.map((v) => v.platform)
    ];

    return (
        <>
            <SkipLink />
            <AuthorTools pageId={id} tooltipContent="Edit this KB Article" />
            <div
                className="kb-article section-page flex-column-page"
                data-testid="page-template"
            >
                <div className="content">
                    <Header
                        title={getSupportTitle(homePageReference)}
                        id={header.id}
                        pageId={id}
                        cloudProducts={header.cloudProducts}
                        serverProducts={header.serverProducts}
                        additionalLinks={header.additionalLinks}
                        primaryListItems={headerListItem}
                        showProductDropdownAsWaffle={true}
                        showPreviewBanner={preview}
                        resources={resources}
                        logo={header.logo}
                        atlassianSupportLogo={header.atlassianSupportLogo}
                        atlassianLogo={header.atlassianLogo}
                        shouldRenderDocumentation={false}
                        supportFormUrls={{
                            authenticated: null,
                            unauthenticated: null
                        }}
                    />
                    <div className="main-content-container flex-container margin-top-medium">
                        <main className="kb-article__container">
                            <div className="kb-article__sub-header">
                                <Breadcrumbs
                                    entryId={id}
                                    pageTree={[]}
                                    type={PAGE_KB_ARTICLE}
                                    context={getReferencesFromArticle(entry)}
                                />
                            </div>
                            <article
                                className="kb-article__inner-container margin-top-medium"
                                id="maincontent"
                            >
                                <div className="title margin-bottom-medium">
                                    <h1>{title}</h1>
                                </div>
                                <div className="kb-article__body">
                                    <PlatformNotice platform={platform} />
                                    <KbArticleRtfContent articleBody={body} />
                                    {lastPublished && (
                                        <div
                                            className="kb-article__last-published"
                                            data-testid="kb-article-last-published"
                                        >
                                            Updated on {lastPublished}
                                        </div>
                                    )}
                                    <FeedbackButtons
                                        productName={homePageReference.title}
                                        pageId={id}
                                    />
                                </div>
                                <Sidebar>
                                    <KbArticleSidebar page={entry} />
                                </Sidebar>
                            </article>
                            <AdditionalHelp
                                productNames={[...products, ...apps]}
                                deployments={[...platforms]}
                            />
                        </main>
                    </div>
                </div>
                <Footer
                    logo={header.atlassianLogo.url}
                    className="margin-top-large"
                />
            </div>
        </>
    );
};

export default KbArticle;
