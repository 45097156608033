import React, { useCallback, useEffect } from 'react';
import DropdownMenu from '@atlaskit/dropdown-menu';

// @ts-ignore
import { Header, HeaderProps } from '../../components/header';
// @ts-ignore
import Footer from '../../components/footer';
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs';
import ListingPageFilters from './components/listing-page-filters/listing-page-filters';
import { ReleaseNotesListLoader } from '../../../content/loaders/release-notes-list/release-notes-list.loader';
import { ListingPage } from '../../../content/types/content/listing-page';
import './listing-page.less';
import DropDownButton from './components/dropdown-button';

export interface ListingPageEntry extends ListingPage {
    header: HeaderProps;
}

interface ListingPageProps {
    entry: ListingPageEntry;
    featureFlags: {};
}

/* eslint-disable complexity */

const SACReleaseNotesListingPage = ({
    entry: { pageTitle, pageSubtitle, header, id, type, resources, filterSets }
}: ListingPageProps) => {
    const [selectedFilters, setSelectedFilters] = React.useState<string[]>([]);
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

    useEffect(() => {
        // TODO: test if we need async here
        (async function () {
            await ReleaseNotesListLoader();
        })();
    }, []);

    // #FILTER LIST START -------------------------------------------
    const onFiltersReset = useCallback(() => {
        setSelectedFilters([]);
    }, []);

    const onFilterChange = useCallback(
        (filter: string) => {
            // clone
            const newFilterList: string[] = [...selectedFilters];

            // toggle
            const filterIndex = newFilterList.indexOf(filter);
            if (filterIndex > -1) {
                newFilterList.splice(filterIndex, 1);
            } else {
                newFilterList.push(filter);
            }

            // update
            setSelectedFilters([...newFilterList]);
        },
        [selectedFilters, setSelectedFilters]
    );

    // FILER SETS
    const filterElements = (
        <ListingPageFilters
            filters={filterSets}
            selectedFilters={selectedFilters}
            onFilterChange={onFilterChange}
            onFiltersReset={onFiltersReset}
        />
    );
    // #FILTER LIST END -------------------------------------------

    return (
        <div>
            <Header
                title="Atlassian Support"
                id={header.id}
                pageId={id}
                additionalLinks={header.additionalLinks}
                resources={resources}
                showPreviewBanner={false}
                logo={header.logo}
                cloudProducts={header.cloudProducts}
                serverProducts={header.serverProducts}
                atlassianSupportLogo={header.atlassianSupportLogo}
                atlassianLogo={header.atlassianLogo}
            />

            <main className="main-content-container listing-page">
                {/* BREADCRUMBS */}
                <div className="margin-bottom-medium">
                    <Breadcrumbs
                        pageTree={[]}
                        entryId={''}
                        context={null}
                        type={type}
                    />
                </div>

                {/* PAGE HEADING + SUB HEADING */}
                <div className="margin-bottom-small">
                    <div className="listing-page__title">
                        <h1 data-testid="listing_page_h1">{pageTitle}</h1>
                        <h2 data-testid="listing_page_h2">{pageSubtitle}</h2>
                    </div>
                </div>

                {/* MAIN CONTENT OF PAGE */}
                <div className="main-content">
                    {/* LEFT FILTER VIEW */}

                    <div className="main-content__filters">
                        {/* SHOW < 720PX */}
                        <div className="only-mobile-view force-full-width-dropdown">
                            <DropdownMenu
                                onOpenChange={(attrs: any) => {
                                    setIsDropdownOpen(attrs.isOpen);
                                }}
                                trigger={
                                    <DropDownButton isActive={isDropdownOpen} />
                                }
                                appearance="tall"
                            >
                                {filterElements}
                            </DropdownMenu>
                        </div>

                        {/* SHOWS >= 720PX */}
                        <div className="only-desktop-view">
                            {filterElements}
                        </div>
                    </div>

                    {/* RIGHT SEARCH RESULT VIEW */}
                    <div className="main-content__results">
                        {/* HEADLINE */}
                        <div className="main-content__results__title">
                            All releases
                        </div>
                        {/* SEARCH RESULTS */}
                        <div></div>
                    </div>
                </div>
            </main>

            {/* PAGE FOOTER */}
            <Footer
                logo={header.atlassianLogo.url}
                className={'margin-top-large'}
            />
        </div>
    );
};

export default SACReleaseNotesListingPage;
