import { TOPIC, TOPIC_SET } from '../../contentful/content-types';

export default (tree: any, targetContentId: string, type: string) => {
    let foundBreadcrumbs: any = null;

    //only these two types have breadcrumbs that need calculating
    if (!(type === TOPIC_SET || type === TOPIC)) {
        return [];
    }

    function traversePageTree(nodes: any, parents = []) {
        // for every node in the list
        for (let i = 0; !foundBreadcrumbs && i < nodes.length; i++) {
            const currentNode = nodes[i];

            // if this is the node we are looking for
            if (currentNode.id === targetContentId) {
                // then this node's parents are equivalent to its breadcrumbs
                foundBreadcrumbs = parents;
            }

            // if this node has children
            if (currentNode.childList) {
                // add this node to a list of potential parents
                const currentNodeWithParents = parents
                    .slice()
                    .concat(currentNode);

                // dive dive dive
                traversePageTree(currentNode.childList, currentNodeWithParents);
            }
        }
    }

    traversePageTree(tree);

    return foundBreadcrumbs || [];
};
