import {
    DOCUMENTATION_CARD_CONTENT_ID,
    COMMUNITY_CARD_CONTENT_ID,
    STATUS_CARD_CONTENT_ID,
    FEEDBACK_CARD_CONTENT_ID,
    MARKETPLACE_CARD_CONTENT_ID,
    BILLING_CARD_CONTENT_ID,
    KNOWLEDGEBASE_CARD_CONTENT_ID
} from './constants';

const defaultCardHandler = ({ card, link, entry, index }) => {
    card.buttonLink = link;
    // remove link if not defined
    if (!card.buttonLink) {
        entry.resourceCards.cards.splice(index, 1);
    }
};

const CARD_CONTENT_ID_TO_LINK_MAP = {
    [DOCUMENTATION_CARD_CONTENT_ID]: 'documentationLink',
    [COMMUNITY_CARD_CONTENT_ID]: 'communityLink',
    [STATUS_CARD_CONTENT_ID]: 'statusLink',
    [FEEDBACK_CARD_CONTENT_ID]: 'feedbackLink',
    [MARKETPLACE_CARD_CONTENT_ID]: 'marketplaceLink',
    [BILLING_CARD_CONTENT_ID]: 'billingLicensingLink',
    [KNOWLEDGEBASE_CARD_CONTENT_ID]: 'knowledgeBaseLink'
};

export const buildLinks = (entry, index) => {
    const card = entry.resourceCards.cards[index];
    const linkKey = CARD_CONTENT_ID_TO_LINK_MAP[card.id];

    if (linkKey) {
        defaultCardHandler({
            card,
            link: entry[linkKey],
            entry,
            index
        });
    } else {
        card.buttonLink = null;
    }
};

export const setDefaultCardLinks = (entry) => {
    // don't do anything if there aren't any resource cards defined
    if (!entry.resourceCards.cards.length) {
        return;
    }

    // iterate through each defaultResourceCard replacing the default links with
    // links from the productLandingPage, if defined.
    const length = entry.resourceCards.cards.length;

    for (let index = length - 1; index >= 0; index--) {
        buildLinks(entry, index);
    }
};
