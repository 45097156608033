import analytics from './renderer/utils/analytics';
import { initializeBrowserApp } from './renderer/utils/initialize-browser-app';

window.addEventListener(
    'load',
    () => {
        initializeBrowserApp();
    },
    { once: true }
);

window.addEventListener(
    'initanalytics',
    () => {
        analytics.init(window.SEGMENT_KEY);
    },
    { once: true }
);
