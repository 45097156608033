/* eslint-disable react/prop-types */
import React from 'react';
import { translatableText } from '../../utils/translatable-text';
// @ts-ignore
import { CardWithCEM } from '../card';
// @ts-ignore
import { TopicReference } from 'src/renderer/components/sidebar/sidebar.types';
import camelCase from 'lodash/camelCase';

interface CardsProps {
    cards: TopicReference[];
}

const Cards: React.FC<CardsProps> = ({ cards }) => {
    return (
        <>
            {cards.map((item: TopicReference) => (
                <CardWithCEM
                    tooltipContent={translatableText.editThisTopic.text}
                    key={item.id}
                    id={item.id}
                    title={item.title}
                    description={item.description}
                    buttonText={translatableText.viewTopic.text}
                    buttonLink={item.url}
                    analytics={camelCase(item.title)}
                />
            ))}
        </>
    );
};

export default Cards;
