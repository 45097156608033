import ReleaseNotesList from '../../types/content/release-notes-list';
import { withDynamicLoader } from '../utils/with-dynamic-loader/with-dynamic-loader';

import { generatePaginatedSchema } from './release-notes-list.schema';
import { loaderQuery } from './release-notes-list';

export const ReleaseNotesListLoader = withDynamicLoader({
    loaderQuery,
    loaderSchema: generatePaginatedSchema('releaseNotesList'),
    loaderFn: async (releaseNotesListResponse): Promise<ReleaseNotesList> => {
        const { pageInfo } = releaseNotesListResponse;

        const releaseNotesList = releaseNotesListResponse.edges.map(
            (releaseNote: any) => {
                const {
                    releaseNoteId,
                    releaseNoteTitle,
                    featureRolloutDate,
                    changeStatus: { label: currentStatus },
                    url,
                    relatedContexts,
                    changeType: {
                        label: changeTypeLabel,
                        icon: changeTypeIcon
                    },
                    description
                } = releaseNote.node;

                return {
                    releaseNoteId,
                    url,
                    releaseNoteTitle,
                    relatedContexts,
                    featureRolloutDate,
                    description,
                    currentStatus,
                    changeTypeLabel,
                    changeTypeIcon
                };
            }
        );

        return {
            releaseNotesList,
            pageInfo
        };
    },
    usesCPAPI: true,
    type: 'releaseNotesList'
});
