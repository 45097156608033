import React from 'react';
import MenuItem from './menu-item';
import SectionHeading from './section-heading';
import Separator from './separator';
import UserDetails from './user-details';
import './account-menu-dialog.less';
import propTypes from 'prop-types';

function AccountMenuDialog({ menuTitle, userDetails, sections }) {
    const sectionsElements = sections.map((section, index) => {
        if (section.type === 'separator') {
            return <Separator key={index} />;
        } else if (section.type === 'submenu') {
            return (
                <div key={index} className="account-items-container">
                    {/* HEADING */}
                    {section.title ? (
                        <SectionHeading title={section.title} />
                    ) : null}
                    {/* MENU LINKS */}
                    {section.items.map(
                        (
                            {
                                title,
                                href,
                                isShortcut,
                                hasLozenge,
                                dataAttributes
                            },
                            index
                        ) => {
                            return (
                                <MenuItem
                                    key={index}
                                    title={title}
                                    href={href}
                                    isShortcut={isShortcut}
                                    hasLozenge={hasLozenge}
                                    dataAttributes={dataAttributes}
                                />
                            );
                        }
                    )}
                </div>
            );
        }
        return null;
    });

    return (
        <div>
            <div className="account-items-container">
                <SectionHeading title={menuTitle} />
            </div>

            <UserDetails
                name={userDetails.name}
                email={userDetails.email}
                avatarUrl={userDetails.avatarUrl}
            />

            {sectionsElements}
        </div>
    );
}

AccountMenuDialog.propTypes = {
    sections: propTypes.arrayOf(
        propTypes.shape({
            type: propTypes.string,
            items: propTypes.arrayOf(
                propTypes.shape({
                    title: propTypes.string,
                    href: propTypes.string,
                    isShortcut: propTypes.bool,
                    hasLozenge: propTypes.bool,
                    dataAttributes: propTypes.arrayOf(
                        propTypes.shape({
                            name: propTypes.string,
                            value: propTypes.string
                        })
                    )
                })
            )
        })
    ),
    menuTitle: propTypes.string,
    userDetails: propTypes.shape({
        name: propTypes.string,
        email: propTypes.string,
        avatarUrl: propTypes.string
    })
};

export default React.memo(AccountMenuDialog);
