/* eslint-disable react/prop-types */
import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { capitalize } from 'lodash';
import type {
    KbArticleBody,
    GqlKbArticleRichTextData
} from '../../../content/types/content/kb-article';
import {
    BLOCKS,
    type Block,
    INLINES,
    type Inline
} from '@contentful/rich-text-types';
import {
    retrieveAndRenderBlockEntry,
    retrieveAndRenderHyperlinkedEntry
} from './utils/retrieve-and-render-entry';
import { RENDER_TABLE_NODES } from './utils/render-content/render-table';

export const ORDERED_RICH_TEXT_FIELDS = [
    'summary',
    'diagnosis',
    'cause',
    'solution'
] as const;

const renderKbArticleRtfField = (
    richTextData: GqlKbArticleRichTextData
): React.ReactNode => {
    const {
        json,
        links: {
            entries: { block = [], hyperlink = [] }
        }
    } = richTextData;

    const blockEntryMap = new Map(block.map((entry) => [entry.sys.id, entry]));
    const hyperlinkEntryMap = new Map(
        hyperlink.map((entry) => [entry.sys.id, entry])
    );

    const options = {
        renderNode: {
            [BLOCKS.EMBEDDED_ENTRY]: (node: Block | Inline) =>
                retrieveAndRenderBlockEntry(blockEntryMap, node),
            [INLINES.ENTRY_HYPERLINK]: (
                node: Block | Inline,
                children: React.ReactNode
            ) =>
                retrieveAndRenderHyperlinkedEntry(
                    hyperlinkEntryMap,
                    node,
                    children
                ),
            ...RENDER_TABLE_NODES
        }
    };

    return documentToReactComponents(json, options);
};

const renderKbArticleRtfFields = (articleBody: KbArticleBody) => {
    return ORDERED_RICH_TEXT_FIELDS.reduce<JSX.Element[]>(
        (renderedBodyComponents, fieldName, index) => {
            const fieldBody = articleBody[fieldName];

            if (fieldBody) {
                const fieldTitle = capitalize(fieldName);
                const marginTopStyle =
                    index === 0 ? { marginTop: '0px' } : undefined;

                renderedBodyComponents.push(
                    <React.Fragment key={fieldName}>
                        <h2 id={fieldName} style={marginTopStyle}>
                            {fieldTitle}
                        </h2>
                        {renderKbArticleRtfField(fieldBody)}
                    </React.Fragment>
                );
            }

            return renderedBodyComponents;
        },
        []
    );
};

interface KbArticleRtfContentProps {
    articleBody: KbArticleBody;
}

const KbArticleRtfContent: React.FC<KbArticleRtfContentProps> = ({
    articleBody
}) => {
    const content = renderKbArticleRtfFields(articleBody);

    return <div className="rtf__content">{content}</div>;
};

export default KbArticleRtfContent;
