import React, { useState, useEffect, useCallback } from 'react';
import UserAvatarCircleIcon from '@atlaskit/icon/glyph/user-avatar-circle';
import QuestionCircleIcon from '@atlaskit/icon/glyph/question-circle';
import { AtlasLink } from '../atlas-link';
import { translatableText } from '../../utils/translatable-text';
import { useSupportFormUrls } from '../../hooks/use-support-form-urls';
import { getLoginUrl } from '../../utils/get-auth-url';
import { SupportFormUrls } from '../contact';
import HelpfulTopics from '../helpful-topics';
import './dropdown.less';
import camelCase from 'lodash/camelCase';
import { AdditionalLinkType } from '../../pages/product-landing/product-landing-type';
import { useAuth } from '../../providers/auth-providers';

export interface AppAndThemeCards {
    title: string;
    url: string;
    description: string;
    id: string;
}
interface DropdownSideNavProps {
    supportFormUrls?: SupportFormUrls;
    additionalLinks: AdditionalLinkType[];
    className?: string;
    type: string;
    relatedAppReferences: AppAndThemeCards[] | undefined;
}

const getNavLinkClassName = (link: AdditionalLinkType) => {
    if (link.linkIcon) {
        return 'nav-links__item--icon nav-links__item--contact';
    }
    if (link.linkLabel === 'Atlassian.com') {
        return 'nav-links__item--wac';
    }
    return 'nav-links__item';
};

const renderAdditionalLinks = (
    links: AdditionalLinkType[],
    supportFormHref: string
) => {
    return links.map((link) => (
        <li
            data-testid="dropdown_item"
            className={getNavLinkClassName(link)}
            key={link.id}
        >
            {link.linkIcon && <QuestionCircleIcon label="question mark icon" />}
            <AtlasLink
                href={
                    link.linkUrl.includes('/contact')
                        ? supportFormHref
                        : link.linkUrl
                }
                text={link.linkLabel}
                dataName={camelCase(link.linkLabel)}
            >
                <span>{link.linkLabel}</span>
            </AtlasLink>
        </li>
    ));
};

const DropdownSideNav = ({
    supportFormUrls,
    additionalLinks,
    className,
    type,
    relatedAppReferences
}: DropdownSideNavProps) => {
    const supportFormLinks = useSupportFormUrls(supportFormUrls);
    const { isAuthenticated } = useAuth();
    const [loginLink, setLoginLink] = useState('');

    const { href: supportFormHref } =
        supportFormLinks[
            isAuthenticated ? 'authenticated' : 'needsAuthentication'
        ];

    const updateLoginLink = useCallback(
        function () {
            setLoginLink(getLoginUrl(window.location.href));
        },
        [setLoginLink]
    );

    useEffect(() => {
        updateLoginLink();
    }, [updateLoginLink]);

    if (type === 'Documentation') {
        return (
            <div
                className={className}
                data-testid="dropdown-side-content_documentation"
            >
                <HelpfulTopics relatedAppReferences={relatedAppReferences} />
            </div>
        );
    }

    return (
        <div className={className} data-testid="dropdown-side-content">
            <ul className="nav-links__list">
                {!isAuthenticated && (
                    <li
                        data-testid="dropdown_item"
                        className="nav-links__item--icon"
                        key={'log_into_account_item'}
                    >
                        <UserAvatarCircleIcon label="login avatar icon" />
                        <a href={loginLink}>
                            <span>{translatableText.logInToAccount.text}</span>
                        </a>
                    </li>
                )}
                {additionalLinks &&
                    renderAdditionalLinks(additionalLinks, supportFormHref)}
            </ul>
        </div>
    );
};

export default DropdownSideNav;
