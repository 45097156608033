import { useState, useEffect } from 'react';

export default function useDropdownFocus({
    isOpen,
    closeDropdown
}: {
    isOpen: boolean;
    closeDropdown: Function;
}) {
    // set the state of the first element
    const [firstElement, setFirstElement] = useState(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleEscapeKey = ({ key }: { key: string }) => {
        if (key === 'Escape') {
            // @ts-ignore
            document.activeElement.blur();
            closeDropdown();
        }
    };

    useEffect(() => {
        if (isOpen && firstElement !== null) {
            // @ts-ignore
            firstElement.focus();
        }
    }, [isOpen, firstElement]);

    useEffect(() => {
        window.addEventListener('keyup', handleEscapeKey);

        return () => {
            window.removeEventListener('keyup', handleEscapeKey);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return setFirstElement;
}
