// Temporarily Hardcoded Jira context ids. All doc pages in these context for
// should display jira admin hub link  (FRAK-1834)
const hardcodedJiraContexts = [
    // Jira Software Cloud
    '3yydXRuQ88gGCKOyYsAGaU',
    // Jira Service Management Cloud
    '1gXBh54v1sayA6w8yowM8u',
    // Jira Core Cloud
    '2QnaIiOyGAeI4aWeg4y8ms',
    // Jira Cloud app for iOS
    '3981JAWMVEsIEWWivaEiTq',
    // Jira Cloud app for android
    '1gpdDE2G09yKbUHkuVStz3',
    // Jira Cloud app for macOS
    '1Vq0XfobLij8lzVbb5j5DV'
];

const hardcodedAppAndThemeReferences = {
    marketplaceAppLink: {
        title: 'Atlassian Marketplace',
        url: 'https://marketplace.atlassian.com/',
        description: 'Explore apps for Atlassian products',
        id: 'marketplace-apps'
    },
    developersLink: {
        title: 'Developers',
        url: 'https://developer.atlassian.com/',
        description:
            'Find API documentation and guides for developing apps and integrations',
        id: 'developer-link'
    },
    trainingLink: {
        title: 'Training & Certification',
        url: 'https://atlassian.com/university',
        description:
            'Develop new skills, improve your team’s work, and advance your professional career',
        id: 'university-link'
    }
};

const hardcodedHubReferences = {
    atlassianAccounts: {
        title: 'Atlassian accounts',
        url: '/atlassian-account/resources/',
        description:
            'Learn how to log in to your Atlassian products and manage your account',
        id: '1P7jDE77y08HZPQHzGxw8k'
    },
    bitbucketCloud: {
        title: 'Bitbucket Cloud',
        url: '/bitbucket-cloud/resources/',
        description:
            'Bitbucket gives teams one place to plan, collaborate, test, and deploy their code',
        id: '3WYF8m9Vo0K1baX5QeoT7i'
    },
    cloudAutomation: {
        title: 'Cloud automation',
        url: '/cloud-automation/resources/',
        description:
            'References, guides, and step-by-step procedures for anything related to automation rules in Jira Cloud products.',
        id: '5bkOpPTsel8QTJ9u5Ps8J'
    },
    confluenceCloud: {
        title: 'Confluence Cloud',
        url: '/confluence-cloud/resources/',
        description:
            'Confluence is where you create, organize, and discuss work with your team',
        id: '2WF3bPCZbSmlv54OxarLKk'
    },
    jiraCloudAdmin: {
        title: 'Administer Jira Cloud products',
        url: '/jira-cloud-administration/resources/',
        description:
            'Jira Cloud administrators can set up projects and processes to suit the needs of their team',
        id: '1SgPcTLL9n03fdodr7pwbV'
    },
    jiraCloudAppAndroid: {
        title: 'Jira Cloud mobile for Android',
        url: '/jira-cloud-android/resources/',
        description: 'Move work forward anywhere, anytime',
        id: '45LmPULrDObGgmX88WprtN'
    },
    jiraCloudAppIOS: {
        title: 'Jira Cloud mobile for iOS',
        url: '/jira-cloud-ios/resources/',
        description: 'Move work forward anywhere, anytime',
        id: '2cqpcSrRfTWvj0TpyW4GH5'
    },
    jiraCloudAppMacOS: {
        title: 'Jira Cloud mobile for macOS',
        url: '/jira-cloud-macos/resources/',
        description: 'Move work forward anywhere, anytime',
        id: '3fGMZ0ofM3dvqjptHDkbbY'
    },
    jiraWorkManagement: {
        title: 'Jira Work Management',
        url: '/jira-work-management/resources/',
        description:
            'Jira Work Management helps business teams manage their work and stay organized',
        id: '1AoRHgV2ST5KsZUmL8wsVJ'
    },
    jiraServiceManagementCloud: {
        title: 'Jira Service Management Cloud',
        url: '/jira-service-management-cloud/resources/',
        description:
            'Get started guides and other documentation about using Jira Service Management Cloud',
        id: '5BhC78eUQTRLlYGSaYIHNR'
    },
    jiraSoftwareCloud: {
        title: 'Jira Software Cloud',
        url: '/jira-software-cloud/resources/',
        description:
            'Jira Software combines development tools with agile features to help teams build world-class software',
        id: 'yyP8dUm27XSqsglpG8eTb'
    },
    migration: {
        title: 'Migrate your Atlassian server to cloud',
        url: '/migration/resources/',
        description:
            "Find documentation for moving from Atlassian's self-managed server or Data Center products to cloud",
        id: '1lxxwYhdYVq6kXgjOLUuOv'
    },
    opsgenieProduct: {
        title: 'Opsgenie',
        url: '/opsgenie/resources/',
        description:
            'Opsgenie delivers informational alerts to the right people, enabling them to assess the incident and take appropriate actions rapidly',
        id: '3PEIFqX3g9KR77WoWJZuNn'
    },
    opsgenieAppIOS: {
        title: 'Opsgenie app for iOS',
        url: '/opsgenie-ios/resources/',
        description:
            'Learn how to receive alerts as push notifications and use Opsgenie on the go',
        id: '2ulSnRvOR8SEqfpw7oRn9E'
    },
    opsegenieAppAndroid: {
        title: 'Opsgenie app for Android',
        url: '/opsgenie-android/resources/',
        description:
            'Learn how to receive alerts as push notifications and use Opsgenie on the go',
        id: '1J7ogyxsMbYsMopIUU155M'
    },
    opsgenieAppBlackberry: {
        title: 'Opsgenie app for BlackBerry',
        url: '/opsgenie-blackberry-dynamics/resources/',
        description:
            'Learn how to receive alerts as push notifications and use Opsgenie on the go',
        id: '6qMJZqarDiEBg8OfDX2Pdq'
    },
    organizationAdmin: {
        title: 'Organization administration',
        url: '/organization-administration/resources/',
        description:
            'Customize your organization and manage product settings as an organization admin',
        id: '2XiSkD3Fl9NcyxG0feOXHl'
    },
    postMigration: {
        title: 'Post-migration',
        url: '/post-migration/resources/',
        description:
            'Learn what to do once you have migrated from server to cloud',
        id: '4STjN0TPjzFj3FFsa8I9Kj'
    },
    preMigration: {
        title: 'Pre-migration',
        url: '/pre-migration/resources/',
        description:
            'Learn how to start your Jira, Confluence, or Bitbucket migration from server to cloud',
        id: '4NXvImPgSRG4gDFH3mpYVU'
    },
    provisioningUsers: {
        title: 'Provisioning users',
        url: '/provisioning-users/resources/',
        description:
            'Sync users and groups from an external directory to your organization and products',
        id: '24VgEHmtZpmsuNnxvDB9n0'
    },
    securityAndAccessPolicies: {
        title: 'Security and access policies',
        url: '/security-and-access-policies/resources/',
        description:
            'Learn how to keep your data secure and configure security and authentication policies',
        id: 'bu0Nrvv6chXfTzkaKXwqq'
    },
    statuspage: {
        title: 'Statuspage',
        url: '/statuspage/resources/',
        description:
            'The communication piece of your incident management process',
        id: 'XA8HZy7GQRfJGNV91HUHv'
    },
    subscriptionsAndBilling: {
        title: 'Subscriptions and billing',
        url: '/subscriptions-and-billing/resources/',
        description:
            'Learn how to manage subscriptions and billing for Atlassian cloud products',
        id: '2t0MPg8ycFWPqcz0h71ZFA'
    },
    userManagement: {
        title: 'Manage your organization’s users',
        url: '/user-management/resources/',
        description:
            'Learn how to manage your users’ accounts, their roles, and product access settings.',
        id: '6R9GKyMLkTYfNSNvpgygVx'
    }
};

export {
    hardcodedJiraContexts,
    hardcodedAppAndThemeReferences,
    hardcodedHubReferences
};
