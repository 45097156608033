import { ResourceType } from '../../components/card/card.type';
import { string, arrayOf, shape, oneOf } from 'prop-types';
import { ProductType } from '../../components/product/product.type';
import { IconType } from '../../components/asset/asset.type';
import { withPageType } from '../../utils/with-page-type';

export const HomeType = {
    id: string.isRequired,
    title: string,
    heroIconPrimary: IconType,
    heroIconSecondary: IconType,
    cards: arrayOf(ResourceType),
    featuredResources: shape({
        id: string.isRequired,
        cards: arrayOf(ResourceType),
        type: oneOf(['defaultResourceCards'])
    }),
    cloudProducts: arrayOf(ProductType),
    serverProducts: arrayOf(ProductType)
};

export const HomePageType = withPageType(HomeType);
