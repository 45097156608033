import { string, shape, oneOf } from 'prop-types';
import { IconType, Icon } from '../asset/asset.type';
import {
    DeploymentType,
    Deployment
} from '../deployment-selector/deployments.type';

export const ProductType = shape({
    id: string.isRequired,
    icon: IconType,
    version: string,
    productBlurb: string,
    internalTitle: string,
    productNameReference: shape({
        id: string,
        productName: string
    }),
    deployment: oneOf(['Cloud', 'Server', 'Data Center', 'Not applicable'])
        .isRequired,
    deploymentUrls: DeploymentType
});

export interface Product {
    id: string;
    icon: Icon;
    version?: string;
    productBlurb?: string;
    internalTitle?: string;
    docsHomeSlug?: string;
    productNameReference: {
        id?: string;
        productName?: string;
    };
    deployment: 'Cloud' | 'Server' | 'Data Center' | 'Not applicable';
    deploymentUrls: Deployment;
}
