import { translatableText } from '../../utils/translatable-text';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import React, { FC } from 'react';
import { GuideType } from '../../pages/collection/guide.type';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';

interface GuideDropdownProps {
    guides: GuideType[];
    onClick: (event: React.MouseEvent) => void;
}

export const GuidesMobileDropdownButton: FC<GuideDropdownProps> = ({
    guides,
    onClick
}: GuideDropdownProps) => {
    if (!guides || guides.length === 0) {
        return null;
    }

    return (
        <div
            data-event-component="mobileDropdownItemGuides"
            data-track-event="clicked"
            data-testid="mobile_dropdown_item_guides"
            className="dropdown__mobile-item"
            role="button"
            aria-label="Trigger guides dropdown"
            tabIndex={0}
            onClick={onClick}
            onKeyDown={(e) => e.keyCode === 13 && { onClick }}
        >
            <span>{translatableText.getStarted.text}</span>
            <ChevronRightIcon label="chevron-right" />
        </div>
    );
};

export const GuidesDropdownButton: FC<GuideDropdownProps> = ({
    guides,
    onClick
}: GuideDropdownProps) => {
    if (!guides || guides.length === 0) {
        return null;
    }

    if (guides.length === 1) {
        return (
            <li>
                <div
                    data-event-container="pageHeader"
                    data-event-component="getStartedButton"
                    data-track-event="clicked"
                    data-testid="get-started-button"
                    className="header__nav-item contact-btn"
                    role="button"
                    tabIndex={0}
                    aria-label="Go to get started page"
                    onClick={() => window.open(guides[0].url, '_self')}
                    onKeyDown={(e) =>
                        e.keyCode === 13 && window.open(guides[0].url, '_self')
                    }
                >
                    <a href={guides[0].url}>
                        {translatableText.getStarted.text}
                    </a>
                </div>
            </li>
        );
    }

    return (
        <li>
            <div
                data-event-container="pageHeader"
                data-event-component="getStartedDropdown"
                data-track-event="clicked"
                data-testid="get-started-dropdown"
                className="header__nav-item"
                role="button"
                tabIndex={0}
                aria-label="Open get started dropdown"
                onClick={onClick}
                onKeyDown={(e) => e.keyCode === 13 && { onClick }}
            >
                <span data-testid="get-started-dropdown-text">
                    {translatableText.getStarted.text}
                </span>
                <ChevronDownIcon label="chevron-down" />
            </div>
        </li>
    );
};
