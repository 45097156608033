import { string, arrayOf, oneOfType, object } from 'prop-types';
import { withPageType } from '../../utils/with-page-type';
import { ProductType } from '../../components/product/product.type';
import { VersionsType } from '../../components/deployment-selector/versions.type';

export const TopicType = {
    topicTitle: string,
    shortTitle: string,
    tableOfContents: arrayOf(string),
    body: oneOfType([
        string, // for a MD based body
        object // for an ADF based body
    ]),
    id: string.isRequired,
    lastPublished: string,
    url: string,
    metaDescription: string,
    deployment: string,
    versions: arrayOf(VersionsType),
    product: ProductType
};

export const TopicPageType = withPageType(TopicType);
