import React from 'react';
import Avatar from '@atlaskit/avatar';
import propTypes from 'prop-types';
const UserDetails = ({ email, name, avatarUrl }) => {
    return (
        <div className="user-details">
            <div>
                <Avatar
                    appearance="circle"
                    src={avatarUrl}
                    name={name}
                    size="medium"
                />
            </div>
            <div className="user-info">
                <div className="user-name">{name}</div>
                <div className="user-email">{email}</div>
            </div>
        </div>
    );
};

UserDetails.propTypes = {
    email: propTypes.string,
    name: propTypes.string,
    avatarUrl: propTypes.string
};

export default UserDetails;
