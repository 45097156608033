import React from 'react';
import ChangeIcon from 'src/content/types/content/change-icon';
import './release-note-sidebar.less';

export const ChangeType = ({
    changeTypeLabel,
    changeTypeIcon
}: {
    changeTypeLabel: string;
    changeTypeIcon: ChangeIcon;
}) => {
    return (
        <div
            className="sidebar sidebar__section--release-note"
            data-testid="change-type-component"
        >
            <div className="sidebar__heading">Release Type</div>

            <div className="sidebar__item ">
                <img
                    className="document-icon-wrapper"
                    src={changeTypeIcon.url}
                    alt={changeTypeIcon.title}
                />
                <span className="sidebar__item--change-type">
                    {changeTypeLabel}
                </span>
            </div>
        </div>
    );
};
