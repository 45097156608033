import React from 'react';

export interface AtlasImageProps {
    imgSrc: string;
    altTag: string;
}

// eslint-disable-next-line react/prop-types
const AtlasImage: React.FC<AtlasImageProps> = ({ imgSrc, altTag }) => {
    const imgStyle = {
        maxWidth: '100%',
        border: '2px inset rgba(9,30,66,0.08)',
        WebkitBorderRadius: '3px',
        MozBorderRadius: '3px',
        borderRadius: '3px'
    };

    return (
        <div style={{ textAlign: 'center' }}>
            {(imgSrc || altTag) && (
                <img
                    style={imgStyle}
                    src={imgSrc}
                    alt={altTag}
                    onDragStart={(e) => {
                        e.preventDefault();
                    }}
                />
            )}
        </div>
    );
};

export default AtlasImage;
