import React from 'react';
import render404 from '../pages/404';
import renderCollection from '../pages/collection';
import renderHome from '../pages/home';
import renderProductLanding from '../pages/product-landing';
import renderDocumentedProduct from '../pages/documented-product';
import renderTopic from '../pages/topic';
import renderTopicSet from '../pages/topic-set';
import renderContact from '../pages/contact';
import renderHotContact from '../pages/hot-contact';
import renderPartnerContact from '../pages/partner-contact';
import renderStatuspageContact from '../pages/statuspage-contact';
import renderCspRequestsAppPage from '../pages/csp-app-page';
import renderPreview from '../pages/preview-error';
import renderFileDownload from '../pages/file-download';
import ReleaseNotesDetailedPage from '../pages/release-notes-detailed';
import ListingPage from '../pages/listing-page';
import renderSearchResultsPage from '../pages/search-results';
import renderKbArticle from '../pages/kb-article';
import renderKbHome from '../pages/kb-home';
import {
    NOT_FOUND_PAGE,
    COLLECTION,
    HOME_PAGE,
    PRODUCT_LANDING_PAGE,
    DOCUMENTED_PRODUCT,
    TOPIC,
    TOPIC_SET,
    CONTACT_PAGE,
    HOT_CONTACT_PAGE,
    PARTNER_CONTACT_PAGE,
    STATUSPAGE_CONTACT_PAGE,
    CSP_REQUESTS_APP_PAGE,
    TOPIC_DERIVED,
    FILE_DOWNLOAD_PAGE,
    RELEASE_NOTE,
    LISTING_PAGE,
    SEARCH_RESULTS_PAGE,
    PAGE_KB_ARTICLE,
    PAGE_KB_HOME
} from '../../contentful/content-types';
import '../less/_base.less';
import '../less/_base_v2.less';
import CurrentPageIdContext from '../contexts/current-page-id-context';
import CurrentContextIdContext from '../contexts/current-context-id-context';
import DefaultFeatureFlagsContext from '../contexts/default-feature-flags-context';
import { PREVIEW_ERROR } from '../../common/constants';

import { AuthProvider } from '../providers/auth-providers';

const pageRenderMapping = (contentType) =>
    ({
        [NOT_FOUND_PAGE]: render404,
        [COLLECTION]: renderCollection,
        [HOME_PAGE]: renderHome,
        [PRODUCT_LANDING_PAGE]: renderProductLanding,
        [DOCUMENTED_PRODUCT]: renderDocumentedProduct,
        [TOPIC]: renderTopic,
        [TOPIC_DERIVED]: renderTopic,
        [TOPIC_SET]: renderTopicSet,
        [CONTACT_PAGE]: renderContact,
        [HOT_CONTACT_PAGE]: renderHotContact,
        [PARTNER_CONTACT_PAGE]: renderPartnerContact,
        [STATUSPAGE_CONTACT_PAGE]: renderStatuspageContact,
        [CSP_REQUESTS_APP_PAGE]: renderCspRequestsAppPage,
        [PREVIEW_ERROR]: renderPreview,
        [FILE_DOWNLOAD_PAGE]: renderFileDownload,
        [RELEASE_NOTE]: ReleaseNotesDetailedPage,
        [LISTING_PAGE]: ListingPage,
        [SEARCH_RESULTS_PAGE]: renderSearchResultsPage,
        [PAGE_KB_ARTICLE]: renderKbArticle,
        [PAGE_KB_HOME]: renderKbHome
    }[contentType]);

export default function renderReactApp(entry, pageTree, featureFlags) {
    if (entry) {
        const contentType = entry.type;
        const renderFunction = pageRenderMapping(contentType);

        return (
            <AuthProvider>
                <CurrentPageIdContext.Provider value={entry.id}>
                    <CurrentContextIdContext.Provider
                        value={entry.context?.id || ''}
                    >
                        <DefaultFeatureFlagsContext.Provider
                            value={featureFlags}
                        >
                            {/* This prevents exposure of the unpublished pages on product landing pages */}
                            {renderFunction(
                                entry,
                                contentType !== PRODUCT_LANDING_PAGE &&
                                    pageTree,
                                featureFlags
                            )}
                        </DefaultFeatureFlagsContext.Provider>
                    </CurrentContextIdContext.Provider>
                </CurrentPageIdContext.Provider>
            </AuthProvider>
        );
    }

    throw new Error('No renderer found for entry');
}
