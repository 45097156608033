/* eslint-disable no-console */

// Stub out these server functions so the
// strategies have the same API
export const requestLogger = () => {};

export const errorRequestLogger = () => {};

const Logger = {
    debug: console.log,
    info: console.log,
    warn: console.error,
    error: console.error,
    fatal: console.error
};

export default Logger;
