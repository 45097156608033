/* eslint-disable complexity */
import React from 'react';
import './search-result-card.less';

import CheckIcon from '@atlaskit/icon/glyph/check';
import CommentIcon from '@atlaskit/icon/glyph/comment';
import LikeIcon from '@atlaskit/icon/glyph/like';
import { AtlasLink } from '../atlas-link';
import { Breadcrumb, TitleSection, TicketType } from '.';
import {
    highlightSearchTerm,
    highlightText
} from '../../utils/highlight-search-result-keyword';
import analytics from '../../utils/analytics';
import { MouseEventButton } from '../../utils/constants';
import { translatableText } from '../../utils/translatable-text';
import { HeaderProps } from '../header';
import { Deployment } from '../../pages/search-results/types';
import { getContentAri } from '../../utils/get-content-ari';

const MOUSE_EVENT_BUTTONS_TO_TRACK = new Set([
    MouseEventButton.LEFT,
    MouseEventButton.MIDDLE
]);

export interface SearchResultCardProps {
    id: string;
    ticketType?: TicketType;
    breadcrumbs?: string[];
    title: string;
    description: string;
    dateModified?: string;
    answerAccepted?: boolean;
    views?: number;
    comments?: number;
    markedUseful?: number;
    href: string;
    searchTerm: string;
    position: number;
    queryId: string;
    versions?: string[];
    productName?: string;
    products?: any[];
    productIcons?: HeaderProps['productIcons'];
    deployment?: string; // Remove when isSacSearch2Enabled is fully implemented
    isSearchTermHighlightingEnabled?: boolean;
    isSacSearch2Enabled?: boolean;
}

export const SearchResultCard: React.FC<SearchResultCardProps> = ({
    id,
    breadcrumbs,
    title,
    description,
    dateModified,
    answerAccepted,
    views,
    comments,
    markedUseful,
    ticketType,
    href,
    searchTerm,
    position,
    queryId,
    products,
    deployment, // Remove when isSacSearch2Enabled is fully implemented
    productIcons,
    isSearchTermHighlightingEnabled,
    isSacSearch2Enabled
}: SearchResultCardProps) => {
    const isFooterVisible = !!(
        answerAccepted ||
        views ||
        comments ||
        markedUseful
    );
    const timeRendered = Date.now();

    let textForVersion; // Search v1
    let lozengeText; // Search v2
    let productIconUrl; // Search v2

    const getTextForVersion = (versions: string[], productName: string) => {
        if (versions?.length === 1) {
            textForVersion = `${productName} ${versions[0]}`;
        } else if (versions?.length > 1) {
            textForVersion = translatableText.multipleVersionsProducts.text;
        }
    };

    const getLozengeText = (versions: string[], productName: string) => {
        if (versions?.length === 1) {
            lozengeText = `${productName} ${versions[0]}`;
        } else if (versions?.length > 1) {
            lozengeText = `${productName} ${
                versions[versions.length - 1]
            } & later`;
        } else {
            lozengeText = productName;
        }
    };

    if (products?.length === 1) {
        const { versions, name, key, platform } = products[0];
        if (isSacSearch2Enabled) {
            getLozengeText(versions, name);
            productIconUrl = productIcons?.[platform as Deployment]?.[key];
        } else {
            getTextForVersion(versions, name);
        }
    } else if (products?.length && products.length > 1) {
        textForVersion = translatableText.multipleVersionsProducts.text; // Search v1
        lozengeText = translatableText.multipleVersionsProducts.text; // Search v2
    }

    const formattedDate = dateModified
        ? new Date(dateModified).toLocaleDateString(navigator.language)
        : undefined;

    const renderAnswerAccepted = () => {
        if (answerAccepted) {
            return (
                <span
                    className="answer-accepted background-success"
                    data-testid="answer-accepted"
                >
                    <span className="check-icon">
                        <CheckIcon size="small" label="Answer accepted" />
                    </span>
                    <span>Answer Accepted</span>
                </span>
            );
        }
        return null;
    };

    const renderViews = () => {
        if (views) {
            return (
                <span
                    className="font-weight-590 text-light-gray"
                    data-testid="views"
                >
                    {views} views
                </span>
            );
        }
        return null;
    };

    const renderComments = () => {
        if (comments) {
            return (
                <span className="comments" data-testid="comments">
                    <CommentIcon size="small" label="Comments" />
                    <span>{comments}</span>
                </span>
            );
        }
        return null;
    };

    const renderMarkedUseful = () => {
        if (markedUseful) {
            return (
                <span
                    className="marked-useful font-weight-590 text-light-gray"
                    data-testid="marked-useful"
                >
                    <LikeIcon size="small" label="marked-useful" />
                    <span>{markedUseful} found this helpful</span>
                </span>
            );
        }
        return null;
    };

    const handleOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (MOUSE_EVENT_BUTTONS_TO_TRACK.has(e.button)) {
            analytics.trackEvent('search result', {
                // @ts-ignore
                event: 'clicked',
                eventComponent: 'search-result-card',
                actionSubjectId: 'link',
                category: 'search',
                action: 'clicked',
                label: 'search result card',
                position,
                searchTerm,
                linkUrl: href,
                contentId: id,
                timeToClick: (Date.now() - timeRendered) / 1000,
                clickButton: MouseEventButton[e.button],
                queryId,
                linkContentAri: getContentAri(id)
            });
        }
    };

    const handleContextMenu = (e: React.MouseEvent<HTMLAnchorElement>) => {
        /*
         * We are consciously choosing to disable the right click context menu so that we can reduce the possibility of
         * loosing analytics events when a user:
         * - Right clicks a search result.
         * - Selects "Open Link in New Tab" or similar.
         *
         * See: https://hello.jira.atlassian.cloud/browse/CSTE-2430?focusedCommentId=13967701
         */
        e.preventDefault();
    };

    return (
        <AtlasLink
            className="search-result-card search-result-card__card-button-wrapper"
            data-testid="search-result-card"
            href={href}
            target="_blank"
            text={title}
            onClick={handleOnClick}
            onAuxClick={handleOnClick}
            onContextMenu={handleContextMenu}
        >
            <div
                className={`search-result-card__card-container${
                    isSacSearch2Enabled ? '-v2' : ''
                }`}
            >
                <div className="left-section">
                    <Breadcrumb breadcrumbs={breadcrumbs} />
                    <div className="card-content text-gray">
                        <TitleSection
                            ticketType={ticketType}
                            title={title}
                            {...(isSearchTermHighlightingEnabled
                                ? {}
                                : { searchTerm })}
                            iconUrl={productIconUrl}
                            isSacSearch2Enabled={isSacSearch2Enabled}
                            isSearchTermHighlightingEnabled={
                                isSearchTermHighlightingEnabled
                            }
                        />
                        <p
                            className="card-description"
                            data-testid="search-result-card__description"
                        >
                            {isSearchTermHighlightingEnabled
                                ? highlightText(description)
                                : highlightSearchTerm(searchTerm, description)}
                        </p>
                        {formattedDate && (
                            <p
                                className="last-modified-date font-weight-590 text-light-gray"
                                data-testid="datemodified"
                            >
                                Last modified on {formattedDate}
                            </p>
                        )}
                        {isFooterVisible && (
                            <div
                                className="card-footer"
                                data-testid="card-footer"
                            >
                                {renderAnswerAccepted()}
                                {renderViews()}
                                {renderComments()}
                                {renderMarkedUseful()}
                            </div>
                        )}
                        {isSacSearch2Enabled && (
                            <small
                                className="card-url text-light-gray font-weight-400"
                                data-testid="card-url"
                            >
                                {href}
                            </small>
                        )}
                    </div>
                </div>
                <div className="right-section text-gray">
                    {isSacSearch2Enabled ? (
                        <div className="search-result-card__lozenge-container">
                            <span
                                className="search-result-card__lozenge"
                                data-testid="search-result-card__lozenge"
                            >
                                {lozengeText}
                            </span>
                        </div>
                    ) : (
                        <>
                            <span
                                className="deployment font-weight-600"
                                data-testid="deployment"
                            >
                                {deployment?.replace(/_/g, ' ')}
                            </span>
                            {textForVersion ? (
                                <span
                                    className="font-weight-400 version-text"
                                    data-testid="product-version-text"
                                >
                                    {textForVersion}
                                </span>
                            ) : null}
                        </>
                    )}
                </div>
            </div>
        </AtlasLink>
    );
};
