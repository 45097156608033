import React from 'react';
import { AtlasLink } from '../atlas-link';
import './resource-card.less';
import ArrowRightIcon from '@atlaskit/icon/glyph/arrow-right';
import analytics from '../../utils/analytics';
import { AnalyticsMetadata } from '../../utils/analytics/types';

interface ResourceCardProps {
    image: string;
    title: string;
    description: string;
    link: string;
    buttonText: string;
    analyticsMetadata: AnalyticsMetadata;
}

export const ResourceCard = ({
    image,
    title,
    description,
    link,
    buttonText,
    analyticsMetadata
}: ResourceCardProps) => {
    return (
        <li className="resource-card" data-testid="resource-card">
            <div className="resource-card__content">
                <div className="resource-card__content--image-container">
                    <img
                        src={image}
                        alt={title}
                        className="resource-card__content--image-container--img"
                        data-testid="resource-card-image"
                        height={80}
                        width={80}
                    />
                </div>
                <div className="card-body">
                    <h3 className="card-title-text">{title}</h3>
                    <p
                        className="card-description"
                        data-testid="card-description"
                    >
                        {description}
                    </p>
                </div>
                <AtlasLink
                    href={link}
                    className="resource-card__content--link"
                    data-testid="resource-card-link"
                    onClick={() => {
                        analytics.trackEvent('resource card', {
                            // @ts-ignore
                            event: 'clicked',
                            eventComponent: 'resource-card',
                            actionSubjectId: 'link',
                            category: 'resource',
                            action: 'clicked',
                            label: 'resource card',
                            resource: title,
                            resourceUrl: link,
                            ...analyticsMetadata
                        });
                    }}
                >
                    <p className="card-link-text">{buttonText}</p>
                    <span className="card-link-icon">
                        <ArrowRightIcon label="Arrow Right" size="small" />
                    </span>
                </AtlasLink>
            </div>
        </li>
    );
};
