import React, { useEffect, useState } from 'react';
import { bool, string } from 'prop-types';
import { CONFLUENCE_LOGO, CONTENTFUL_LOGO } from '../../utils/constants';
import { buildContentfulDeepLink } from '../../utils/build-contentful-deep-link';
import './author-tools.less';
import EditorTooltip from './editor-tooltip';
import { useAuth } from '../../providers/auth-providers';

const AuthorTools = (props) => {
    const { canEdit: userCanEdit } = useAuth();

    const [contentfulSpaceId, setContentfulSpaceId] = useState();
    useEffect(() => {
        setContentfulSpaceId(window.contentfulSpaceId);
    }, []);

    const openContentfulPage = () => {
        const { pageId } = props;
        window.open(
            buildContentfulDeepLink(contentfulSpaceId, pageId),
            '_blank'
        );
    };

    const openConfluencePage = () => {
        const { confluencePageId } = props;
        const confluenceUrl = `${window.CONFLUENCE_URL}/login.action?os_destination=%2Fpages%2Fviewpage.action%3FpageId%3D${confluencePageId}`;
        window.open(confluenceUrl, '_blank');
    };

    const { tooltipContent, confluencePageId, parentAligned } = props;
    const wrapperClassNames = [
        'author-tools__editor-icons',
        parentAligned ? 'parent-aligned' : ''
    ].join(' ');

    return userCanEdit ? (
        <div data-testid="author-tools-wrapper" className={wrapperClassNames}>
            {confluencePageId ? (
                <>
                    <EditorTooltip
                        tooltipContent={tooltipContent}
                        testId="confluence-header-icon"
                        altText="Confluence"
                        logoSrc={CONFLUENCE_LOGO}
                        openEditor={openConfluencePage}
                        ariaLabel="Open Confluence Editor page"
                    />
                    <EditorTooltip
                        tooltipContent={tooltipContent}
                        testId="contentful-header-icon"
                        altText="Contentful"
                        logoSrc={CONTENTFUL_LOGO}
                        openEditor={openContentfulPage}
                        ariaLabel="Open Contentful Editor page"
                    />
                </>
            ) : (
                <EditorTooltip
                    tooltipContent={tooltipContent}
                    testId="contentful-header-icon"
                    altText="Contentful"
                    logoSrc={CONTENTFUL_LOGO}
                    openEditor={openContentfulPage}
                    ariaLabel="Open Contentful Editor page"
                />
            )}
        </div>
    ) : null;
};

AuthorTools.propTypes = {
    pageId: string.isRequired,
    tooltipContent: string,
    confluencePageId: string,
    parentAligned: bool
};

export default AuthorTools;
