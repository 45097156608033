import React, { useContext } from 'react';
import { translatableText } from '../../utils/translatable-text';
//@ts-ignore
import { CardGroup, Card } from '../card';
import CurrentContextIdContext from '../../contexts/current-context-id-context';
import camelCase from 'lodash/camelCase';
import { AtlasLink } from '../atlas-link';
import { hardcodedAppAndThemeReferences } from './hardcoded-refs';
import { getHardcodedReferences } from './get-hardcoded-refs';

interface AppAndThemeCards {
    title: string;
    url: string;
    description: string;
    id: string;
}

const MAX_FULL_CARDS = 5;
// eslint-disable-next-line complexity
export const addStaticCards = (
    relatedLinks: AppAndThemeCards[] | undefined,
    contextId: string
) => {
    const { developersLink, trainingLink, marketplaceAppLink } =
        hardcodedAppAndThemeReferences;

    if (!relatedLinks) {
        relatedLinks = [];
    }
    let relatedReferences = [...relatedLinks];

    const hardcodedReferences = getHardcodedReferences(contextId);
    relatedReferences = hardcodedReferences.length
        ? hardcodedReferences
        : relatedReferences;

    relatedReferences = [
        ...relatedReferences,
        marketplaceAppLink,
        developersLink,
        trainingLink
    ];

    return relatedReferences;
};

export const HelpfulTopics = ({
    relatedAppReferences
}: {
    relatedAppReferences: AppAndThemeCards[] | undefined;
}) => {
    const contextId = useContext(CurrentContextIdContext);
    const linksToRender = addStaticCards(relatedAppReferences, contextId);
    const showCards = linksToRender.length <= MAX_FULL_CARDS;

    return (
        <div className={'helpful-topics__container'}>
            <h3 className="ternary-heading helpful-topics__title">
                {translatableText.helpfulTopics.text}
            </h3>
            {showCards && (
                <CardGroup className={'documentation-dropdown'}>
                    {linksToRender.map(({ title, url, description, id }) => (
                        <Card
                            title={title}
                            buttonLink={url}
                            className={'documentation-card'}
                            description={description}
                            key={id}
                            type={'full'}
                        />
                    ))}
                </CardGroup>
            )}
            {!showCards && (
                <div className="dropdown__card-styling">
                    <ul className="nav-links__list single-line">
                        {linksToRender.map(({ title, url, id }) => (
                            <li
                                data-testid="dropdown_item"
                                className="nav-links__item"
                                key={id}
                            >
                                <AtlasLink
                                    href={url}
                                    text={title}
                                    dataName={camelCase(title)}
                                >
                                    <span>{title}</span>
                                </AtlasLink>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};
