export const loaderQuery = `query fetchReleaseNotes($first: Int!) {
    content: releaseNotes(first: $first, publishedOnly: true) {
        edges {
            node {
                releaseNoteId
                url
                releaseNoteTitle: title
                featureRolloutDate
                description

                relatedContexts {
                    ... on ContentPlatformContextProduct {
                        type:__typename
                        title
                        name: productName
                        icon {
                            url
                            description
                        }
                    }
                    ... on ContentPlatformContextApp {
                        type:__typename
                        title
                        name: appName
                        icon {
                            url
                            description
                        }
                    }
                }
                changeType {
                    label
                    icon {
                        url
                        title
                    }
                }

                changeStatus {
                    label
                }
            }
        }
        pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
        }
    }
}`;
