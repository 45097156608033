import React, { Component } from 'react';
import Spinner from '@atlaskit/spinner';
import './remote-react-app.less';
import Logger from '../../../logger';
import isFunction from 'lodash/isFunction';
import { forEach } from 'lodash';

/**
 * Used to filter which files should be loaded, e.g. by specifically defining the files or by providing a function to filter files.
 */
type FilesFilter = ((fileKey: string) => boolean) | string[];

/**
 * The Manifest's mapping from file key to the path of the actual file.
 */
export interface ManifestFileMapping {
    [key: string]: string;
}

export interface RemoteReactAppProps {
    /**
     * The ID of the div that will be placed onto the page that the React App will use to embed the app.
     */
    appTargetId: string;

    /**
     * Either the URL of the manifest file or a function to get the URL.
     *
     * <p>The function is used when the ability to get the manifest URL is not able to be done until the component is mounted, for example if you
     * are dependent on a value being present on the <pre>window</pre> element.
     */
    manifestUrlProvider: () => string | string;

    /**
     * Optional parameter that is used to get the map of file key to file location in the manifest.
     *
     * <p>This is necessary as some manifests have the files under the "files" key.
     *
     * @param manifestContent content of the manifest file
     * @return the mapping of file keys to file locations
     */
    parseFilesFromManifestFile?: (manifestContent: any) => ManifestFileMapping;

    /**
     * Field to determine what files should be embedded by either providing a list of file keys or a function to filter the file keys.
     */
    filesFilter: FilesFilter;

    /**
     * This method is executed in the 'componentDidMount()' method of component. This method can be used
     * for initializing some fields.
     */
    executeAfterMount?: () => void;

    /**
     * Determines whether a loading spinner should be displayed while it waits for the application to render.
     *
     * <p>If this is true the react app needs to make sure to put the content in the appTargetId div otherwise this spinner
     * will not be removed.
     */
    includeLoadingSpinner?: boolean;

    /**
     * This method is executed after provided scripts and styles were loaded to DOM.
     */
    onScriptLoad?: () => void;
}

/**
 * Gets the full base path for an asset.
 *
 * <p>This is useful for when the manifest file path is relative and therefore needs to be added to the baseUrl of the
 * manifest file.  If the base URL of the manifest was not included it will just assume that the file path is the full
 * path.
 *
 * @param manifestUrl details about the manifest
 * @param assetPathOrUrl path or full URL to the asset
 * @returns string full path to manifest file
 */
const getAssetUrl = (manifestUrl: string, assetPathOrUrl: string): string => {
    if (assetPathOrUrl.indexOf('http') === 0) {
        return assetPathOrUrl;
    }

    const url = new URL(manifestUrl);
    return url.protocol + '//' + url.hostname + assetPathOrUrl;
};

/**
 * Parse the manifest file to get the mapping of file key to file location.
 *
 * @param manifestFileContents       the contents of the file
 * @param parseFilesFromManifestFile the optional function to parse the file
 * @return the mapping of file key to file location
 */
const parseFileContents = (
    manifestFileContents: any,
    parseFilesFromManifestFile?: (manifestContent: any) => ManifestFileMapping
): ManifestFileMapping => {
    if (parseFilesFromManifestFile) {
        return parseFilesFromManifestFile(manifestFileContents);
    }

    return manifestFileContents;
};

/**
 * Determine whether the provided file with key should be loaded.
 *
 * @param filesFilter  the filter for determining which files to inject
 * @param fileKey the key of the file and whether to load it
 * @return whether the file should be embedded
 */
const filterFile = (filesFilter: FilesFilter, fileKey: string): boolean => {
    if (typeof filesFilter === 'function') {
        return filesFilter(fileKey);
    }

    return filesFilter.includes(fileKey);
};

/**
 * Add the provided script onto the DOM.
 *
 * @param scriptPath the full path to the script
 */
const addScriptToDom = (scriptPath: string, type = 'text/javascript') => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.setAttribute('type', type);
        script.setAttribute('src', scriptPath);

        // @ts-ignore
        script.addEventListener('load', () => resolve());
        script.addEventListener('error', (err) => reject(err));

        const head = document.querySelectorAll('head')[0];
        head.append(script);
    });
};

/**
 * Add the provided stylesheet onto the DOM.
 *
 * @param stylePath the full path to the stylesheet
 */
const addStyleToDom = (stylePath: string) => {
    return new Promise((resolve, reject) => {
        const styleSheet = document.createElement('link');
        styleSheet.setAttribute('rel', 'stylesheet');
        styleSheet.setAttribute('href', stylePath);
        // @ts-ignore
        styleSheet.addEventListener('load', () => resolve());
        styleSheet.addEventListener('error', (err) => reject(err));

        const head = document.querySelectorAll('head')[0];
        head.append(styleSheet);
    });
};

const getAssetPromiseUrls = (
    files: ManifestFileMapping,
    filesFilter: FilesFilter,
    actualManifestUrl: string
) => {
    const promises: any[] = [];

    for (const key of Object.keys(files || {})) {
        if (filterFile(filesFilter, key)) {
            const fileList = files[key];
            if (!Array.isArray(fileList)) {
                const assetPath = getAssetUrl(actualManifestUrl, fileList);
                promises.push(getAssetFromFile(key, assetPath));
            } else {
                getAssetFromFilelist(fileList, actualManifestUrl, promises);
            }
        }
    }
    return promises;
};

const getAssetFromFilelist = (
    fileList: string[],
    actualManifestUrl: string,
    promises: any[]
) => {
    forEach(fileList, (file) => {
        const assetPath = getAssetUrl(actualManifestUrl, '/assets/' + file);
        promises.push(getAssetFromFile(file, assetPath, 'module'));
    });
};

const getAssetFromFile = (
    file: string,
    assetPath: string,
    type = 'text/javascript'
): any => {
    if (file.endsWith('.js')) {
        return addScriptToDom(assetPath, type);
    } else if (file.endsWith('css')) {
        return addStyleToDom(assetPath);
    }
};

/**
 * Component that handles the loading and displaying of a React application from a remote resource.
 *
 * <p>This will use a manifest file to determine which scripts and stylesheets will need to be placed into the DOM to run this application.
 *
 * <p>It would be nice to include the scripts and styles in the main render function but when it is included the script tags are not run and it seems
 * to be frown upon to do this. See https://stackoverflow.com/questions/34424845/adding-script-tag-to-react-jsx.
 */
export default class RemoteReactApp extends Component<RemoteReactAppProps> {
    async componentDidMount() {
        const {
            manifestUrlProvider,
            parseFilesFromManifestFile,
            filesFilter,
            executeAfterMount,
            onScriptLoad
        } = this.props;

        if (isFunction(executeAfterMount)) {
            executeAfterMount();
        }

        const actualManifestUrl =
            typeof manifestUrlProvider === 'function'
                ? manifestUrlProvider()
                : manifestUrlProvider;

        const manifestFileContents: any = await fetch(actualManifestUrl).then(
            (response) => response.json()
        );

        const files: ManifestFileMapping = parseFileContents(
            manifestFileContents,
            parseFilesFromManifestFile
        );

        const promises = getAssetPromiseUrls(
            files,
            filesFilter,
            actualManifestUrl
        );

        if (isFunction(onScriptLoad)) {
            Promise.all(promises)
                .then(() => onScriptLoad())
                .catch((error) =>
                    Logger.error({ error }, 'error loading external files')
                );
        }
    }

    render() {
        const { appTargetId, includeLoadingSpinner } = this.props;

        return (
            <div id={appTargetId}>
                {includeLoadingSpinner && (
                    <div className="remote-react-app-loading">
                        <Spinner size={'xlarge'} />
                    </div>
                )}
            </div>
        );
    }
}
