import React, { useState } from 'react';
import Tabs from '@atlaskit/tabs';
import analytics from '../../../utils/analytics/analytics';

export interface ContentTypeTab {
    key: string;
    name: string;
    label: string;
    testId: string;
    docCount: number;
}

interface ContentTypeTabsProps {
    tabs: ContentTypeTab[];
    onSelect: (selected: ContentTypeTab) => string;
}

export const DEFAULT_CONTENT_TYPE_TAB: ContentTypeTab = {
    key: 'all',
    name: 'All',
    label: 'All (0)',
    testId: 'all-tab',
    docCount: 0
};

export const ContentTypeTabs = ({ tabs, onSelect }: ContentTypeTabsProps) => {
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    return (
        <Tabs
            tabs={tabs}
            selected={selectedIndex}
            onSelect={(selectedTab, index) => {
                const selected = selectedTab as ContentTypeTab;
                if (selectedIndex === index) {
                    return;
                }
                setSelectedIndex(index);
                const previousValue = onSelect(selected);
                analytics.trackEvent('content type tab', {
                    //@ts-ignore
                    event: 'clicked',
                    eventComponent: 'tab',
                    actionSubjectId: 'contentTypeTab',
                    category: 'filter',
                    action: 'click',
                    label: 'content type tab',
                    currentSelection: previousValue,
                    newSelection: selected.key,
                    docCount: selected.docCount
                });
            }}
            testId="content-type-tabs"
        />
    );
};
