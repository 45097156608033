import type { Block, Inline } from '@contentful/rich-text-types';
import type { GqlKbArticleRichTextEntry } from '../../../../content/types/content/kb-article';
import type { GqlCalloutRichTextHyperlinkEntry } from '../../../../content/types/content/component-callout';

type GqlKbRichTextEntry =
    | GqlKbArticleRichTextEntry
    | GqlCalloutRichTextHyperlinkEntry;

const getEntryFromNode = <T extends GqlKbRichTextEntry>(
    node: Block | Inline,
    entryMap: Map<string, T>
) => {
    const embeddedId = node.data.target.sys.id;
    const entry = entryMap.get(embeddedId);

    if (!entry) {
        throw new Error(
            `No corresponding entry was found for the ID ${embeddedId} included in the rich text node.`
        );
    }

    return entry;
};

export default getEntryFromNode;
