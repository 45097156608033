import { useState, useEffect } from 'react';
import { SupportFormUrls } from '../components/contact';
import { translatableText } from '../utils/translatable-text';
import { getLoginUrl } from '../utils/get-auth-url';
import { getRelativeLocalizedContactPath } from '../utils/get-relative-localized-contact-path';

interface ContactLink {
    authenticated: HrefElement;
    needsAuthentication: HrefElement;
}

interface HrefElement {
    href: string;
    text: string;
}

export const useSupportFormUrls = (customSupportFormURLs?: SupportFormUrls) => {
    const [supportFormLinks, setSupportFormLinks] = useState<ContactLink>({
        needsAuthentication: {
            text: '',
            href: ''
        },
        authenticated: {
            text: translatableText.contactSupport.text,
            href: '/contact'
        }
    });

    useEffect(() => {
        const updateSupportFormUrls = async function () {
            const relativeContactURL = getRelativeLocalizedContactPath();

            setSupportFormLinks({
                needsAuthentication: {
                    text: translatableText.logInToContactSupport.text,
                    href: getLoginUrl(
                        customSupportFormURLs &&
                            customSupportFormURLs.authenticated
                            ? customSupportFormURLs.authenticated
                            : `${window.location.origin}${relativeContactURL}`
                    )
                },
                authenticated: {
                    text: translatableText.contactSupport.text,
                    href:
                        customSupportFormURLs &&
                        customSupportFormURLs.authenticated
                            ? customSupportFormURLs.authenticated
                            : relativeContactURL
                }
            });
        };

        updateSupportFormUrls();
    }, [customSupportFormURLs]);

    return supportFormLinks;
};
