import React from 'react';
import CspRequestsAppPage from './csp-app-page';

export default function render(entry, pageTree, featureFlags) {
    return (
        <CspRequestsAppPage
            entry={entry}
            pageTree={pageTree}
            featureFlags={featureFlags}
        />
    );
}
