import React from 'react';

import { AtlasLink } from '../atlas-link';
import analytics from '../../utils/analytics';
import './products-dropdown-card.less';

const ICON_SIZE = 32;

interface ProductsDropdownCardProps {
    title: string;
    icon: string;
    url: string;
    id?: string;
    analyticsMetadata: {
        parentComponent: string;
        position: number;
        [key: string]: any;
    };
}

export const ProductsDropdownCard = ({
    title,
    icon,
    url,
    id,
    analyticsMetadata
}: ProductsDropdownCardProps) => {
    return (
        <li className="products-dropdown-card__container">
            <AtlasLink
                href={url}
                className="products-dropdown-card"
                data-testid={`products-dropdown-card-${id}`}
                onClick={() => {
                    analytics.trackEvent('products dropdown card', {
                        // @ts-ignore
                        event: 'clicked',
                        eventComponent: 'products-dropdown-card',
                        actionSubjectId: 'link',
                        category: 'product',
                        action: 'clicked',
                        label: 'products dropdown card',
                        product: title,
                        productUrl: url,
                        ...analyticsMetadata
                    });
                }}
            >
                <div className="products-dropdown-card__icon-image__container">
                    <img
                        src={icon}
                        alt=""
                        height={ICON_SIZE}
                        width={ICON_SIZE}
                        className="products-dropdown-card__icon-image"
                        data-testid={`products-dropdown-card-icon-image-${id}`}
                    />
                </div>
                <div className="products-dropdown-card__product-title__container">
                    <h4>
                        <span className="products-dropdown-card__product-title">
                            {title}
                        </span>
                    </h4>
                </div>
            </AtlasLink>
        </li>
    );
};
