import Joi from '@hapi/joi';
import { RELEASE_NOTE } from '../../../contentful/content-types';

export const schema = Joi.object().keys({
    releaseNoteId: Joi.string(),
    featureRolloutDate: Joi.string().allow(null),
    type: Joi.string().default(RELEASE_NOTE),
    lastPublished: Joi.string().isoDate(),
    releaseNoteTitle: Joi.string(),
    currentStatus: Joi.string(),
    statusDescription: Joi.string(),
    url: Joi.string(),
    relatedContexts: Joi.array().items(
        Joi.object().keys({
            type: Joi.string(),
            title: Joi.string(),
            name: Joi.string(),
            icon: [
                Joi.object().keys({
                    url: Joi.string(),
                    description: Joi.string().allow(null)
                }),
                null
            ]
        })
    ),
    image: [
        Joi.object().keys({
            url: Joi.string(),
            altTag: Joi.string()
        }),
        null
    ],
    changeTypeLabel: Joi.string(),
    changeTypeIcon: [
        Joi.object().keys({
            url: Joi.string(),
            title: Joi.string()
        })
    ],
    keyChanges: Joi.any(),
    description: Joi.any(),
    reasonForChange: Joi.any(),
    benefitsList: Joi.any(),
    prepareForChange: Joi.any()
});
