import { safeURLPath } from './safe-url-path';
import slugify from 'slugify';
import OptionsError from './options-error';
import Logger from '../../logger';

export interface ReleaseNoteOptions {
    basePath: string;
    slug: string;
    anchor?: string;
}

export const getReleaseNotePath = safeURLPath((options: ReleaseNoteOptions) => {
    const { slug } = options;

    if (typeof slug !== 'string') {
        Logger.info('slug option is required and must be a string');
        throw new OptionsError('slug option is required and must be a string');
    }

    return [
        // basePath,
        'release-notes',
        slugify(slug, {
            remove: /[^\w -]/g
        })
    ];
});
