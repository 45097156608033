const getLanguageFromMetaTag = () => {
    const metaTagElement = document.querySelector(
        'meta[itemprop="inLanguage"]'
    );

    if (metaTagElement) {
        return metaTagElement.getAttribute('content');
    }

    return null;
};

export default getLanguageFromMetaTag;
