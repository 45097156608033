import React, { createRef } from 'react';
import Footer from '../../components/footer';
// @ts-ignore
import Breadcrumbs from '../../components/breadcrumbs';
// @ts-ignore
import DeploymentSelector from '../../components/deployment-selector';
// @ts-ignore
import { Header, HeaderProps, Resources } from '../../components/header';
// @ts-ignore
import {
    CardGroup,
    CardWithCEM,
    CardsWithTitleAdjustment,
    CardsWithTitleAdjustmentFuncParams
    // @ts-ignore
} from '../../components/card';
import './collection.less';
import {
    PageTree,
    PageTreeList,
    PageTreeItem
} from '../../components/page-tree';

// import CardAdjust from '../../components/card-adjust/index.js';
// @ts-ignore
import AuthorTools from '../../components/author-tools';
import { COLLECTION, TOPIC } from '../../../contentful/content-types';
import { translatableText } from '../../utils/translatable-text';
import { CollectionTreeType } from '../../components/sidebar/sidebar.types';
import Collection, {
    ChildReference
} from '../../../content/types/content/collection';
import camelCase from 'lodash/camelCase';
import {
    getSupportTitle,
    isProductContext,
    isThemeContext
} from '../../../content/context-utils';
import NoticeHub from '../../components/notice-hub';

interface CollectionEntry extends Collection {
    header: HeaderProps;
    preview: boolean;
    resources: Resources;
}

interface SACCollectionProps {
    entry: CollectionEntry;
    pageTree: CollectionTreeType[];
}

// eslint-disable-next-line complexity
const SACCollectionPage = ({
    entry: {
        title,
        header,
        childrenReferences,
        id,
        subhead,
        preview,
        context,
        versions,
        resources,
        collections,
        relatedAppReferences
    },
    pageTree
}: SACCollectionProps) => {
    const subContentRef = createRef<HTMLDivElement>();

    const renderCards = (cards: ChildReference[]) => {
        return (
            <CardsWithTitleAdjustment collection={cards}>
                {({
                    item,
                    measureRef,
                    titleHeight
                }: CardsWithTitleAdjustmentFuncParams) => (
                    <CardWithCEM
                        tooltipContent="Edit this card"
                        key={item.id}
                        id={item.id}
                        title={item.title}
                        description={item.description}
                        buttonText={
                            item.pageType === TOPIC
                                ? translatableText.viewTopic.text
                                : translatableText.viewTopics.text
                        }
                        buttonLink={item.url}
                        titleHeight={titleHeight}
                        measureRef={measureRef}
                        analytics={camelCase(item.title)}
                    />
                )}
            </CardsWithTitleAdjustment>
        );
    };

    return (
        <>
            <AuthorTools pageId={id} tooltipContent="Edit this Collection" />
            <div className="anthology" data-testid="page-template">
                <div className="content-wrapper">
                    <Header
                        title={getSupportTitle(context)}
                        id={header.id}
                        cloudProducts={header.cloudProducts}
                        serverProducts={header.serverProducts}
                        additionalLinks={header.additionalLinks}
                        showProductDropdownAsWaffle={true}
                        showPreviewBanner={preview}
                        pageId={id}
                        resources={resources}
                        supportHomePath={
                            isProductContext(context)
                                ? context.deploymentUrls[
                                      context.deployment.toLowerCase()
                                  ] || context.deploymentUrls.default
                                : '/'
                        }
                        collections={collections}
                        shouldRenderDocumentation
                        logo={header.logo}
                        atlassianSupportLogo={header.atlassianSupportLogo}
                        atlassianLogo={header.atlassianLogo}
                        relatedAppReferences={relatedAppReferences}
                        supportFormUrls={{
                            authenticated: isProductContext(context)
                                ? context.customSupportFormAuthenticated
                                : null,
                            unauthenticated: isProductContext(context)
                                ? context.customSupportFormUnauthenticated
                                : null
                        }}
                    />

                    <div className="main-content-container flex-container margin-top-medium">
                        <PageTree
                            context={context}
                            subContentRef={subContentRef}
                        >
                            <PageTreeList isRoot>
                                {pageTree.map(
                                    ({ title, slug, id, childList }) => (
                                        <PageTreeItem
                                            key={id}
                                            title={title}
                                            slug={slug}
                                            id={id}
                                            childList={childList}
                                        />
                                    )
                                )}
                            </PageTreeList>
                        </PageTree>
                        <main>
                            <div
                                data-testid="collection__header"
                                className="anthology__sub-header"
                                ref={subContentRef}
                            >
                                <Breadcrumbs
                                    pageTree={pageTree}
                                    entryId={id}
                                    context={context}
                                    type={COLLECTION}
                                />
                                {!isThemeContext(context) && (
                                    <DeploymentSelector
                                        selected={context.deployment}
                                        deployments={context.deploymentUrls}
                                        versions={versions}
                                    />
                                )}
                            </div>

                            {context.notice && (
                                <NoticeHub notice={context.notice} />
                            )}

                            <div className="anthology__hero margin-top-medium">
                                <div className="anthology__hero--primary">
                                    <h1>{title}</h1>
                                </div>
                                {subhead && (
                                    <div className="anthology__hero--secondary">
                                        <p aria-label="subhead">{subhead}</p>
                                    </div>
                                )}
                            </div>
                            <CardGroup>
                                {renderCards(childrenReferences)}
                            </CardGroup>
                        </main>
                    </div>
                </div>
                <Footer
                    logo={header.atlassianLogo.url}
                    className={'margin-top-large'}
                />
            </div>
        </>
    );
};

export default SACCollectionPage;
