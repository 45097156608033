import React from 'react';
import kebabCase from 'lodash/kebabCase';
import './additional-help.less';
import { translatableText } from '../../utils/translatable-text';
import track from '../atlas-link/track';

interface AdditionalHelpArgs {
    productNames: string[];
    deployments: string[];
}

const platformMap: Record<string, string[]> = {
    'Data Center and Server': ['Data Center', 'Server']
};

const AdditionalHelp: React.FunctionComponent<AdditionalHelpArgs> = (
    props: AdditionalHelpArgs
) => {
    const { productNames, deployments } = props;

    const mappedDeployments = deployments.flatMap(
        (deployment) => platformMap[deployment] ?? [deployment]
    );

    const productAndDeploymentTags = [
        ...productNames.map(kebabCase),
        ...mappedDeployments
    ];
    const tags = [...new Set(productAndDeploymentTags)].join(',');

    const href =
        'https://community.atlassian.com/t5/custom/page/page-id/create-post-step-1' +
        (tags ? `?add-tags=${tags}` : '');

    const handleClick = () =>
        track(
            href,
            translatableText.askTheCommunity.text,
            'askTheCommunityButton'
        );

    return (
        <div data-testid="additional_help" className="additional-help">
            <h2 className="sac-h3 title">
                {translatableText.stillNeedHelp.text}
            </h2>
            <div className="subtext">{translatableText.hereForYou.text}</div>
            <div className="button">
                <a
                    href={href}
                    rel="nofollow"
                    className="link"
                    onClick={handleClick}
                >
                    {translatableText.askTheCommunity.text}
                </a>
            </div>
        </div>
    );
};

export default AdditionalHelp;
