import React, { Component } from 'react';
import { Header, HeaderType } from '../../components/header';
import Footer from '../../components/footer';
import { bool, shape, string } from 'prop-types';
import RemoteReactApp from '../../components/remote-react-app';

export default class StatuspageContactPage extends Component {
    render() {
        const {
            entry: { header, id, preview, headerLogo }
        } = this.props;

        const manifestUrl = () =>
            `${window.CONTACT_FORMS_MANIFEST}?v=${new Date().getTime()}`;

        return (
            <div className="contact flex-column-page">
                <div className="content-wrapper">
                    <Header
                        atlassianSupportLogo={header.atlassianSupportLogo}
                        hideContactButton={true}
                        logo={headerLogo}
                        products={null}
                        resources={null}
                        id={header.id}
                        showPreviewBanner={preview}
                        additionalLinks={header.additionalLinks}
                        pageId={id}
                    />
                    <div className="main-content-container margin-top-large">
                        <div className="statuspage__contact__forms">
                            <RemoteReactApp
                                appTargetId="support-form-app"
                                manifestUrlProvider={manifestUrl}
                                filesFilter={[
                                    'statuspage-index.js',
                                    'statuspage-index.css'
                                ]}
                                includeLoadingSpinner={true}
                            />
                        </div>
                    </div>
                </div>
                <Footer
                    logo={header.atlassianLogo.url}
                    className={'margin-top-large'}
                />
            </div>
        );
    }
}

StatuspageContactPage.propTypes = {
    entry: shape({
        headerLogo: shape({
            url: string
        }),
        header: HeaderType,
        id: string.isRequired,
        preview: bool
    })
};
