import React, { PropsWithChildren } from 'react';

import './products-dropdown.less';
// @ts-ignore
import { Product } from '../product';
import { ProductsDropdownCard } from '../products-dropdown-card';
import { SubnavWithSidebarTabs, TabData } from '../subnav-with-sidebar-tabs';
import analytics from '../../utils/analytics/analytics';
import { AnalyticsMetadata } from '../../utils/analytics/types';

interface ProductsDropdownProps {
    className: string;
    cloudProducts: Product[];
    serverProducts: Product[];
    isOpen: boolean;
    analyticsMetadata: AnalyticsMetadata;
    isRedesignEnabled?: boolean;
}

const ProductsGrid = ({ children }: PropsWithChildren<{}>) => {
    return <ul className="products-grid">{children}</ul>;
};

const renderProductCard = (product: Product, index: number) => {
    const {
        productNameReference,
        icon,
        iconAlternate,
        id,
        deploymentUrls,
        deployment
    } = product;

    return (
        <ProductsDropdownCard
            title={productNameReference.productName}
            icon={iconAlternate?.url || icon.url}
            key={id}
            id={id}
            url={
                deployment === 'Cloud'
                    ? deploymentUrls.cloud
                    : deploymentUrls.server
            }
            analyticsMetadata={{
                parentComponent: 'products-dropdown',
                position: index + 1
            }}
        />
    );
};

const handleTabSelect = (
    tabName: string,
    analyticsMetadata: AnalyticsMetadata,
    isRedesignEnabled: boolean
    // eslint-disable-next-line max-params
) => {
    analytics.trackEvent('dropdownTab', {
        //@ts-ignore
        event: 'selected',
        eventComponent: 'productsDropdownTab',
        category: 'products dropdown cloud and data center tabs',
        actionSubjectId: `products dropdown ${tabName} tab`,
        action: 'selected',
        tabName: tabName,
        isRedesignEnabled,
        ...analyticsMetadata
    });
};

export const ProductsDropdown = ({
    className,
    isOpen,
    cloudProducts,
    serverProducts,
    analyticsMetadata,
    isRedesignEnabled
}: ProductsDropdownProps) => {
    const tabs: TabData[] = [
        {
            id: 'cloud',
            title: 'Cloud',
            content: (
                <ProductsGrid>
                    {cloudProducts.map((product: Product, index: number) =>
                        renderProductCard(product, index)
                    )}
                </ProductsGrid>
            )
        },
        {
            id: 'data_center',
            title: 'Data Center',
            content: (
                <ProductsGrid>
                    {serverProducts.map((product: Product, index: number) =>
                        renderProductCard(product, index)
                    )}
                </ProductsGrid>
            )
        }
    ];

    return (
        <div
            className={`${className}${isOpen ? '' : ' hidden'}`}
            data-testid="products-dropdown"
        >
            <SubnavWithSidebarTabs
                tabs={tabs}
                onTabSelect={(tab: number) => {
                    handleTabSelect(
                        tabs[tab].title,
                        analyticsMetadata,
                        isRedesignEnabled ?? false
                    );
                }}
            />
        </div>
    );
};
