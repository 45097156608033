import React from 'react';

export const TextSection = ({
    text,
    testId,
    title,
    atTop = false
}: {
    text: string;
    testId: string;
    title: string;
    atTop?: boolean;
}) => {
    return (
        <div
            className={atTop ? '' : 'release-note__body-item'}
            data-testid={testId}
        >
            <h2 className="release-note__section-heading">{title}</h2>
            <div
                dangerouslySetInnerHTML={{
                    __html: text
                }}
            />
        </div>
    );
};
