import React from 'react';
import { AtlasLink } from '../atlas-link';
import { translatableText } from '../../utils/translatable-text';
import './footer.less';
// @ts-ignore
import CSMChatContainer from '../csm-chat-widget/csm-chat-container';

interface Props {
    className?: string;
    logo: string;
}

const Footer = (props: Props) => {
    return (
        <footer className={['footer', props.className].join(' ')}>
            <CSMChatContainer />
            <div className="footer__inner-container">
                <div className="footer__inner-content">
                    <a href="https://www.atlassian.com">
                        <img
                            src={props.logo}
                            alt="Atlassian Logo"
                            className="footer__icon"
                        />
                    </a>
                    <div className="footer__links">
                        <ul>
                            <li className="atl-policy-link-container">
                                <AtlasLink
                                    className="atl-policy-link atl-policy-link-text"
                                    dataName="footerLinkNoticeAtCollection"
                                    href=" https://www.atlassian.com/legal/privacy-policy#additional-disclosures-for-ca-residents"
                                    text={
                                        translatableText.noticeAtCollection.text
                                    }
                                />
                            </li>
                            <li>
                                <AtlasLink
                                    dataName="footerLinkPrivacyPolicy"
                                    href="https://www.atlassian.com/legal/privacy-policy"
                                    text={translatableText.privacyPolicy.text}
                                />
                            </li>
                            <li>
                                <AtlasLink
                                    dataName="footerLinkCustomerAgreement"
                                    href="https://www.atlassian.com/legal/cloud-terms-of-service"
                                    text={translatableText.termsOfUse.text}
                                />
                            </li>
                            <li>
                                <AtlasLink
                                    dataName="footerLinkSecurity"
                                    href="https://www.atlassian.com/security"
                                    text={translatableText.security.text}
                                />
                            </li>
                            <li>{new Date().getUTCFullYear()} Atlassian</li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
