import React from 'react';
import VersionListing from '../version-listing';
import type { Version } from '../version-listing/version-listing';
import type { GqlProductVersion } from '../../../../../content/types/content/product-version';

const productVersionToVersion = (
    productVersion: GqlProductVersion
): Version => {
    return {
        ...productVersion,
        id: productVersion.sys.id,
        name: `${productVersion.product.productName} ${productVersion.platform}`
    };
};

const ProductVersions = ({
    productVersions
}: {
    productVersions: GqlProductVersion[];
}) => {
    return (
        <VersionListing
            title={'Product' + (productVersions.length > 1 ? 's' : '')}
            versions={productVersions.map(productVersionToVersion)}
        />
    );
};

export default ProductVersions;
