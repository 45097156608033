import React from 'react';
import Button from '@atlaskit/button';
import { translatableText } from '../../../utils/translatable-text';
import analytics from '../../../utils/analytics';

interface ResetButtonProps {
    appearance: 'default' | 'subtle';
    analyticsMetadata?: {
        [key: string]: any;
    };
    onReset: () => boolean;
}

const ResetButton = ({
    appearance,
    analyticsMetadata = {},
    onReset
}: ResetButtonProps) => {
    return (
        <Button
            className="reset-button"
            appearance={appearance}
            onClick={() => {
                const hasReset = onReset();
                if (hasReset) {
                    analytics.trackEvent('reset button', {
                        // @ts-ignore
                        event: 'clicked',
                        eventComponent: 'resetButton',
                        actionSubjectId: 'resetButton',
                        category: 'reset',
                        action: 'clicked',
                        label: 'reset button',
                        ...analyticsMetadata
                    });
                }
            }}
        >
            {translatableText.resetButton.text}
        </Button>
    );
};

export default ResetButton;
