import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

const useViewportWidth = () => {
    const initialViewPortWidth =
        typeof window === 'undefined' ? undefined : window.innerWidth;
    const [viewportWidth, setViewportWidth] = useState(initialViewPortWidth);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }
        const handleWindowResize = () => setViewportWidth(window.innerWidth);
        window.addEventListener('resize', debounce(handleWindowResize, 100));
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { viewportWidth };
};

export default useViewportWidth;
