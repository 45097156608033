function indentStackTrace(stackTrace: string) {
    const indent = '    ';

    const newStackTrace = stackTrace
        .split('\n')
        .map((line: string) => `${indent}${line}`)
        .join('\n');

    return newStackTrace;
}

interface Context {
    [key: string]: any;
}

interface AppErrorArgs {
    message: string;
    context?: Context | Context[];
    previousError?: Error | AppError;
    previousErrors?: Error[] | AppError[];
}

// Based on https://gist.github.com/justmoon/15511f92e5216fa2624b

// TODO: Investigate how this would work in browser.
// Error.captureStackTrace might not always exist.
// How to add AppError to window object?
export class AppError extends Error {
    context: Context[];

    constructor({
        message,
        context,
        previousError,
        previousErrors
    }: AppErrorArgs) {
        super();

        this.validateInputs({ message, previousError, previousErrors });

        if (Error.captureStackTrace) {
            // Creates a .stack property on targetObject, which when accessed returns a string representing
            // the location in the code at which Error.captureStackTrace() was called.
            // https://nodejs.org/api/errors.html#errors_error_capturestacktrace_targetobject_constructoropt
            Error.captureStackTrace(this, this.constructor);
        }

        this.name = 'AppError';
        this.message = message;
        this.context = [];

        if (context && !Array.isArray(context)) {
            context.forErrorMessage = message;
        }

        if (context) {
            this.context = [context];
        }

        if (previousError) {
            this.handlePreviousError(previousError);
        }

        if (previousErrors?.length) {
            this.handlePreviousErrors(previousErrors);
        }
    }

    validateInputs({ message, previousError, previousErrors }: AppErrorArgs) {
        if (!message) {
            throw new Error('"message" is required by AppError');
        }

        if (typeof message !== 'string') {
            throw new TypeError('"message" is required to be a string');
        }

        if (previousError && previousErrors) {
            throw new Error(
                '"previousError" and "previousErrors" are mutually exclusive'
            );
        }
    }

    handlePreviousError(previousError: Error | AppError) {
        const { stack, message } = previousError;
        if (previousError instanceof AppError && previousError.context) {
            this.context = [...this.context, ...previousError.context];
        }

        if (stack) {
            this.stack = `${this.stack}\n\n${indentStackTrace(stack)}`;
        }

        if (message) {
            this.message = `${this.message} > ${message}`;
        }
    }

    handlePreviousErrors(previousErrors: Error[] | AppError[]) {
        const previousErrorMessages = [];
        for (const previousError of previousErrors) {
            if (previousError instanceof AppError && previousError?.context) {
                this.context = [...this.context, ...previousError.context];
            }

            if (previousError?.stack) {
                this.stack = `${this.stack}\n\n${indentStackTrace(
                    previousError.stack
                )}`;
            }

            if (previousError?.message) {
                previousErrorMessages.push(previousError.message);
            }
        }

        if (previousErrorMessages.length) {
            this.message = `${this.message} >> ${previousErrorMessages.join(
                ' + '
            )}`;
        }
    }
}
