import getLanguageFromMetaTag from '../../common/localization-utils';

export const getRelativeLocalizedContactPath = () => {
    const language = getLanguageFromMetaTag();
    let relativeContactPath = '/contact';

    // set relative url to JaSAC if the language is set to Japanese
    if (language === 'ja-JP') {
        relativeContactPath = '/ja/contact';
    }

    return relativeContactPath;
};
