import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
    fetchSearchResults,
    SuccessfulSearchResponse
} from './utils/fetch-search-results';
import { Deployment } from '../../types';
export interface SearchState {
    isLoading: boolean;
    searchTerm: string;
    searchResponse: SuccessfulSearchResponse;
    showPagination: boolean;
}

const initialState: SearchState = {
    isLoading: false,
    searchTerm: '',
    searchResponse: {
        queryId: '',
        results: [],
        facets: {
            contentType: {},
            platform: {
                [Deployment.CLOUD]: { count: 0, name: '', products: {} },
                [Deployment.DATA_CENTER]: { count: 0, name: '', products: {} }
            }
        },
        pagination: {
            from: 0,
            size: 10,
            total: 0
        },
        metadata: {
            query: {
                originalQuery: '',
                rewrittenQuery: '',
                searchedQuery: ''
            },
            sort: {
                by: ''
            },
            source: ''
        }
    },
    showPagination: false
};

const shouldShowPagination = (state: SearchState) => {
    return state.searchResponse.pagination
        ? state.searchResponse.pagination?.total >
              state.searchResponse.pagination?.size
        : false;
};

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        updateSearchTerm: (state, action: PayloadAction<string>) => {
            state.searchTerm = action.payload;
        },
        setShowPagination: (state) => {
            state.showPagination = shouldShowPagination(state);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSearchResults.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchSearchResults.fulfilled, (state, action) => {
                // @ts-ignore
                state.searchResponse = action.payload || {
                    queryId: '',
                    results: [],
                    facets: [],
                    pagination: {
                        from: 0,
                        size: 10,
                        total: 0
                    }
                };
                state.showPagination = shouldShowPagination(state);
                state.isLoading = false;
            })
            .addCase(fetchSearchResults.rejected, (state) => {
                state.isLoading = false;
            });
    }
});

export const { updateSearchTerm, setShowPagination } = searchSlice.actions;

export default searchSlice.reducer;
