import React from 'react';
import './platform-tabs.less';
import { Tabs } from './tabs';
import { Context } from '../../../content';
import { AnalyticsMetadata } from '../../utils/analytics/types';
import { ProductCardsGroup } from '../product-card';
import analytics from '../../utils/analytics';
import { camelCase } from 'lodash';

interface PlatformTabsProps {
    cloudProducts: Context[];
    serverProducts: Context[];
    details: string;
    analyticsMetadata: AnalyticsMetadata;
    featureFlags: { isRedesignEnabled?: boolean };
}

const handleTabSelect = (details: string, tab: string) => {
    const tabName = tab === 'Data Center' ? 'Server' : tab;
    analytics.trackEvent(camelCase(tabName + ' tab'), {
        //@ts-ignore
        event: 'clicked',
        eventComponent: 'tab',
        category: 'products cloud and server tabs',
        actionSubjectId: camelCase(details + ' products ' + tabName + ' tab'),
        action: 'click',
        label: tab
    });
};

const handleKeyDown = (details: string, tab: string, keyboardEvent: string) => {
    const tabName = tab === 'Data Center' ? 'Server' : tab;
    analytics.trackEvent(camelCase(tabName + ' tab'), {
        //@ts-ignore
        event: 'keyboard',
        eventComponent: 'tab',
        category: 'products cloud and server tabs',
        actionSubjectId: camelCase(details + ' products ' + tabName + ' tab'),
        action: 'selected',
        label: tab,
        keyboardEvent
    });
};

export const PlatformTabs = ({
    cloudProducts,
    serverProducts,
    details,
    analyticsMetadata,
    featureFlags: { isRedesignEnabled }
}: PlatformTabsProps) => {
    const tabs = [
        {
            label: 'Cloud',
            content: (
                <ProductCardsGroup
                    productList={cloudProducts}
                    analyticsMetadata={analyticsMetadata}
                    featureFlags={{
                        isRedesignEnabled
                    }}
                />
            )
        },
        {
            label: 'Data Center',
            content: (
                <ProductCardsGroup
                    productList={serverProducts}
                    analyticsMetadata={analyticsMetadata}
                    featureFlags={{
                        isRedesignEnabled
                    }}
                />
            )
        }
    ];

    return (
        <Tabs
            tabs={tabs}
            onSelect={(selected) => {
                selected.label && handleTabSelect(details, selected.label);
            }}
            onKeyDown={(selected, keyboardEvent) => {
                selected.label &&
                    handleKeyDown(details, selected.label, keyboardEvent);
            }}
        />
    );
};
