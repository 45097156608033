import { string, arrayOf, shape } from 'prop-types';
import { TopicType } from '../topic/topic.type';
import { withPageType } from '../../utils/with-page-type';
import { ProductType } from '../../components/product/product.type';
import { VersionsType } from '../../components/deployment-selector/versions.type';

export const TopicSetType = {
    title: string,
    id: string.isRequired,
    lastPublished: string,
    description: string,
    sectionOverview: string,
    topicReferences: arrayOf(shape(TopicType)),
    url: string,
    metaDescription: string,
    deployment: string,
    versions: arrayOf(VersionsType),
    product: ProductType
};

export const TopicSetPageType = withPageType(TopicSetType);
