import { generateUUID } from './helpers';

let analytics: any = null;
let sessionId = '';
let persistId = '';

// sets analytics object to window
export const getAnalyticsObject = () => {
    if (analytics) {
        return analytics;
    }

    if (
        typeof window !== 'undefined' &&
        typeof window.ace !== 'undefined' &&
        window.ace !== null &&
        typeof window.ace.analytics !== 'undefined'
    ) {
        analytics = window.ace.analytics;
    }

    return analytics;
};

// Sets the session storage object
const setSessionStorage = () => {
    if (sessionId) {
        return sessionId;
    }

    if (sessionStorage) {
        sessionId = sessionStorage.getItem(
            'LANDING_PAGES_SESSION_ID'
        ) as string;
        if (sessionId === null) {
            sessionId = generateUUID();
            sessionStorage.setItem('LANDING_PAGES_SESSION_ID', sessionId);
        }
    }

    return sessionId;
};

// Sets the local storage object
const setLocalStorage = () => {
    if (persistId) {
        return persistId;
    }

    if (localStorage) {
        persistId = localStorage.getItem('LANDING_PAGES_PERSIST_ID') as string;

        if (persistId === null) {
            persistId = generateUUID();
            localStorage.setItem('LANDING_PAGES_PERSIST_ID', persistId);
        }
    }

    return persistId;
};

// Initializing function that sets up local storage objects and analytics references
export const setAnalyticsRefs = () => {
    setSessionStorage();
    setLocalStorage();
};
