import React from 'react';
import {
    CardGroup,
    Card,
    CardsWithTitleAdjustment,
    ResourceType
    // @ts-ignore
} from '../card';
import useDropdownFocus from '../../hooks/use-dropdown-focus';
import './dropdown.less';
import camelCase from 'lodash/camelCase';
import { Resources } from '../header';

interface DropdownResourcesProps {
    closeDropdown: () => void;
    className: string;
    resources: Resources | undefined;
    isOpen: boolean;
}

const DropdownResources = ({
    className,
    isOpen,
    resources,
    closeDropdown
}: DropdownResourcesProps) => {
    const setFirstElement = useDropdownFocus({ isOpen, closeDropdown });

    if (!resources || !(resources.cards && resources.cards.length)) {
        return null;
    }
    const filteredCards = resources.cards.filter((item) => !item.hideInHeader);
    return (
        <div
            className={[className, isOpen ? '' : 'hidden'].join(' ')}
            data-testid="dropdown_resources"
        >
            <CardGroup className={'dropdown__resources'}>
                <CardsWithTitleAdjustment collection={filteredCards}>
                    {(
                        { item, measureRef, titleHeight }: ResourceType,
                        idx: number
                    ) => (
                        <Card
                            setRef={idx === 0 ? setFirstElement : undefined}
                            key={item.shortTitle ? item.shortTitle : item.title}
                            title={
                                item.shortTitle ? item.shortTitle : item.title
                            }
                            buttonLink={item.buttonLink}
                            icon={item.icon.url}
                            iconDimensions={{
                                height: 40,
                                width: 40
                            }}
                            description={
                                item.shortDescription ||
                                item.productSupportHomeDescription ||
                                item.sacHomeDescription ||
                                ''
                            }
                            analytics={item.title + ' resources dropdown'}
                            data-event-container="dropdownResources"
                            data-event-component={camelCase(
                                'dropdown resources ' + item.shortTitle
                            )}
                            data-track-event="clicked"
                            measureRef={measureRef}
                            titleHeight={titleHeight}
                        />
                    )}
                </CardsWithTitleAdjustment>
            </CardGroup>
        </div>
    );
};

export default DropdownResources;
