import React, {
    KeyboardEvent,
    KeyboardEventHandler,
    MouseEventHandler,
    useRef,
    useState
} from 'react';

import './subnav-with-sidebar-tabs.less';
import { Tab, TabData } from './tab';

export interface SubnavWithSidebarTabsProps {
    tabs: TabData[];
    defaultTab?: number;
    onTabSelect: (tab: number) => void;
}

const isValidTab = (tabs: TabData[], index: number): boolean => {
    return Array.isArray(tabs) && index > -1 && index < tabs.length;
};

interface UseEventHandlerParams {
    next?: (start: number) => number | boolean;
    previous?: (start: number) => number | boolean;
    select?: (item: number) => void | boolean;
}

interface EventsHandlers {
    createOnClick: (index: number) => MouseEventHandler<unknown>;
    createOnKeyDown: (index: number) => KeyboardEventHandler<unknown>;
}

const noop = (): boolean => {
    return false;
};

const useEventHandler = ({
    previous = noop,
    next = noop,
    select = noop
}: UseEventHandlerParams): EventsHandlers => {
    const keyMap = {
        ArrowUp: (start: number) => previous(start),
        ArrowDown: (start: number) => next(start)
    };

    return {
        createOnClick: (index: number) => () => select(index),
        createOnKeyDown: (index: number) => {
            return <E extends HTMLElement>(evt: KeyboardEvent<E>) => {
                const moveAction = keyMap[evt.key as keyof typeof keyMap];

                if (!moveAction) {
                    return;
                }

                const next = moveAction(index);
                if (next === false) {
                    return;
                }

                evt.preventDefault();
                if (typeof next !== 'undefined' && typeof next !== 'boolean') {
                    select(next);
                }
            };
        }
    };
};

export const SubnavWithSidebarTabs = ({
    tabs,
    defaultTab = 0,
    onTabSelect
}: SubnavWithSidebarTabsProps) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const [activeTabIndex, setActiveTabIndex] = useState(() => {
        if (isValidTab(tabs, defaultTab)) {
            return defaultTab;
        }

        return 0;
    });
    const [focusTab, setFocusTab] = useState<number>();

    const selectFocusableTab = (tab: number): void => {
        let nextTab = 0;

        if (tab < tabs.length) {
            nextTab = tab;
        }

        setActiveTabIndex(nextTab);
        setFocusTab(nextTab);
    };

    const { createOnClick, createOnKeyDown } = useEventHandler({
        previous: (start: number) => {
            const nextIndex = start - 1;

            return isValidTab(tabs, nextIndex) ? nextIndex : tabs.length - 1;
        },
        next: (start: number) => {
            const nextIndex = start + 1;

            return isValidTab(tabs, nextIndex) ? nextIndex : 0;
        },
        select: (tab: number) => {
            if (tab !== activeTabIndex) {
                selectFocusableTab(tab);
                onTabSelect(tab);
            }
        }
    });

    const handleBackspace = (event: React.KeyboardEvent) => {
        if (event.key === 'Backspace') {
            const nextTabIndex =
                activeTabIndex === tabs.length - 1
                    ? activeTabIndex - 1
                    : activeTabIndex + 1;
            setActiveTabIndex(nextTabIndex);
            setFocusTab(nextTabIndex);
        }
    };

    return (
        <div ref={containerRef} className="subnav-with-sidebar-tabs">
            <div className="subnav-with-sidebar-tabs__tablist__container">
                <div
                    role="tablist"
                    aria-label="Products by platform"
                    aria-orientation="vertical"
                    className="subnav-with-sidebar-tabs__tablist"
                    data-testid="subnav-with-sidebar-tabs-tablist"
                >
                    {tabs.map((tab, index) => {
                        const isActive = index === activeTabIndex;
                        const isFocused = index === focusTab;

                        return (
                            <Tab
                                key={`key-${tab.id}`}
                                isActive={isActive}
                                isFocused={isFocused}
                                tab={tab}
                                onClick={createOnClick(index)}
                                onKeyDown={createOnKeyDown(activeTabIndex)}
                            />
                        );
                    })}
                </div>
            </div>
            <div
                role="tabpanel"
                className="subnav-with-sidebar-tabs__tabpanel"
                data-testid="subnav-with-sidebar-tabs-tabpanel"
            >
                {tabs.map((tab, index) => {
                    return (
                        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                        <div
                            key={index}
                            style={{
                                position: index === 0 ? 'static' : 'absolute',
                                insetBlockStart: '0',
                                visibility:
                                    activeTabIndex === index
                                        ? 'visible'
                                        : 'hidden'
                            }}
                            onKeyDown={handleBackspace}
                        >
                            {tab.content}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
