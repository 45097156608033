import React from 'react';
import { ProductLandingPage } from './product-landing';

export default function render(entry, pageTree, featureFlags) {
    return (
        <ProductLandingPage
            entry={entry}
            pageTree={pageTree}
            featureFlags={featureFlags}
        />
    );
}
