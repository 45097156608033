import React from 'react';
// @ts-ignore
import { ProductType } from '../product';
import { DeploymentSelectorTabs } from '../deployment-selector-tabs';
import './dropdown.less';

interface DropdownProductsProps {
    closeDropdown: () => void;
    className: string;
    cloudProducts: ProductType;
    serverProducts: ProductType;
    isOpen: boolean;
    showProductDropdownAsWaffle: boolean | undefined;
}

const DropdownProducts = ({
    className,
    isOpen,
    cloudProducts,
    serverProducts,
    showProductDropdownAsWaffle
}: DropdownProductsProps) => {
    if (!cloudProducts || !serverProducts) {
        return null;
    }

    return (
        <div
            className={[
                className,
                'dropdown__products',
                isOpen ? '' : 'hidden'
            ].join(' ')}
            data-testid="dropdown_products"
        >
            <DeploymentSelectorTabs
                cloudProducts={cloudProducts}
                serverProducts={serverProducts}
                details={
                    showProductDropdownAsWaffle
                        ? 'waffle menu'
                        : 'product dropdown'
                }
                analyticsMetadata={{
                    parentComponent: 'dropdown-products'
                }}
            />
        </div>
    );
};

export default DropdownProducts;
