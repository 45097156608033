import React from 'react';
import './preview-banner.less';

const PreviewBanner = () => (
    <div data-testid="preview_banner" className="preview-banner">
        PREVIEW MODE
    </div>
);

export default PreviewBanner;
