import React from 'react';
// @ts-ignore
import {
    CardGroup,
    Card,
    CardsWithTitleAdjustment,
    CardsWithTitleAdjustmentFuncParams
    // @ts-ignore
} from '../card';
import useDropdownFocus from '../../hooks/use-dropdown-focus';
import './dropdown.less';
import { GuideType } from '../../pages/collection/guide.type';

interface DropdownGuideProps {
    closeDropdown: (arg: string) => void;
    className: string;
    guides: GuideType[] | undefined;
    isOpen: boolean;
}

const DropdownGuides = ({
    className,
    isOpen,
    guides,
    closeDropdown
}: DropdownGuideProps) => {
    const setFirstElement = useDropdownFocus({ isOpen, closeDropdown });

    if (!guides || !guides.length) {
        return null;
    }

    return (
        <div
            className={[className, isOpen ? '' : 'hidden'].join(' ')}
            data-testid="dropdown_guides"
        >
            <CardGroup className={'dropdown__guides'}>
                <CardsWithTitleAdjustment collection={guides}>
                    {(
                        {
                            item,
                            measureRef,
                            titleHeight
                        }: CardsWithTitleAdjustmentFuncParams,
                        idx: number
                    ) => (
                        <Card
                            setRef={idx === 0 ? setFirstElement : undefined}
                            key={item.title}
                            title={item.title}
                            buttonLink={item.url}
                            description={item.description}
                            measureRef={measureRef}
                            titleHeight={titleHeight}
                        />
                    )}
                </CardsWithTitleAdjustment>
            </CardGroup>
        </div>
    );
};

export default DropdownGuides;
