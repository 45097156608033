import axios, { AxiosResponse } from 'axios';

let cachedUser:
    | boolean
    | {
          isUserAuthenticated: () => boolean;
          getRawDetails: () => {
              email: any;
              photos: any;
              displayName: any;
          } | null;
          getUserDisplayName: () => any;
          getUserEmail: () => any | string;
          getUserAaId: () => string;
          getUserAvatarUrl: () => string | any;
          getCanUserEdit: () => boolean;
      } = false;
const userDetailsCallbackCache: ((value?: unknown) => void)[] = [];
let nowLoadingUserDetails = false;

export const _createUserDetailsObject = (
    response: { aaId: string; email: any; photos: any; displayName: any } | null
) => {
    const _isUserAuthenticated = () => {
        return (
            response !== null &&
            typeof response.email === 'string' &&
            response.email.length > 0
        );
    };

    const _getUserAaId = () => {
        if (response) {
            return _isUserAuthenticated() ? response.aaId : '';
        }
        return '';
    };

    const _getUserEmail = () => {
        if (response) {
            return _isUserAuthenticated() ? response.email : '';
        }
        return '';
    };

    const _getUserAvatarUrl = () => {
        if (response) {
            if (
                !_isUserAuthenticated() ||
                !Array.isArray(response.photos) ||
                response.photos.length === 0 ||
                typeof response.photos[0].value !== 'string'
            ) {
                return '';
            }

            return response.photos[0].value;
        }
        return '';
    };

    const _getCanUserEdit = () => {
        if (!response) {
            return false;
        }

        return _isUserAuthenticated()
            ? /@atlassian.com$/.test(response.email)
            : false;
    };

    const _getRawDetails = () => {
        return response;
    };

    const _getUserDisplayName = () => {
        if (response) {
            return response.displayName;
        }
    };

    return {
        isUserAuthenticated: _isUserAuthenticated,
        getUserEmail: _getUserEmail,
        getUserAaId: _getUserAaId,
        getUserAvatarUrl: _getUserAvatarUrl,
        getCanUserEdit: _getCanUserEdit,
        getRawDetails: _getRawDetails,
        getUserDisplayName: _getUserDisplayName
    };
};

export const _triggerCallbacks = () => {
    for (const element of userDetailsCallbackCache) {
        element(cachedUser);
    }
};

export const constructUrl = (hostname: string, protocol: string): string => {
    let url;
    if (hostname.includes('partners')) {
        url =
            protocol +
            '//' +
            hostname +
            '/gateway/api/customer-context/user/auth';
    } else if (
        hostname === 'support.atlassian.com' ||
        hostname.includes('support.stg')
    ) {
        url = window.SAC_BASE_URL + '/gateway/api/customer-context/user/auth';
    } else {
        url =
            'https://support.dev.internal.atlassian.com/gateway/api/customer-context/user/auth';
    }
    return url;
};

export const handleUserDetailsLoading = (
    resolve: any,
    apiOutput: AxiosResponse
): void => {
    if (cachedUser === false) {
        userDetailsCallbackCache.push(resolve);
        if (!nowLoadingUserDetails) {
            nowLoadingUserDetails = true;

            try {
                cachedUser = _createUserDetailsObject({
                    email: apiOutput.data.userName,
                    aaId: apiOutput.data.id,
                    photos: [{ value: apiOutput.data.photos[0].value }],
                    displayName: apiOutput.data.displayName
                });
                _triggerCallbacks();
            } catch {
                cachedUser = _createUserDetailsObject(null);
                _triggerCallbacks();
            }
        }
    } else {
        resolve(cachedUser);
    }
};

export const _getUserDetails = async (): Promise<any> => {
    const url = constructUrl(
        window.location.hostname,
        window.location.protocol
    );
    const apiOutput: AxiosResponse = await axios.get(url, {
        withCredentials: true,
        headers: { 'content-type': 'application/json' }
    });

    return new Promise((resolve) => {
        handleUserDetailsLoading(resolve, apiOutput);
    });
};

export default {
    getUserDetails: _getUserDetails
};
