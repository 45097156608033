import React from 'react';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import HipchatMediaAttachmentCountIcon from '@atlaskit/icon/glyph/hipchat/media-attachment-count';

interface Props {
    isCurrent: boolean;
    isOpen: boolean;
    hasChildList?: boolean;
    icon?: React.ReactChildren;
    onClick: () => void;
}

const getLabel = (isOpen: boolean) =>
    isOpen ? 'collapse page tree item' : 'expand page tree item';

export const PageTreeIcon = ({
    isCurrent,
    isOpen,
    hasChildList,
    onClick
}: Props) => {
    const classList = ['pagetree__expander'];
    let icon = hasChildList ? <ChevronRightIcon label="" /> : null;
    let label = '';
    const tabIndex = hasChildList ? 0 : -1;

    if (hasChildList) {
        label = getLabel(isOpen);
    }

    if (!hasChildList && !isCurrent) {
        return null;
    }

    if (isOpen) {
        classList.push('pagetree__expander--expanded');
    }

    if (isCurrent) {
        classList.push('pagetree__expander--current');
        icon = <HipchatMediaAttachmentCountIcon label="" />;
    }

    return (
        <div
            data-testid="pagetree-item-expander"
            className={classList.join(' ')}
            role="button"
            tabIndex={tabIndex}
            aria-expanded={isOpen}
            aria-label={label}
            // only call the on click handler if the item has a child list
            onClick={() => hasChildList && onClick()}
            onKeyDown={(evt) => hasChildList && evt.keyCode === 13 && onClick()}
        >
            {icon}
        </div>
    );
};
