import React, { FC } from 'react';
import Footer from '../../components/footer';
import { Header, HeaderListItem } from '../../components/header';
// @ts-ignore
import { ContactCtaBlock } from '../../components/contact';
// @ts-ignore
import Breadcrumbs from '../../components/breadcrumbs';
// @ts-ignore
import DeploymentSelector from '../../components/deployment-selector';
// @ts-ignore
import {
    CardGroup,
    Card,
    CardsWithTitleAdjustment,
    CardsWithTitleAdjustmentFuncParams
    // @ts-ignore
} from '../../components/card';
// @ts-ignore
import { setDefaultCardLinks } from '../../utils/set-default-card-links';
import { translatableText } from '../../utils/translatable-text';
import { ProductLandingProps } from './product-landing-type';
import {
    getContextName,
    getSupportTitle,
    isProductContext
} from '../../../content/context-utils';
import './product-landing.less';
import NoticeHub from '../../components/notice-hub';
import { CardResource } from 'src/renderer/components/card/card.type';
import { getContextDeploymentForSelectedButton } from '../../../content/context-utils/get-context-attributes';

export const ProductLandingPage: FC<ProductLandingProps> = ({
    entry
}: ProductLandingProps) => {
    const {
        context,
        internalTitle,
        cards,
        resourceCards,
        documentationLink,
        gettingStartedLink,
        header,
        knowledgeBaseLink,
        id,
        preview,
        collections
    } = entry;

    const renderCards = (cards: CardResource[], productName: string) => {
        return (
            <CardsWithTitleAdjustment collection={cards}>
                {({
                    item,
                    measureRef,
                    titleHeight
                }: CardsWithTitleAdjustmentFuncParams) => (
                    <Card
                        key={item.title}
                        title={item.title}
                        buttonText={item.buttonText}
                        buttonLink={item.buttonLink}
                        productName={productName}
                        analytics={item.analytics}
                        icon={item.icon ? item.icon.url : ''}
                        description={
                            item.productSupportHomeDescription ||
                            item.sacHomeDescription ||
                            ''
                        }
                        measureRef={measureRef}
                        titleHeight={titleHeight}
                    />
                )}
            </CardsWithTitleAdjustment>
        );
    };

    const getPrimaryListItems = (
        documentation: string,
        gettingStarted: string,
        knowledgeBase: string
    ) => {
        return (
            <React.Fragment>
                {gettingStarted && (
                    <HeaderListItem
                        label={translatableText.getStarted.text}
                        href={gettingStarted}
                    />
                )}
                {documentation && (
                    <HeaderListItem
                        label={translatableText.documentation.text}
                        href={documentation}
                    />
                )}
                {knowledgeBase && (
                    <HeaderListItem
                        label={translatableText.knowledgeBase.text}
                        href={knowledgeBase}
                    />
                )}
            </React.Fragment>
        );
    };

    // ensure the default resource cards have the correct product links
    setDefaultCardLinks(entry);

    const breadcrumbProductLabel = getContextName(context);
    return (
        <div className="product" data-testid="page-template">
            <div className="content-wrapper">
                <Header
                    title={getSupportTitle(context)}
                    resources={resourceCards}
                    id={header.id}
                    cloudProducts={header.cloudProducts}
                    serverProducts={header.serverProducts}
                    additionalLinks={header.additionalLinks}
                    primaryListItems={getPrimaryListItems(
                        documentationLink,
                        gettingStartedLink,
                        knowledgeBaseLink
                    )}
                    showProductDropdownAsWaffle={true}
                    showPreviewBanner={preview}
                    pageId={id}
                    supportFormUrls={{
                        authenticated: isProductContext(context)
                            ? context.customSupportFormAuthenticated
                            : null,
                        unauthenticated: isProductContext(context)
                            ? context.customSupportFormUnauthenticated
                            : null
                    }}
                    collections={collections}
                    logo={header.logo}
                    atlassianSupportLogo={header.atlassianSupportLogo}
                    atlassianLogo={header.atlassianLogo}
                />

                <main className="main-content-container">
                    <div className="product__sub-header ">
                        <Breadcrumbs entryId={id} context={context} />
                        {isProductContext(context) && (
                            <DeploymentSelector
                                selected={getContextDeploymentForSelectedButton(
                                    context
                                )}
                                deployments={context.deploymentUrls}
                            />
                        )}
                    </div>

                    {context.notice && <NoticeHub notice={context.notice} />}

                    <div
                        data-testid="product_title"
                        className="product__title margin-top-medium margin-bottom-medium"
                    >
                        <h1>{internalTitle}</h1>
                    </div>
                    <CardGroup>
                        {resourceCards.cards.length > 0 &&
                            renderCards(
                                resourceCards.cards,
                                breadcrumbProductLabel
                            )}
                        {cards.length
                            ? renderCards(cards, breadcrumbProductLabel)
                            : ''}
                    </CardGroup>
                    {isProductContext(context) && (
                        <ContactCtaBlock
                            supportFormUrls={{
                                authenticated:
                                    context.customSupportFormAuthenticated,
                                unauthenticated:
                                    context.customSupportFormUnauthenticated
                            }}
                            analyticsMetadata={{
                                parentComponent: 'product-landing-page'
                            }}
                        />
                    )}
                </main>
            </div>
            <Footer
                logo={header.atlassianLogo.url}
                className={'margin-top-large'}
            />
        </div>
    );
};
