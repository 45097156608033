import React, { useState, useEffect } from 'react';
import { CollectionTreeType } from './sidebar.types';
// @ts-ignore
import { AtlasLink, track } from '../atlas-link';
import { ShowMore } from '../show-more';
import './sidebar.less';
import camelCase from 'lodash/camelCase';

const initializeVisibleRange = (
    currentTopicIndex: number,
    numberOfTopics: number,
    isProgressBarEnabled: boolean
) => {
    //case 1: Guides show all topics
    if (isProgressBarEnabled) {
        return [0, numberOfTopics - 1];
    }
    // case 2: if the current topic is 2 or less from the top, show the top five topics
    // takes into account if there are less than 5 topics total
    if (currentTopicIndex <= 2) {
        return [0, Math.min(numberOfTopics - 1, 4)];
    }
    // case 3: if the current topic index is 2 or less from the end, show the bottom 5 topics
    // takes into account if there are less than 5 topics total
    if (currentTopicIndex >= numberOfTopics - 2) {
        return [Math.max(numberOfTopics - 5, 0), numberOfTopics - 1];
    }
    // All others: show from 2 above to 2 below the current topic
    return [Math.max(currentTopicIndex - 2, 0), currentTopicIndex + 2];
};

/**
 * Links to tree items that are siblings to the current page.
 *
 */
const SiblingPagesSection = ({
    parent,
    treeItems,
    currentPageId,
    currentTopicIndex,
    isProgressBarEnabled
}: {
    parent: CollectionTreeType;
    treeItems: CollectionTreeType[];
    currentPageId: string;
    currentTopicIndex: number;
    isProgressBarEnabled: boolean;
}) => {
    const [visibleRange, setVisibleRange] = useState(
        initializeVisibleRange(
            currentTopicIndex,
            treeItems.length,
            isProgressBarEnabled
        )
    );
    const getSidebarItemClassName = (isCurrentPage: boolean, index: number) => {
        const indexInTreeItems = index + visibleRange[0];
        // all <li> gets sidebar__item class by default
        const linkClassNames = ['sidebar__item'];
        if (isProgressBarEnabled) {
            if (indexInTreeItems < currentTopicIndex) {
                linkClassNames.push('progress-bar progress-bar__visited');
            } else if (indexInTreeItems === currentTopicIndex) {
                linkClassNames.push('progress-bar progress-bar__current');
            } else if (indexInTreeItems > currentTopicIndex) {
                linkClassNames.push('progress-bar');
            }
        } else if (isCurrentPage) {
            linkClassNames.push('sidebar__item--current');
        }

        return linkClassNames.join(' ');
    };

    useEffect(() => {
        setVisibleRange(
            initializeVisibleRange(
                currentTopicIndex,
                treeItems.length,
                isProgressBarEnabled
            )
        );
    }, [currentTopicIndex, treeItems.length, isProgressBarEnabled]);

    return (
        <ul
            className="sidebar__section sidebar__section--topic"
            data-testid="sibling-pages"
        >
            {/* Header */}
            <li className={isProgressBarEnabled ? 'progress-bar' : ''}>
                <a
                    href={parent.slug}
                    className="sidebar__heading"
                    data-testid="sibling-section-heading"
                >
                    {parent.title}
                </a>
            </li>
            {!isProgressBarEnabled &&
                currentTopicIndex > 2 &&
                visibleRange[0] >= 1 && (
                    <ShowMore
                        className="sidebar__item--show-more-up"
                        label="Expand sibling pages list"
                        testId="sibling-chevron-up"
                        onClick={() => setVisibleRange([0, visibleRange[1]])}
                    />
                )}
            {/* Links */}
            {treeItems
                // the second index argument is excluded from the return value, so we need to add 1
                .slice(visibleRange[0], visibleRange[1] + 1)
                .map((page, index) => {
                    const isCurrentPage = page.id === currentPageId;

                    const sidebarItemClassName = getSidebarItemClassName(
                        isCurrentPage,
                        index
                    );

                    return (
                        <li
                            key={index}
                            className={sidebarItemClassName}
                            data-testid="sibling-section-link"
                        >
                            {isCurrentPage ? (
                                <p className="sidebar__link">{page.title}</p>
                            ) : (
                                <AtlasLink
                                    text={page.title}
                                    href={page.slug}
                                    dataName={camelCase(
                                        `sidebar link ${page.title}`
                                    )}
                                    className="sidebar__link"
                                    data-event-container="sidebar"
                                />
                            )}
                        </li>
                    );
                })}
            {!isProgressBarEnabled &&
                currentTopicIndex + 3 < treeItems.length &&
                visibleRange[1] < treeItems.length - 1 && (
                    <ShowMore
                        label="Expand sibling pages list"
                        testId="sibling-chevron-down"
                        onClick={() => {
                            setVisibleRange([
                                visibleRange[0],
                                treeItems.length
                            ]);
                            track('#', 'Show more', 'showMore');
                        }}
                    />
                )}
        </ul>
    );
};

export default SiblingPagesSection;
