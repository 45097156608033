import React from 'react';
const escapeRegExp = (string: string) => {
    return string.replace(/[$()*+.?[\\\]^{|}]/g, '\\$&');
};

// TODO: Remove when isSearchTermHighlightingEnabled is fully enabled
export const highlightSearchTerm = (searchTerm: string, text: string) => {
    if (searchTerm) {
        const escapedSearchTerm = escapeRegExp(searchTerm);
        const searchTermRegex = new RegExp(`(${escapedSearchTerm})`, 'gi');

        return text
            ?.split(searchTermRegex)
            .map((segment, i) =>
                searchTermRegex.test(segment) ? (
                    <mark key={`${segment}-${i}`}>{segment}</mark>
                ) : (
                    segment
                )
            );
    }
    return text;
};

export const highlightText = (text: string) => {
    const html = new DOMParser().parseFromString(text, 'text/html').body
        .childNodes;
    const sections: (string | JSX.Element | null)[] = [];

    html.forEach((node, i) => {
        if (node.nodeName === 'SPAN') {
            sections.push(
                <mark className="highlighted-text" key={i}>
                    <b>{node.textContent}</b>
                </mark>
            );
        } else {
            sections.push(node.textContent);
        }
    });

    return sections;
};
