import React from 'react';
import Lozenge from '@atlaskit/lozenge';
import propTypes from 'prop-types';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import { AtlasLink } from '../../atlas-link';

// @ts-ignore
const MenuItem = ({ title, hasLozenge, isShortcut, href, dataAttributes }) => {
    const dataKeyValues = {};

    dataAttributes.forEach(
        (dataAttribute: { key: string | number; value: any }) => {
            // @ts-ignore
            dataKeyValues[dataAttribute.key] = dataAttribute.value;
        }
    );

    return (
        <AtlasLink
            href={href}
            // @ts-ignore
            alt={title}
            className="menu-item"
            {...dataKeyValues}
        >
            <div>
                <span>{title}</span>
                {hasLozenge ? (
                    <span className="lozenge-new">
                        <Lozenge appearance="new">New</Lozenge>
                    </span>
                ) : null}
            </div>
            {isShortcut ? (
                <div className="menu-shortcut">
                    <ShortcutIcon label={'shortcut'} size="small" />
                </div>
            ) : null}
        </AtlasLink>
    );
};

MenuItem.propTypes = {
    title: propTypes.string.isRequired,
    hasLozenge: propTypes.bool,
    isShortcut: propTypes.bool,
    href: propTypes.string,
    dataAttributes: propTypes.arrayOf(
        propTypes.shape({
            name: propTypes.string,
            value: propTypes.string
        })
    )
};

MenuItem.defaultProps = {
    hasLozenge: false,
    isShortcut: false,
    href: '',
    dataAttributes: []
};

export default MenuItem;
