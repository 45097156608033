import { string, arrayOf, shape } from 'prop-types';
import { CollectionType } from '../collection/collection.type';
import { ProductType } from '../../components/product/product.type';
import { VersionsType } from '../../components/deployment-selector/versions.type';
import { IconType } from '../../components/asset/asset.type';
import { withPageType } from '../../utils/with-page-type';

export const DocumentedProductType = {
    title: string,
    id: string.isRequired,
    tagline: string,
    icon: IconType,
    collectionReferences: arrayOf(shape(CollectionType)),
    lastPublished: string,
    context: ProductType,
    url: string,
    metaDescription: string,
    deployment: string,
    versions: arrayOf(VersionsType)
};

export const DocumentedProductPageType = withPageType(DocumentedProductType);
