import React from 'react';

interface PageTreeListProps {
    children: React.ReactNode;
    isRoot: boolean;
    isOpen?: boolean;
}

export const PageTreeList = ({
    children,
    isRoot,
    isOpen
}: PageTreeListProps) => {
    const hiddenStyle = !isOpen && !isRoot ? { display: 'none' } : {};
    return (
        <ul
            className={`pagetree__list ${isRoot && `pagetree__list--root`}`}
            style={{ ...hiddenStyle }}
        >
            {children}
        </ul>
    );
};
