import React, { Component } from 'react';
import { string } from 'prop-types';
import get from 'lodash/get';
import { RadioGroup } from '@atlaskit/radio';
import analytics from '../../utils/analytics';
import IssueCollector from '../issue-collector';
import Button from '@atlaskit/button';
import './feedback-buttons.less';
import { translatableText } from '../../utils/translatable-text';

export default class FeedbackButtons extends Component {
    constructor() {
        super();
        this.state = {
            dropdownOpen: false,
            clickedButtonValue: null,
            dropdownCheckedValue: null,
            dropdownOptions: [
                {
                    name: 'was-this-helpful-no-reason',
                    value: "It wasn't accurate",
                    label: translatableText.notAccurate.text
                },
                {
                    name: 'was-this-helpful-no-reason',
                    value: "It wasn't clear",
                    label: translatableText.notClear.text
                },
                {
                    name: 'was-this-helpful-no-reason',
                    value: "It wasn't relevant",
                    label: translatableText.notRelevant.text
                }
            ]
        };
        this.handleFeedbackClick = this.handleFeedbackClick.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.handleDropdownOptionClick =
            this.handleDropdownOptionClick.bind(this);
    }

    handleFeedbackClick(evt) {
        const val = get(evt, 'target.textContent', '').toLowerCase();
        if (!val) {
            return null;
        }
        if (val === 'no') {
            this.toggleDropdown();
        } else if (this.state.dropdownOpen) {
            this.setState({ dropdownOpen: false });
        }
        this.setState({ clickedButtonValue: val });

        this.trackFeedbackAnalytics(val);
    }

    handleDropdownOptionClick(evt) {
        const val = evt.target.value;
        this.setState({
            checkedValue: val,
            dropdownOpen: false
        });
        this.trackFeedbackAnalytics(val);
    }

    toggleDropdown() {
        this.setState({ dropdownOpen: !this.state.dropdownOpen });
    }

    trackFeedbackAnalytics(val) {
        analytics.trackEvent('was_this_helpful_click', {
            event: 'clicked',
            eventComponent: 'button',
            actionSubjectId: 'wasThisHelpful',
            category: 'was this helpful',
            action: 'click',
            label: val
        });
    }

    render() {
        const { productName, pageId } = this.props;

        return (
            <div data-testid="feedback_buttons" className="feedback">
                <p className="feedback__question-text">
                    {translatableText.wasThisHelpful.text}
                </p>
                <Button
                    className="feedback__yes-button"
                    onClick={this.handleFeedbackClick}
                    isSelected={this.state.clickedButtonValue === 'yes'}
                >
                    {translatableText.yes.text}
                </Button>
                <div className="feedback__no-wrapper">
                    <Button
                        className="feedback__no-button"
                        onClick={this.handleFeedbackClick}
                        isSelected={this.state.clickedButtonValue === 'no'}
                    >
                        {translatableText.no.text}
                    </Button>
                    <div
                        className={`feedback__dropdown ${
                            this.state.dropdownOpen ? 'is-open' : ''
                        }`}
                    >
                        <RadioGroup
                            options={this.state.dropdownOptions}
                            checkedValue={this.state.dropdownCheckedValue}
                            onChange={this.handleDropdownOptionClick}
                        />
                    </div>
                </div>
                <IssueCollector
                    className="feedback__report-a-problem"
                    productName={productName}
                    pageId={pageId}
                >
                    {translatableText.provideFeedbackAboutThisArticle.text}
                </IssueCollector>
            </div>
        );
    }
}

FeedbackButtons.propTypes = {
    productName: string.isRequired,
    pageId: string.isRequired
};
