import React from 'react';
import { ContextLabel } from '../../../content/types/content/context';
import './release-note-sidebar.less';

export const Contexts = ({ contexts }: { contexts: ContextLabel[] }) => {
    const defaultIcon =
        '//images.ctfassets.net/zsv3d0ugroxu/1GKGxGW9OIU6CiAOugs6s6/09146dfbeb437b37572da3f8c4b84682/product-atlassian.svg';
    return (
        <div className="sidebar sidebar__section--release-note">
            {contexts.map((context, i) => {
                return (
                    <div key={'context-' + i} className="sidebar__context">
                        <img
                            data-testid={'context-image-' + i}
                            className="sidebar__context-icon"
                            src={context.icon?.url || defaultIcon}
                            alt={
                                context.icon?.description ||
                                context.name + ' icon'
                            }
                        />
                        <span className="sidebar__context-name">
                            {context.name}
                        </span>
                    </div>
                );
            })}
        </div>
    );
};
