import React, { useState, useEffect } from 'react';
import EditorAlignLeftIcon from '@atlaskit/icon/glyph/editor/align-left';
import ArrowLeftIcon from '@atlaskit/icon/glyph/arrow-left';
// @ts-ignore
import { translatableText } from '../../utils/translatable-text';
import './page-tree.less';
import analytics from '../../utils/analytics/analytics';
import isEmpty from 'lodash/isEmpty';
import {
    getContextName,
    Context,
    AppContext,
    ThemeContext,
    isProductContext
} from '../../../content/context-utils';

const addSubContentClass = (subContentRef: React.RefObject<any>) => {
    if (subContentRef) {
        subContentRef.current.classList.add('page-tree-expanded');
    }
};

const removeSubContentClass = (subContentRef: React.RefObject<any>) => {
    if (subContentRef) {
        subContentRef.current.classList.remove('page-tree-expanded');
    }
};

interface PageTreeProps {
    children: React.ReactNode;
    className?: string;
    subContentRef: React.RefObject<any>;
    context: Context | AppContext | ThemeContext;
}

const PageTree = ({
    children,
    context,
    subContentRef,
    className
}: PageTreeProps) => {
    const [pageTreeCollapsed, setPageTreeCollapsed] = useState(true);
    const [pageTreeTopStyling, setPageTreeTopStyling] = useState({});

    useEffect(() => {
        const addPageTreeStyle = () => {
            const marginTop = (window.scrollY + 28) * -1;
            const shouldFixToTop = 88 + marginTop + 28 <= 0;
            setPageTreeTopStyling(
                shouldFixToTop
                    ? { top: '28px' }
                    : { marginTop: `${marginTop * 0.1}rem` }
            );
        };
        // when the component mounts check the sessionStorage if pagetree should be
        // collapsed or not
        if (sessionStorage.getItem('sac.sidebar.expanded') === 'true') {
            setPageTreeCollapsed(false);
            addSubContentClass(subContentRef);
        }
        // call once on initial mount in case page tree is open and the page is already
        // scrolled on load, i.e. linking from an anchor link
        addPageTreeStyle();

        // setup a handler to move pagetree to top of viewport on scroll
        window.addEventListener('scroll', () => {
            addPageTreeStyle();
        });
    }, [subContentRef]);

    const openPageTree = () => {
        setPageTreeCollapsed(false);
        addSubContentClass(subContentRef);
        sessionStorage.setItem('sac.sidebar.expanded', 'true');
        analytics.trackEvent('pageTreeOpen', {
            // @ts-ignore
            event: 'opened',
            eventComponent: 'pageTree',
            category: 'pageTree',
            action: 'click',
            label: 'Open Page Tree'
        });
    };

    const closePageTree = () => {
        setPageTreeCollapsed(true);
        removeSubContentClass(subContentRef);
        sessionStorage.setItem('sac.sidebar.expanded', 'false');
        analytics.trackEvent('pageTreeClose', {
            // @ts-ignore
            event: 'closed',
            eventComponent: 'pageTree',
            category: 'pageTree',
            action: 'click',
            label: 'Close Page Tree'
        });
    };

    const handleExpanderKeyDown = (evt: React.KeyboardEvent) => {
        if (evt.keyCode === 13) {
            openPageTree();
        }
    };

    const handleBackKeyDown = (evt: React.KeyboardEvent) => {
        if (evt.keyCode === 13) {
            closePageTree();
        }
    };

    const getPageTreeTitle = (context: Context | AppContext | ThemeContext) => {
        const contextName = getContextName(context);

        if (isProductContext(context)) {
            return `${contextName} ${context.deployment}`;
        }

        return contextName;
    };

    return (
        <aside className={!pageTreeCollapsed ? 'pagetree-width' : ''}>
            {pageTreeCollapsed && (
                <div
                    className="pagetree-expander"
                    data-testid="pagetree-expander"
                    role="button"
                    tabIndex={0}
                    aria-label="open page tree"
                    onClick={openPageTree}
                    onKeyDown={handleExpanderKeyDown}
                >
                    <EditorAlignLeftIcon label="" />
                </div>
            )}
            <div
                className={[
                    'pagetree',
                    className,
                    pageTreeCollapsed ? 'pagetree--hidden' : ''
                ].join(' ')}
                data-testid="pagetree"
                style={{ ...pageTreeTopStyling }}
            >
                <div className="pagetree__inner">
                    <div className="pagetree__context">
                        <div
                            data-testid="pagetree-back-arrow"
                            className="pagetree__back-arrow"
                            role="button"
                            aria-label="close page tree"
                            tabIndex={0}
                            onClick={closePageTree}
                            onKeyDown={handleBackKeyDown}
                        >
                            <ArrowLeftIcon label="" />
                        </div>
                        {!isEmpty(context.icon) && (
                            <img
                                className="pagetree__icon"
                                src={context.icon ? context.icon.url : ''}
                                alt={getContextName(context)}
                                height="40"
                                width="40"
                            />
                        )}
                        <div>
                            <a
                                href={context.docsHomeSlug}
                                className="pagetree__title"
                                data-testid="pagetree-title"
                                data-event-container="pagetree"
                                data-event-component="pagetreeTitle"
                                data-track-event="clicked"
                            >
                                {getPageTreeTitle(context)}
                            </a>
                            <p className="pagetree__subhead">
                                {translatableText.documentation.text}
                            </p>
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </aside>
    );
};

export default PageTree;
