import React from 'react';
import DocumentFilledIcon from '@atlaskit/icon/glyph/document-filled';
import { TopicReference } from './sidebar.types';
// @ts-ignore
import { AtlasLink } from '../atlas-link';
import './sidebar.less';
// @ts-ignore
import { translatableText } from '../../utils/translatable-text';
import { ShowMore } from '../show-more';
import camelCase from 'lodash/camelCase';

/**
 * Child topics section; Under a feature flag "sacUsabilityIsEnabled" as of 2/7/2020.
 */
const ChildPagesSection = ({
    topicReferences,
    loadMoreTopicsHandler,
    isTopicListCollapsed,
    maxTopicsWhenCollapsed
}: {
    topicReferences: TopicReference[];
    loadMoreTopicsHandler: () => void;
    isTopicListCollapsed: boolean;
    maxTopicsWhenCollapsed: number;
}) => {
    return (
        <ul
            className="sidebar__section sidebar__section--topic"
            data-testid="child-page-list"
        >
            {/* Header */}
            <li className="sidebar__heading" data-testid="explore-more-heading">
                {translatableText.exploreMore.text}
            </li>

            {/* Links */}
            {topicReferences.map(({ id, url, title }, index) => {
                if (index < maxTopicsWhenCollapsed || !isTopicListCollapsed) {
                    return (
                        <li
                            key={id}
                            className="sidebar__item"
                            data-testid="child-page"
                        >
                            <span className="document-icon-wrapper">
                                <DocumentFilledIcon
                                    label=""
                                    primaryColor="#5243AA"
                                />
                            </span>
                            <AtlasLink
                                text={title}
                                href={url}
                                className="sidebar__link"
                                dataName={camelCase(`sidebar Link ${title}`)}
                            />
                        </li>
                    );
                }
            })}

            {/* Expand button */}
            {isTopicListCollapsed && (
                <ShowMore
                    label="Show the rest of the children topics"
                    onClick={loadMoreTopicsHandler}
                    testId="child-chevron-down"
                />
            )}
        </ul>
    );
};

export default ChildPagesSection;
