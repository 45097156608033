import React from 'react';
import type { KbArticlePage } from '../../../content/types/content/kb-article';
import TableOfContents from '../sidebar/table-of-contents';
import AppVersions from './components/app-versions';
import ProductVersions from './components/product-versions';
import './kb-article-sidebar.less';
import PlatformSection from './components/platform-section';

const KbArticleSidebar = ({ page }: { page: KbArticlePage }) => {
    const { appVersions, productVersions, tableOfContents, url } = page;

    return (
        <div data-testid="kb-article-sidebar">
            <div className="sidebar__section">
                <div className="kb-article-sidebar__section-wrapper">
                    <PlatformSection platform={page.platform} />
                </div>
                <div className="kb-article-sidebar__section-wrapper">
                    <ProductVersions productVersions={productVersions} />
                </div>
                <div className="kb-article-sidebar__section-wrapper">
                    <AppVersions appVersions={appVersions} />
                </div>
            </div>
            {tableOfContents.length > 0 && (
                <div
                    className="kb-article-sidebar__section-wrapper"
                    data-testid="kb-article-toc"
                >
                    <TableOfContents url={url} links={tableOfContents} />
                </div>
            )}
        </div>
    );
};

export default KbArticleSidebar;
