export const NOT_FOUND_PAGE = 'notFoundPage';
export const COLLECTION = 'collection';
export const HOME_PAGE = 'homePage';
export const PRODUCT_LANDING_PAGE = 'productLandingPage';
export const DOCUMENTED_PRODUCT = 'documentedProduct';
export const TOPIC = 'topic';
export const TOPIC_DERIVED = 'topicDerived';
export const TOPIC_SET = 'topicSet';
export const CONTACT_PAGE = 'contactPage';
export const PARTNER_CONTACT_PAGE = 'partnerContactPage';
export const STATUSPAGE_CONTACT_PAGE = 'statuspageContactPage';
export const CSP_REQUESTS_APP_PAGE = 'myRequestsPage';
export const CARD = 'card';
export const ASSET = 'asset';
export const DEFAULT_CARDS = 'defaultResourceCards';
export const CONTEXT = 'productListing';
export const CONTEXT_PRODUCT = 'productListing';
export const CONTEXT_THEME = 'contextTheme';
export const CONTEXT_APP = 'contextApp';
export const APP_NAME = 'appName';
export const PRODUCT_NAME = 'productName';
export const HEADER_LINKS = 'globalHeaderLinks';
export const HEADER = 'globalHeader';
export const HOT_CONTACT_PAGE = 'hotContactPage';
export const SITE_METADATA = 'sitewideMetadata';
export const IMAGE_ENTRY = 'imageEntry';
export const TEXT_FRAGMENT = 'componentGeneric';
export const REDIRECT = 'redirect';
export const FILE_DOWNLOAD_PAGE = 'fileDownloadPage';
export const KNOWLEDGE_BASE = 'topicKnowledgeBase';
export const NOTICE_HUB = 'noticeHub';
export const RELEASE_NOTE = 'releaseNote';
export const LISTING_PAGE = 'listingPage';
export const STRUCTURAL_METADATA_PRODUCT_VIEW = 'structuralMetadataProductView';
export const TAXONOMY_USER_ROLE_REF = 'taxonomyUserRoleRef';
export const SEARCH_RESULTS_PAGE = 'searchResultsPage';
export const REFERENCE_ITEM_GENERIC = 'referenceItemGeneric';
export const REFERENCE_ITEM_JQL_FIELD = 'referenceItemJqlField';
export const SAC_PAGE_REFERENCE = 'sacPageReference';
export const REFERENCE_ITEM_JQL_FUNCTION = 'referenceItemJqlFunction';
export const REFERENCE_ITEM_JQL_OPERATOR = 'referenceItemJqlOperator';
export const PAGE_KB_HOME = 'pageKbHome';
export const PAGE_KB_ARTICLE = 'pageKbArticle';
export const KB_ARTICLE_INFORMATIONAL = 'kbArticleInformational';
export const KB_ARTICLE_PROBLEM = 'kbArticleProblem';
export const KB_ARTICLE_HOW_TO = 'kbArticleHowTo';
export const COMPONENT_CODE_BLOCK = 'componentCodeBlock';
export const COMPONENT_KB_IMAGE = 'componentKbImage';
export const COMPONENT_CALLOUT = 'componentCallout';
export const APP_VERSION = 'appVersion';
export const PRODUCT_VERSION = 'productVersion';
export const SUPPORT_CASE = 'supportCase';
export const FEATURED_CONTENT = 'featuredContent';
export const RESOURCE_LINK = 'resourceLink';
