/** @WARNING DO NOT EDIT THIS FILE */

/*
    On 9/30/2019 we removed minification in order to preserve function names
    to allow Smartling to translate text we've marked in translatable-text.js.

    View the PR here: https://bitbucket.org/atlassian/sac-infrastructure/pull-requests/945/
    Capturing JS: https://help.smartling.com/hc/en-us/articles/360000322674-Capturing-Javascript-Content

    In client-config.ts, We attempted to configure TerserPlugin to preserve these names
    however when smartling-tags.js showed:

        export function sl_translate() {} 
        export const sl_tr_start = sl_translate;
        export const sl_tr_end = sl_translate;

    sl_tr_start would not be preserved. 
    We surmise it has something to do with the quirkiness of JS & Webpack.

    The configuration below allows for all three functions to survive the bundling process.
*/

function sl_translate() {} // eslint-disable-line @typescript-eslint/no-empty-function
export const sl_tr_start = sl_translate;
export const sl_tr_end = sl_tr_start;
