import { initialize } from 'launchdarkly-js-client-sdk';
import cookies from 'js-cookie';

let ldClient: any;

async function initializeLaunchDarkly() {
    if (ldClient === undefined) {
        const user = {
            key: 'developer',
            custom: {
                isDevTestingEnabled: cookies.get('isDevTesting') !== undefined
            }
        };

        ldClient = initialize(
            (window as any).LD_CLIENT_SDK_KEY as string,
            user
        );
    }

    return ldClient.waitForInitialization();
}

export async function isEnabled(
    featureFlagKey: string,
    defaultFlagValue: boolean
): Promise<boolean> {
    await initializeLaunchDarkly();
    return ldClient.variation(featureFlagKey, defaultFlagValue);
}

export async function isMultiVariationEnabled(
    featureFlagKey: string,
    defaultFlagValue: boolean
): Promise<Record<string, boolean>> {
    await initializeLaunchDarkly();
    return ldClient.variation(featureFlagKey, defaultFlagValue);
}
