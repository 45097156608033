import React from 'react';
import './filter-tags.less';
import TagGroup from '@atlaskit/tag-group';
import Tag from '@atlaskit/tag';
import analytics from '../../utils/analytics';
import { translatableText } from '../../utils/translatable-text';

interface FilterTag {
    type: string;
    key: string;
    name: string;
    analyticsMetadata?: {
        [key: string]: any;
    };
    onRemove: (key: string) => void;
}

interface FilterTagsProps {
    tags: FilterTag[];
}

export const FilterTags = ({ tags }: FilterTagsProps) => {
    return tags?.length ? (
        <div className="filter-tags__wrapper">
            <TagGroup>
                {tags.map(
                    ({ type, key, name, analyticsMetadata, onRemove }) => (
                        <Tag
                            testId={`${type}-tag`}
                            key={key}
                            text={name}
                            removeButtonText={`Remove ${name} filter tag`}
                            onBeforeRemoveAction={() => {
                                onRemove(key);
                                analytics.trackEvent('filter tag', {
                                    // @ts-ignore
                                    event: 'clicked',
                                    eventComponent: 'tag',
                                    actionSubjectId: 'filterTag',
                                    category: 'filter',
                                    action: 'clicked',
                                    label: `${type} filter tag`,
                                    ...analyticsMetadata
                                });
                                return true;
                            }}
                        />
                    )
                )}
            </TagGroup>
        </div>
    ) : (
        <div
            className="filter-tags__empty-state"
            data-testid="filter-tags-empty-state"
        >
            {translatableText.noFiltersApplied.text}
        </div>
    );
};
