const getLoginUrl = (continueUrl: string) => {
    const continueQuery = continueUrl
        ? `?continue=${encodeURIComponent(continueUrl)}`
        : '';
    return `${window.ID_AUTH_URL}/login${continueQuery}`;
};

const getLogoutUrl = () => {
    return `${window.LOGOUT_URL}?continue=${encodeURIComponent(
        window.SAC_BASE_URL
    )}`;
};

export { getLoginUrl, getLogoutUrl };
