import { CollectionTreeType } from '../../../components/sidebar/sidebar.types';

export const getUpNextContent = (
    // Retrieves content for the "Up Next" box on
    // a Topic page, using the Topic and PageTree
    // Given a Topic and Page Tree:
    // * Retrieve Collections for the Topic (collectionTree)
    // * Populate array of "sibling" Topic Sets, children of
    // the Collection
    // * Populate array with the previous and next Topics for
    // the "previous" and "next" arrows, 'prevGuideCard' and
    // 'nextGuideCard', based on the order in the page tree

    topic: {
        topicTitle: string;
        body: string;
        id: string;
        collectionId: string;
        topicSetId?: string;
    },
    isProgressBarEnabled: boolean,
    pageTree: CollectionTreeType[]
) => {
    const topicEntry = topic;
    let nextGuideCard = null;
    let prevGuideCard = null;
    const currentPageId = topic.id;
    let parent;
    const treeItems: {
        description?: string;
        title: string;
        slug: string;
        id: string;
    }[] = [];
    let currentTopicIndex = -1;
    let nextIndex = 0;
    let prevIndex = 0;
    const otherGuideTopicSets: {
        title: string;
        link: string;
        description?: string;
    }[] = [];

    const collectionTree = pageTree.find(
        (collection) => collection.id === topicEntry.collectionId
    );

    if (isProgressBarEnabled) {
        if (topicEntry.topicSetId) {
            // topic set is parent
            const list = collectionTree?.childList;
            parent = list?.find(
                (topicSet) => topicSet.id === topicEntry.topicSetId
            );
            list?.filter(
                (topicSet) => topicSet.id !== topicEntry.topicSetId
            ).forEach((topicSet) => {
                otherGuideTopicSets.push({
                    title: topicSet.title,
                    link: topicSet.slug,
                    description: topicSet.description
                });
            });
        } else {
            // collection is parent
            parent = collectionTree;
        }
        // if parent collection is guide, use currentTopicIndex to use it as conditional state
        parent?.childList?.forEach(
            (
                val: {
                    description?: string;
                    title: string;
                    slug: string;
                    id: string;
                },
                index: number
            ) => {
                // push siblings
                treeItems.push(val);
                if (val.id === currentPageId) {
                    // get index of current topic in sidebar
                    currentTopicIndex = index;
                    nextIndex = currentTopicIndex + 1;
                    prevIndex = currentTopicIndex - 1;
                }
            }
        );

        // if current topic page is not the last item in the right-nav, grab nextGuideCard's info
        if (treeItems.length !== nextIndex) {
            nextGuideCard = {
                id: currentPageId,
                title: treeItems[nextIndex].title,
                description: treeItems[nextIndex].description,
                buttonLink: treeItems[nextIndex].slug,
                buttonText: 'View topic'
            };
        }

        // if current topic page is not the first item in the right-nav, grab prevGuideCard's info
        if (prevIndex !== -1) {
            prevGuideCard = {
                id: currentPageId,
                title: treeItems[prevIndex].title,
                description: treeItems[prevIndex].description,
                buttonLink: treeItems[prevIndex].slug,
                buttonText: 'View topic'
            };
        }
    }

    return { prevGuideCard, nextGuideCard, otherGuideTopicSets };
};
