import React from 'react';
import TopicSetPage from './topic-set';

export default function render(entry, pageTree, featureFlags) {
    return (
        <TopicSetPage
            entry={entry}
            pageTree={pageTree}
            featureFlags={featureFlags}
        />
    );
}
