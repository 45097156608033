import { HeaderType } from '../components/header/header.type';
import { ProductType } from '../components/product/product.type';
import { SitewideMetadataType } from '../components/sitewide-metadata/sitewide-metadata.type';
import { shape, bool } from 'prop-types';

// Header and Product types are added to each content type that is
// renderered with a PageRenderer
export const withPageType = type =>
    shape({
        ...type,
        header: HeaderType,
        product: ProductType,
        metadata: SitewideMetadataType,
        preview: bool
    });
