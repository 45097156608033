import timer from 'hirestime';
import Logger from '../../../../logger';
import { Schema } from '@hapi/joi';
import loaderResultValidator from '../loader-result-validator';
import { executeClientCPAPIQuery } from '../../../content-platform-api/execute-client-cpapi-graphql-query';

type LoaderFn = (data: any) => Promise<any>;

interface WrapperArguments {
    loaderQuery: string;
    loaderSchema: Schema;
    loaderFn: LoaderFn;
    usesCPAPI?: boolean;
    type: string;
}

export const withDynamicLoader = ({
    loaderQuery,
    loaderSchema,
    loaderFn,
    type
}: WrapperArguments) => {
    const wrappedDynamicLoader = async () => {
        const stopTimer = timer();
        const pageData = await executeClientCPAPIQuery(loaderQuery, {
            first: 10
        });

        const executionMilliseconds = stopTimer();

        const allData = { ...pageData };

        const { content: data } = allData.data.data;

        Logger.debug(
            {
                executionMilliseconds,
                type
            },
            `Dynamic Loader "${type}" executed`
        );

        if (!data) {
            Logger.warn({ type }, `Entry not found`);
            return null;
        }

        // call the passed in loader with the result from the passed in loaderQuery
        const loadedResult = await loaderFn(data);
        // validate the result from the loaderFunction with the loaderSchema that was passed in
        loaderResultValidator(loadedResult, loaderSchema);

        return {
            ...loadedResult
        };
    };

    return wrappedDynamicLoader;
};
