export interface HimalayaNode {
    children: any[];
    tagName: string;
    attributes: any[];
}

export interface TagData {
    tagName: string;
    key: string;
}

interface UpdateHTMLNodes {
    dom: HimalayaNode[];
    tagsToUpdate: TagData[];
    url: string;
    isChildOfTopicSet: boolean;
}

export const recursivelySearchDom = (
    dom: HimalayaNode[],
    predicate: (node: HimalayaNode) => boolean
) => {
    const results: HimalayaNode[] = [];

    function recursivelySearch(nodes: HimalayaNode[]) {
        nodes.forEach((node) => {
            if (predicate(node)) {
                results.push(node);
            }

            if (node.children && node.children.length) {
                recursivelySearch(node.children);
            }
        });
    }

    recursivelySearch(dom);

    return results;
};

const isSpecifiedTag = (node: HimalayaNode, tagName: string, key: string) =>
    node.tagName === tagName && node.attributes.find((a: any) => a.key === key);

const updateElement = (node: HimalayaNode, key: string, url: string) => {
    const index = node.attributes.findIndex((a: any) => a.key === key);
    let hrefValue = node.attributes[index].value;

    if (!hrefValue.includes(url) && !hrefValue.includes('http')) {
        const domain = url.split('/sneaky')[0];

        //only update relative paths
        if (hrefValue.startsWith('/')) {
            hrefValue = hrefValue.replace('/sneaky', '');
            node.attributes[index].value = `${domain}${hrefValue}`;
        }
    }

    return node;
};

const bumpUpHeadingsCACContent = (node: HimalayaNode) => {
    const regex = /h[1-9]/;
    if (regex.test(node.tagName)) {
        node.tagName =
            node.tagName[0] + Math.min(Number.parseInt(node.tagName[1]) + 1, 6);
    }

    return node;
};
export const updateHTMLNodes = ({
    dom,
    tagsToUpdate,
    url,
    isChildOfTopicSet = false
}: UpdateHTMLNodes) => {
    function traverseNodes(nodes: HimalayaNode[]) {
        nodes.forEach((node) => {
            tagsToUpdate.forEach((tag) => {
                if (isSpecifiedTag(node, tag.tagName, tag.key)) {
                    node = updateElement(node, tag.key, url);
                }
            });

            if (isChildOfTopicSet) {
                node = bumpUpHeadingsCACContent(node);
            }

            if (node.children && node.children.length) {
                traverseNodes(node.children);
            }
        });
    }

    traverseNodes(dom);
    return dom;
};
