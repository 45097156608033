import { useEffect } from 'react';

const CSMChatWidget = () => {
    useEffect(() => {
        const loadScript = (url) => {
            const script = document.createElement('script');
            script.src = url;
            script.async = true; // or script.defer = true;
            document.body.append(script);
        };

        const checkAndLoadWidget = () => {
            const widget = document.querySelector(
                '#atlassian-conversation-assistant-widget'
            );
            if (!widget) {
                loadScript(
                    `${window.CSM_CHAT_WIDGET_BASE_URL}/assets/loader.js`
                );
            }
        };

        checkAndLoadWidget();
    }, []);
    return null;
};

export default CSMChatWidget;
