import React from 'react';
import type { RenderNode } from '@contentful/rich-text-react-renderer';
import { BLOCKS, type Inline, type Block } from '@contentful/rich-text-types';

function renderTable(
    _node: Block | Inline,
    children: React.ReactNode
): JSX.Element {
    return (
        <div className="table-wrap">
            <table className="confluenceTable">
                <tbody>{children}</tbody>
            </table>
        </div>
    );
}

function renderTableRow(
    _node: Block | Inline,
    children: React.ReactNode
): JSX.Element {
    return <tr>{children}</tr>;
}

function renderTableHeaderCell(
    _node: Block | Inline,
    children: React.ReactNode
): JSX.Element {
    return <th className="confluenceTh">{children}</th>;
}

function renderTableCell(
    _node: Block | Inline,
    children: React.ReactNode
): JSX.Element {
    return <td className="confluenceTd">{children}</td>;
}

export const RENDER_TABLE_NODES: RenderNode = {
    [BLOCKS.TABLE]: renderTable,
    [BLOCKS.TABLE_ROW]: renderTableRow,
    [BLOCKS.TABLE_HEADER_CELL]: renderTableHeaderCell,
    [BLOCKS.TABLE_CELL]: renderTableCell
};
