import React, { useState, useEffect } from 'react';
import Account from '../utils/account';
import Logger from '../../logger';

export interface AuthDetailsInterface {
    aaId: string;
    isAuthenticated: boolean;
    email: string;
    avatarUrl: string;
    canEdit: boolean;
    rawDetails: any;
    displayName: string;
    authApiTransitionState: any;
}

export enum AuthApiAvailableStates {
    SUCCESS = 'success',
    FAILURE = 'failure',
    LOADING = 'loading',
    NOT_INITIATED = 'not_initiated'
}

const defaultDetails = {
    isAuthenticated: false,
    aaId: '',
    email: '',
    avatarUrl: '',
    canEdit: false,
    rawDetails: {},
    displayName: '',
    authApiTransitionState: AuthApiAvailableStates.NOT_INITIATED
};

// ENCAPSULATED CONTEXT
const AuthContext = React.createContext<AuthDetailsInterface>(defaultDetails);

export const AuthAnalyticsMetadata = {
    isUserAuthenticated: false
};

// EXPORTED PROVIDER
export function AuthProvider(props: { children: React.ReactNode }) {
    const [details, setDetails] =
        useState<AuthDetailsInterface>(defaultDetails);

    const checkAuth: () => {} = async () => {
        try {
            setDetails({
                ...defaultDetails,
                authApiTransitionState: AuthApiAvailableStates.LOADING
            });
            const {
                isUserAuthenticated,
                getUserEmail,
                getUserAaId,
                getUserAvatarUrl,
                getCanUserEdit,
                getRawDetails,
                getUserDisplayName
            } = await Account.getUserDetails();

            // UPDATE STATE
            const newState = {
                isAuthenticated: await isUserAuthenticated(),
                email: await getUserEmail(),
                aaId: await getUserAaId(),
                avatarUrl: await getUserAvatarUrl(),
                canEdit: await getCanUserEdit(),
                rawDetails: await getRawDetails(),
                displayName: await getUserDisplayName(),
                authApiTransitionState: AuthApiAvailableStates.SUCCESS
            };
            setDetails(newState);
        } catch (error) {
            setDetails({
                ...defaultDetails,
                authApiTransitionState: AuthApiAvailableStates.FAILURE
            });
            Logger.error({ error }, 'Error getting user details');
        }
    };

    // ON MOUNT ONLY
    useEffect(() => {
        checkAuth();
    }, []);

    useEffect(() => {
        AuthAnalyticsMetadata.isUserAuthenticated = details.isAuthenticated;
    }, [details]);

    return (
        <AuthContext.Provider value={details}>
            {props.children}
        </AuthContext.Provider>
    );
}

// EXPORTED HOOK
export function useAuth() {
    return React.useContext(AuthContext);
}

// EXPORTED HOC
export function WithAuthDetails(WrappedComponent: React.ComponentType<any>) {
    const WrappedComponentClosure = (props: any) => (
        <AuthContext.Consumer>
            {(authDetails: AuthDetailsInterface) => (
                <WrappedComponent
                    authDetails={authDetails}
                    {...props}
                ></WrappedComponent>
            )}
        </AuthContext.Consumer>
    );

    WrappedComponentClosure.displayName = `WithAuthDetailsClosure`;
    return WrappedComponentClosure;
}
WithAuthDetails.DisplayName = 'WithAuthDetails';
