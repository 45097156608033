import React from 'react';
import { SearchResultsPage } from './search-results';
import { Provider } from 'react-redux';
import { setupStore } from './state/store';

export default function render(entry: any, _pageTree: any, featureFlags: any) {
    return (
        <Provider store={setupStore()}>
            <SearchResultsPage entry={entry} featureFlags={featureFlags} />
        </Provider>
    );
}
