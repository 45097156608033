import React from 'react';
import { Checkbox } from '@atlaskit/checkbox';
import './listing-page-filters.less';
import { FilterSet } from 'src/content/types/content/filter-set';

const ListingPageFilters = ({
    filters,
    selectedFilters,
    onFilterChange,
    onFiltersReset
}: {
    filters: FilterSet[];
    selectedFilters: string[];
    onFilterChange: (filter: string) => void;
    onFiltersReset: () => void;
}) => {
    const filterListElement = filters.map((filterGroup: FilterSet) => {
        return (
            <div
                key={filterGroup.filterSetName}
                data-testid="listing_page_filters"
            >
                <div className="listing-page-filters__list__title">
                    {filterGroup.filterSetName}
                </div>
                <div>
                    {filterGroup.filterSet.map((item: string) => (
                        <div
                            className="listing-page-filters__list__item"
                            key={item}
                        >
                            <Checkbox
                                data-testid={`checkbox-${item}`}
                                key={`checkbox-${item}`}
                                value={item}
                                label={item}
                                onChange={() => {
                                    onFilterChange(item);
                                }}
                                name={`checkbox-${item}`}
                                isChecked={selectedFilters.includes(item)}
                            />
                        </div>
                    ))}
                </div>
            </div>
        );
    });

    return (
        <div className="listing-page-filters">
            <div className="listing-page-filters__title">Filters</div>
            <button
                onClick={onFiltersReset}
                className="listing-page-filters__reset"
                data-testid="listing-page-filters-reset"
            >
                Reset
            </button>
            <div className="listing-page-filters__list">
                {filterListElement}
            </div>
        </div>
    );
};

export default ListingPageFilters;
