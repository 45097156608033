import React from 'react';
import './help-links.less';

const HelpLinks = () => {
    return (
        <div className="help-links">
            <p className="help-links__lead-in">More ways to get help</p>
            <p>
                <a href="https://support.atlassian.com/contact">
                    Contact Atlassian support
                </a>
            </p>
            <p>
                Visit the{' '}
                <a href="https://community.atlassian.com/">
                    Atlassian Community
                </a>{' '}
                or
                <a href="https://community.developer.atlassian.com/">
                    {' '}
                    Atlassian Developer Community
                </a>
            </p>
            <p>
                Contact the{' '}
                <a href="https://ecosystem.atlassian.net/servicedesk/customer/portal/14">
                    Developer Support Service Desk
                </a>
            </p>
            <p>
                Connect with an{' '}
                <a href="https://www.atlassian.com/partners">
                    Atlassian Partner
                </a>{' '}
                for custom requests
            </p>
        </div>
    );
};

export default HelpLinks;
