import React, { useMemo } from 'react';
import AtlaskitBreadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import { Helmet } from 'react-helmet';
import {
    getContextBreadcrumbs,
    getKbPageBreadcrumbs,
    getReleaseNoteBreadcrumbs,
    getStructuredData
} from './util';
import calculateBreadcrumbs from '../../utils/calculate-breadcrumbs';
import {
    COLLECTION,
    LISTING_PAGE,
    TOPIC,
    TOPIC_SET
} from '../../../contentful/content-types';

import './breadcrumbs.less';
import track from '../atlas-link/track';
import camelCase from 'lodash/camelCase';
import type { KbPageReferences } from '../../../content/types/content/kb-article';
import type { ContentfulContext } from '../../../content/types/content/context';
import { translatableText } from '../../utils/translatable-text';

export interface BreadcrumbsArgs {
    entryId: string;
    pageTree: any[];
    context: ContentfulContext | KbPageReferences | null;
    type: string;
}

export interface BreadcrumbItemProps {
    id: string;
    slug: string;
    title: string;
}

const BreadcrumbItems = (crumb: BreadcrumbItemProps) => {
    const onClick = React.useCallback(
        () =>
            track(
                crumb.slug,
                crumb.title,
                camelCase(`breadcrumb ${crumb.title}`)
            ),
        [crumb]
    );

    return (
        <BreadcrumbsItem
            truncationWidth="150"
            key={crumb.id}
            href={crumb.slug}
            text={crumb.title}
            onClick={onClick}
        />
    );
};

const Breadcrumbs = ({
    pageTree,
    entryId,
    context,
    type = ''
}: BreadcrumbsArgs): React.ReactElement => {
    const displayResourcesBreadcrumb =
        type === COLLECTION || type === TOPIC_SET || type === TOPIC;
    const displayReleaseNotesBreadcrumb = type === LISTING_PAGE;

    const isContextReference = !!context && 'id' in context;
    const isKbReference = !!context && 'kbHomePageReference' in context;

    // Initialize with the hardcoded Atlassian Support crumb
    let crumbs: BreadcrumbItemProps[] = [
        {
            id: '/',
            slug: '/',
            title: translatableText.atlassianSupport.text
        }
    ];

    crumbs = crumbs.concat(
        // Add context breadcrumbs
        isContextReference
            ? getContextBreadcrumbs(context, displayResourcesBreadcrumb)
            : [],
        // Add KB breadcrumbs
        isKbReference ? getKbPageBreadcrumbs(context) : [],
        // Add release note breadcrumbs
        displayReleaseNotesBreadcrumb ? getReleaseNoteBreadcrumbs() : [],
        // Add page tree breadcrumbs
        useMemo(
            () =>
                context ? calculateBreadcrumbs(pageTree, entryId, type) : [],
            [pageTree, entryId, type, context]
        )
    );

    // get JSON-LD structured data breadcrumb markup to assist with SEO
    // https://developers.google.com/search/docs/data-types/breadcrumb
    const structuredData = useMemo(() => getStructuredData(crumbs), [crumbs]);

    return (
        <>
            <Helmet>
                <script type="application/ld+json">{structuredData}</script>
            </Helmet>
            <div className="breadcrumbs-wrapper" data-testid="breadcrumbs">
                <AtlaskitBreadcrumbs>
                    {crumbs.map((crumb) => BreadcrumbItems(crumb))}
                </AtlaskitBreadcrumbs>
            </div>
        </>
    );
};

export default Breadcrumbs;
