import { BasePathOptions } from './get-base-url-path';
import { DocumentationPageOptions } from './get-documentation-page-path';
import { DocsHomeURLPathOptions } from './get-docs-home-path';

type URLBuilderOptions =
    | BasePathOptions
    | DocsHomeURLPathOptions
    | DocumentationPageOptions;

export const safeURLPath = (URLBuilderFn: any) => (
    options: URLBuilderOptions
) => {
    //check for anchor which exists if topic slug should be an anchor link on parent topic set page
    const { anchor } = options as DocumentationPageOptions;

    // call the URL builder function to get the url fragments
    let URLFragments = URLBuilderFn(options);

    // filter out any null or undefined URL Fragments
    URLFragments = URLFragments.filter((fragment: string) => !!fragment);

    // join all the url path fragments together into a single string
    const URLPath = `/${URLFragments.join('/')}/`;

    // remove any double slashes return the safe URL Path
    const url = URLPath.replace(/\/+/g, '/');

    return anchor ? url + anchor : url;
};
