export const DOCUMENTATION = 'Documentation';
export const DOCUMENTATION_DROPDOWN = 'documentationDropdown';
export const GET_STARTED = 'Get started';
export const GET_STARTED_DROPDOWN = 'getStartedDropdown';
export const PRODUCTS = 'Products';
export const PRODUCTS_DROPDOWN = 'productsDropdown';
export const RESOURCES = 'Resources';
export const RESOURCES_DROPDOWN = 'resourcesDropdown';
export const WAFFLE_MENU = 'waffleMenu';

export const keyCodes = {
    RETURN: 13
};
