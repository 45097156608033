import React from 'react';
import { translatableText } from '../../utils/translatable-text';
import './results-text.less';

interface ResultsTextProps {
    searchTerm: string;
    startCount: number;
    endCount: number;
    totalCount: number;
}

// TODO: Uncomment this function once issue with count is resolved in Search API v2
// const formatResultText = ({
//     searchTerm,
//     startCount,
//     endCount,
//     totalCount
// }: ResultsTextProps): string => {
//     return translatableText.resultText.text
//         .replace('startCount', startCount.toString())
//         .replace('endCount', endCount.toString())
//         .replace('totalCount', totalCount.toString())
//         .replace('searchTerm', searchTerm);
// };

export const ResultsText = ({
    searchTerm,
    // startCount, // TODO: Uncomment this once issue with count is resolved in Search API v2
    // endCount, // TODO: Uncomment this once issue with count is resolved in Search API v2
    totalCount
}: ResultsTextProps) => {
    // TODO: Uncomment this once issue with count is resolved in Search API v2
    // const resultsText = formatResultText({
    //     searchTerm,
    //     startCount,
    //     endCount,
    //     totalCount
    // });

    if (totalCount === 0) {
        return (
            <span data-testid="no-results" className="results-text">
                {translatableText.noResultText.text.replace(
                    'searchTerm',
                    searchTerm
                )}
            </span>
        );
    }

    return (
        <span data-testid="total-results" className="results-text">
            {/* TODO: Uncomment below once issue with count is resolved in Search API v2 */}
            {/* {resultsText} */}
            {/* TODO: Remove below once issue with count is resolved in Search API v2 */}
            {`Showing results for "${searchTerm}"`}
        </span>
    );
};
