import { AuthAnalyticsMetadata } from '../../providers/auth-providers';
import { getAnalyticsObject } from './set-window';
import { EventProperties } from './types';

// Uses the analytics variable to track events
export const trackEvent = (
    eventName: string,
    eventProperties: EventProperties
) => {
    const analytics = getAnalyticsObject();
    //Need to invoke segment.io directly. ATL Analytics do not give us the callback feature
    if (analytics) {
        analytics.Tracking.trackEvent(eventName, {
            ...eventProperties,
            content_type: window?.__APP_INITIAL_STATE__?.contentType,
            content_ari: window?.__APP_INITIAL_STATE__?.contentAri,
            contentful_entry_id: window?.__APP_INITIAL_STATE__?.entry?.id,
            is_logged_in: AuthAnalyticsMetadata.isUserAuthenticated
        });
    }
};
