import './featured-content.less';
import React from 'react';
import {
    FeaturedContent as FeaturedContentEntry,
    FeaturedEntry
} from '../../../content/types/content/featured-content';
import { AtlasLink } from '../atlas-link';
import analytics from '../../utils/analytics';
import { getContentAri } from '../../utils/get-content-ari';
import { AnalyticsMetadata } from '../../utils/analytics/types';

interface FeaturedContentProps {
    content: FeaturedContentEntry;
    analyticsMetadata: AnalyticsMetadata;
    featureFlags: { isRedesignEnabled?: boolean };
}

interface FeaturedContentListProps {
    entries: FeaturedEntry[];
    analyticsMetadata: AnalyticsMetadata;
    isRedesignEnabled?: boolean;
}

interface FeaturedContentListItemProps {
    entry: FeaturedEntry;
    position: number;
    analyticsMetadata: AnalyticsMetadata;
    isRedesignEnabled?: boolean;
}

const FeaturedContentListItem = ({
    entry,
    position,
    analyticsMetadata,
    isRedesignEnabled
}: FeaturedContentListItemProps) => {
    const isV2Component = isRedesignEnabled ? '-v2' : '';
    return (
        <div className={`featured-content-list${isV2Component}-item`}>
            <AtlasLink
                className={`featured-content-list${isV2Component}-item__link`}
                href={entry.url}
                text={entry.linkText}
                target={entry.linkTarget}
                onClick={() => {
                    analytics.trackEvent('featured article', {
                        // @ts-ignore
                        event: 'clicked',
                        eventComponent: 'featured-content-list-item',
                        actionSubjectId: 'link',
                        category: 'featured-content',
                        action: 'clicked',
                        label: 'featured article',
                        position,
                        linkUrl: entry.url,
                        linkText: entry.linkText,
                        linkTarget: entry.linkTarget,
                        contentId: entry.id,
                        linkContentAri: getContentAri(entry.id),
                        contentType: entry.type,
                        ...analyticsMetadata
                    });
                }}
            />
        </div>
    );
};

const FeaturedContentList = ({
    entries,
    analyticsMetadata,
    isRedesignEnabled
}: FeaturedContentListProps) => {
    return (
        <div
            className={`featured-content-list${isRedesignEnabled ? '-v2' : ''}`}
        >
            {entries.map((entry, index) => (
                <FeaturedContentListItem
                    key={entry.id}
                    entry={entry}
                    position={index + 1}
                    analyticsMetadata={analyticsMetadata}
                    isRedesignEnabled={isRedesignEnabled}
                />
            ))}
        </div>
    );
};

const icon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="52"
        height="50"
        viewBox="0 0 52 50"
        fill="none"
    >
        <path
            d="M40 11.75C40 11.3358 39.6642 11 39.25 11H33.25C32.8358 11 32.5 11.3358 32.5 11.75C32.5 12.1642 32.8358 12.5 33.25 12.5L37.4393 12.5L30.9998 18.9393L28.5303 16.4697C28.3897 16.329 28.1989 16.25 28 16.25C27.8011 16.25 27.6103 16.329 27.4697 16.4697L19.2197 24.7197C18.9268 25.0126 18.9268 25.4874 19.2197 25.7803C19.5126 26.0732 19.9874 26.0732 20.2803 25.7803L28 18.0607L30.4695 20.5303C30.6101 20.671 30.8009 20.75 30.9998 20.75C31.1987 20.75 31.3895 20.671 31.5301 20.5303L38.5 13.5606V17.75C38.5 18.1642 38.8358 18.5 39.25 18.5C39.6642 18.5 40 18.1642 40 17.75V11.75ZM29.5 26C29.5 24.3431 30.8431 23 32.5 23H40C41.6569 23 43 24.3431 43 26V32C43 33.6569 41.6569 35 40 35H32.5C30.8431 35 29.5 33.6569 29.5 32V26ZM39.25 30.5H33.25C32.8358 30.5 32.5 30.8358 32.5 31.25C32.5 31.6642 32.8358 32 33.25 32H39.25C39.6642 32 40 31.6642 40 31.25C40 30.8358 39.6642 30.5 39.25 30.5ZM33.25 26C32.8358 26 32.5 26.3358 32.5 26.75C32.5 27.1642 32.8358 27.5 33.25 27.5H39.25C39.6642 27.5 40 27.1642 40 26.75C40 26.3358 39.6642 26 39.25 26H33.25Z"
            fill="#0C66E4"
        />
    </svg>
);

export const FeaturedContent = ({
    content,
    analyticsMetadata,
    featureFlags: { isRedesignEnabled }
}: FeaturedContentProps) => {
    const isV2Component = isRedesignEnabled ? '-v2' : '';

    return (
        <section className={`featured-content${isV2Component}`}>
            <div className={`featured-content${isV2Component}__inner`}>
                <div className={`featured-content${isV2Component}__header`}>
                    {!isRedesignEnabled && icon}
                    <h2
                        className={`featured-content${isV2Component}__header-title`}
                    >
                        {content.title}
                    </h2>
                </div>
                <FeaturedContentList
                    entries={content.featuredEntries}
                    analyticsMetadata={analyticsMetadata}
                    isRedesignEnabled={isRedesignEnabled}
                />
            </div>
        </section>
    );
};
