import React from 'react';
import './search-result-card.less';

export const SearchResultsDisplay = ({
    children,
    isSacSearch2Enabled
}: {
    children: React.ReactNode;
    isSacSearch2Enabled: boolean | undefined;
}) => {
    if (isSacSearch2Enabled) {
        return (
            <div className="search-result-card__result-containerv2">
                {children}
            </div>
        );
    }
    return (
        <div className="search-result-card__result-container">{children}</div>
    );
};
