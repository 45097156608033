import { string, shape } from 'prop-types';

export const DeploymentType = shape({
    cloud: string,
    server: string,
    default: string
});

export interface Deployment {
    cloud?: string;
    server?: string;
    default?: string;
}
